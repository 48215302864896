import React from 'react'
import { isEmpty, max } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import numeral from 'numeral'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import { ErrorMessageChart } from '../error-message-chart'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'

const HousingStressPercentageChart = ({
  chartData,
  name,
  title,
  subTitle,
  dataSource,
  dataNotes,
}) => {
  try {
    if (isEmpty(chartData)) {
      return <ErrorMessageChart error="No data for chart" />
    }
    const maxValue = max(chartData.map(item => item.Total_Per))

    const ticks = scaleLinear()
      .domain([0, maxValue])
      .nice(5)
      .ticks(5)

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {formatPercentage(item.value)} (
                {numeral(item.payload.Total_Num).format('0,0')})
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    return (
      <ChartWrapper
        name={name}
        title={title}
        subTitle={subTitle}
        dataSource={dataSource}
        dataNotes={dataNotes}
        body={
          <div className="chart__viz" style={{ width: '100%', height: 320 }}>
            <ResponsiveContainer>
              <BarChart data={chartData} margin={{ top: 20 }}>
                <XAxis dataKey="group" tickLine={false} />
                <YAxis
                  tickLine={false}
                  unit="%"
                  ticks={ticks}
                  interval={0}
                  tickCount={ticks.count + 1}
                />
                <CartesianGrid vertical={false} />
                <Tooltip content={<CustomTooltip />} />

                <Bar
                  dataKey="Total_Per"
                  name="In stress"
                  unit="%"
                  label={props => (
                    <CustomVerticalLabel {...props} formatter={(value) => formatPercentage(value)}/>
                  )}
                >
                  {chartData.map((entry, index) => (
                    <Cell fill={entry.color} key={`cell-${index}`} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default HousingStressPercentageChart
