import React, { useEffect } from 'react'
import { head, isEmpty, filter } from 'lodash'
import { navigate, graphql } from 'gatsby'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import WorkerAffordabilityMortgageLgaChart from '../components/charts/worker-affordability-mortgage-lga'
import WorkerAffordabilityRentalLgaChart from '../components/charts/worker-affordability-rental-lga'
import WorkerHousingAvailabilityRentalChart from '../components/charts/worker-housing-availability-rental'
import WorkerHousingAvailabilityMortgageChart from '../components/charts/worker-housing-availability-mortgage'
import SectionUpfrontText from '../components/collapsibleUpfrontText/section-upfront-text'
import { WorkerAffordabilityContext } from '../hooks/workerAffordabilityContext'

const WorkerAffordabilityTemplate = props => {
  useEffect(() => {
    const access = head(filter(passwordProtected, ['lga', pageContext.LGASlug]))
    if (!isEmpty(access) && access.password !== window.pass) {
      navigate(`/${pageContext.LGASlug}`)
    }
  })

  // Destructure passed in object
  const data = props.data
  const pageContext = props.pageContext
  const interest = data.inputInterest.nodes[0].data.Incomes
  const lgaName = data.areas2XlsxAreas.LongName

  return (
    <WorkerAffordabilityContext.Consumer>
      {_context => (
        <React.Fragment>
          <PageContentWrapper
            title={pageContext.title}
            LGAName={data.areas2XlsxAreas.LongName}
            theme={pageContext.theme}
            areas={data.areas2XlsxAreas}
            slug={pageContext.LGASlug}
            alias={data.areas2XlsxAreas.Alias}
            productLinks={pageContext.productLinks}
            clientLogo={data.allFile}
          >
            <SectionUpfrontText title="Worker Affordability" type="h2">
              <p>
                Good housing planning and provision considers and supports the local area’s social
                and economic needs. Can workers in important occupations afford to live in the area
                in which they work? What does this mean for an area’s future economy?
              </p>
              <p>
                This topic looks at housing affordability for the top 10 occupations in {lgaName}{' '}
                (based on the number of people in that occupation who work in {lgaName}).
                Affordability is generally considered as spending less than 30% of gross household
                income on housing, with higher amounts being considered housing stress. For each
                occupation, the median household income is calculated. Using the 30% affordability
                threshold, the income is compared to rents and housing prices (first home buyer
                mortgage) to see what these households can afford before going into housing stress.
              </p>
              <p>
                This can indicate whether the local housing market is accessible to people in these
                occupation groups, or whether people filling these occupations largely need to live
                outside and commute into the area to fill local jobs.
              </p>
              <p>
                Read more:{' '}
                <a
                  href="https://blog.id.com.au/2024/housing/keeping-key-workers-local-the-impact-of-housing-unaffordability"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Keeping key workers local: the impact of housing unaffordability
                </a>
              </p>
            </SectionUpfrontText>

            <h2 className="section-heading">With a mortgage</h2>

            <SectionUpfrontText title="Housing purchase affordability">
              <p>
                What can households with someone in the top 10 occupation groups in {lgaName} afford
                to purchase in the area, based on 30% of their income?
              </p>
              <p>
                The first chart shows the housing purchase price that would be affordable to a first
                home buyer (with a 20% deposit paying current average interest rates) without going
                into housing stress, based on the median household income.
              </p>
              <p>
                The median household income is calculated for all households with a "Household
                Reference Person" working in {lgaName} and employed in one of the top 10 occupation
                groups in {lgaName}. This median can be compared on the chart to the entry level and
                median rents for houses and units in the LGA.
              </p>
              <p>
                The second chart shows how many and what percentage of total completed housing sales
                the 12 months ending at the month selected would have been affordable to each
                occupation based on their median household income (and first home buyer
                assumptions), with a breakdown of the bedroom sizes of these dwellings. Change the
                time-period selector to see how affordability for occupation groups has changed over
                time, based on changing numbers of sales, rental costs and incomes, which are
                adjusted for changes in Average Weekly Earnings.
              </p>
              <p>
                Combined with changes in the total number of sales, this can give an indication as
                to whether people who work in the area can afford to purchase homes in the area.
                Often housing purchase is less affordable than renting, so it is possible that
                rental is affordable while home purchase is not in some cases.
              </p>
            </SectionUpfrontText>

            <div className="row">
              <div className="col-md-12">
                <WorkerAffordabilityMortgageLgaChart
                  alias={pageContext.LGASlug}
                  interest={interest}
                  dataNotes={data.affordabilityMortgageNotes}
                  pricePoints={data.entryMedianPrices.nodes[0].Sales}
                  areaName={lgaName}
                  data={data.workerIncomesData.nodes[0].data.Periods}
                  affordableData={data.workerAffordabilityData.nodes[0].data.Sales}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <WorkerHousingAvailabilityMortgageChart
                  data={data.workerAffordabilityData.nodes[0].data.Sales}
                  dataNotes={data.affordabilityMortgageNotes}
                  areaName={lgaName}
                />
              </div>
            </div>
            <h2 className="section-heading anchor-point" id="rental-stress">
              Renting
            </h2>
            <SectionUpfrontText title="Rental affordability">
              <p>
                What can households with someone in the top 10 occupation groups in {lgaName} afford
                to rent in the area, based on 30% of their income?
              </p>
              <p>
                The first chart outlines the weekly rent that would be affordable to renters without
                going into housing stress, based on the median household income.
              </p>
              <p>
                The median household income is calculated for all households with a "Household
                Reference Person" working in {lgaName} and employed in one of the top 10 occupation
                groups in {lgaName}. This is used to calculate an affordable rental amount which can
                be compared on the chart to the entry level and median rents for houses and units in
                the LGA.
              </p>
              <p>
                The second chart shows how many and what percentage of total advertised rentals in
                the 12 months ending at the month selected would have been affordable to each
                occupation based on their median household income, with a breakdown of the bedroom
                sizes of these dwellings. Change the time-period selector to see how affordability
                for occupation groups have changed over time, based on changing numbers of listings,
                rental costs and incomes, which are adjusted for changes in Average Weekly Earnings.
              </p>
              <p>
                Combined with changes to the total number of dwellings advertised for rent, this can
                give an indication as to whether these occupation groups can afford to rent in the
                area near where they work.
              </p>
            </SectionUpfrontText>
            <div className="row anchor-point" id="how-affordable-is-renting-for-local-workers">
              <div className="col-md-12">
                <WorkerAffordabilityRentalLgaChart
                  alias={pageContext.LGASlug}
                  interest={interest}
                  dataNotes={data.affordabilityRentalNotes}
                  pricePoints={data.entryMedianPrices.nodes[0].Rentals}
                  areaName={lgaName}
                  data={data.workerIncomesData.nodes[0].data.Periods}
                  affordableData={data.workerAffordabilityData.nodes[0].data.Rentals}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <WorkerHousingAvailabilityRentalChart
                  data={data.workerAffordabilityData.nodes[0].data.Rentals}
                  dataNotes={data.affordabilityRentalNotes}
                  areaName={lgaName}
                />
              </div>
            </div>
          </PageContentWrapper>
        </React.Fragment>
      )}
    </WorkerAffordabilityContext.Consumer>
  )
}

export default WorkerAffordabilityTemplate

export const PageQuery = graphql`
  query WorkerAffordability($lgacode: String!, $geocode: String!, $benchmarkCode: String!) {
    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      GCC_Code
      GCC_Name
      Alias
    }

    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }

    entryMedianPrices: allAffordabilityAvailability(filter: { lga: { eq: $lgacode } }) {
      nodes {
        Rentals {
          Periods {
            Median_Unit
            Median_House
            Entry_Unit
            Entry_House
            Period_Name
          }
        }
        Sales {
          Periods {
            Median_Unit
            Median_House
            Entry_Unit
            Entry_House
            Period_Name
          }
        }
      }
    }

    workerIncomesData: allWorkerMedianIncomes(filter: { lga: { eq: $lgacode } }) {
      nodes {
        data {
          Periods {
            Occupations {
              Income
              Occupation
            }
            Year
          }
        }
      }
    }

    workerAffordabilityData: allWorkerAffordability(filter: { lga: { eq: $lgacode } }) {
      nodes {
        data {
          Rentals {
            Period_Name
            Total_Value
            Calculated_Afforability {
              Occupation
              Total
              Total_Percent
              Dwelling_Size {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_1_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
          }
          Sales {
            Period_Name
            Total_Value
            Calculated_Afforability {
              Occupation
              Total
              Total_Percent
              Dwelling_Size {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_1_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
          }
        }
      }
    }

    inputInterest: allAffordabilityBands(filter: { gccsa: { eq: $benchmarkCode } }) {
      nodes {
        data {
          Incomes {
            Interest_rate_december
            Interest_rate_june
            Year
          }
        }
      }
    }

    affordabilityMortgageNotes: markdownRemark(
      frontmatter: { slug: { eq: "worker-affordability-mortgage" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    affordabilityRentalNotes: markdownRemark(
      frontmatter: { slug: { eq: "worker-affordability-rental" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
  }
`
