import React, { useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from './../chartWrapper/index'
import ThematicMapCustomAreas from './thematic-map-custom-areas'
import { PropTypes } from 'prop-types'
import { isEmpty } from 'lodash'
import { ErrorMessageChart } from '../error-message-chart'

const HousingStressByIncomeMap = ({
  name,
  title,
  subTitle,
  dataSource,
  dataNotes,
  data,
  dataConfiguration, 
  pageContext
}) => {
  try {
    if (isEmpty(data)) {
      return (
          <ErrorMessageChart error="No data for chart"/>
      )
    }
    const { dataDisplayOptions, dataDisplayTypes, defaultDataDisplayOption, defaultDataDisplayType } = dataConfiguration

    const [optionSelected, setOptionSelected] = useState(dataDisplayOptions[defaultDataDisplayOption])

    const selectedDataType = dataDisplayTypes[defaultDataDisplayType] === 'number' ? true : false


    const changePropertyToMap = option => {
      setOptionSelected(option)
    }

    return (
      <ChartWrapper
        name={name}
        title={title}
        subTitle={`${optionSelected.label} ${subTitle}`}
        dataSource={dataSource}
        dataNotes={dataNotes}
        body={
          <div className="chart__map__wrapper">
            <ButtonGroup size="sm" className="d-print-none mb-2">
              {dataConfiguration.dataDisplayOptions.map((option, index) => (
                <Button
                  outline
                  key={index}
                  active={optionSelected.label === option.label}
                  onClick={() => changePropertyToMap(option)}
                >
                  {option.short_label}
                </Button>
              ))}
            </ButtonGroup>
            <ThematicMapCustomAreas
              data={data.edges.map(x => ({ ...x.node }))}
              propertyToMap={optionSelected}
              dataConfiguration={dataConfiguration}
              dataDisplayType={selectedDataType}
              pageContext={pageContext}
            />
          </div>
        }
      />
    )
  } catch (error) {
    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

HousingStressByIncomeMap.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  dataSource: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dataNotes: PropTypes.object,
  dataConfiguration: PropTypes.object.isRequired
}


export default HousingStressByIncomeMap
