import React from 'react'
import { head, last, filter, isEmpty} from 'lodash'
import { graphql } from 'gatsby'
import { passwordProtected } from '../config/password-proctected'
import Layout from '../components/layout'
import { InfoCard } from '../components/info-card'
import PageHeader from '../components/pageHeader'
import HousingIllustration from '../img/housing-illustration.svg'
import SideBar from '../components/sideBar'
import { Collapse, Button } from 'reactstrap'
import Moment from 'moment'
import { HamburgerIcon, CloseIcon } from '../components/landcom-icons'
import { formatNumberNA, formatNumber } from '../functions/formatNumber'
import formatPercentage from '../functions/formatPercentage'
import { sortByAscendingDatetime } from '../functions/datetimeHelpers'

class ClientHomePage extends React.Component {
  constructor(props) {
    super(props)

    let pass = null
    if (typeof window !== 'undefined') {
      pass = window.pass
    }

    this.state = {
      pass: pass,
      mobileMenuIsOpen: false,
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const userInputedPassword = event.target.pass.value

    this.setState({
      pass: userInputedPassword,
    })

    // Set the password on the window object, very simple varification
    if (typeof window !== 'undefined') {
      window.pass = userInputedPassword
    }
  }

  menuToggle = () => {
    this.setState({
      mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
    })
  }

  render() {
    const { lgaName, slug, productLinks, benchmarkName } = this.props.pageContext
    const { data, location } = this.props
    const alias = data.areas2XlsxAreas.Alias

    const clientData = head(data.allHomePageSummaryXlsxResult.nodes)

    const availabilityData =  head(data.availability.nodes)
    const availabilitySalesData =  last(sortByAscendingDatetime(availabilityData.Sales.Periods, 'Period_Name', "MMM YYYY"))
    const availabilityRentalsData =  last(sortByAscendingDatetime(availabilityData.Rentals.Periods, 'Period_Name', "MMM YYYY"))

    const medianData =  head(data.medianPrices.nodes)
    const medianSalesData =  last(sortByAscendingDatetime(medianData.data.Sales.Periods, 'Period_Name', "MMM YYYY"))
    const medianRentalsData =  last(sortByAscendingDatetime(medianData.data.Rentals.Periods, 'Period_Name', "MMM YYYY"))

    const medianBMData =  data.bmPricePointsRentalSales.nodes
  
    const medianBMSalesData = sortByAscendingDatetime(
        filter(medianBMData, node => node.ListingType == 'Sales'), 
        'Period_Name', 
        "MMM YYYY")
    const medianBMRentalsData = sortByAscendingDatetime(
      filter(medianBMData, node => node.ListingType == 'Rentals'), 
      'Period_Name', 
      "MMM YYYY")

    const approvals_data = data.approvals_lga.edges[0].node.data.LGA
    let approvals
    if (approvals_data[approvals_data.length - 1].Year_Label.includes('*')){
      // Use the last complete FY numbers
      approvals = approvals_data.slice(approvals_data.length - 3, approvals_data.length - 1)
    }
    else{
      approvals = approvals_data.slice(approvals_data.length - 2, approvals_data.length)
    }

    const access = head(
      filter(passwordProtected, ['lga', this.props.pageContext.slug])
    )

    return (
      <Layout
        clientName={lgaName}
        clientAlias={slug}
        productLinks={productLinks}
        clientLogo={this.props.data.allFile}
      >
        <PageHeader
          className="home"
          background={HousingIllustration}
          clientLogo={this.props.data.allFile}
        >
          <div className="row">
            <div className="col-1 d-md-none">
              <Button
                className="mobile-menu-btn"
                color="link"
                onClick={() => this.menuToggle()}
              >
                {!this.state.mobileMenuIsOpen && <HamburgerIcon />}
                {this.state.mobileMenuIsOpen && <CloseIcon />}
              </Button>
            </div>
            <div className="col-11 col-md-12">
              <h1>Explore the housing story for {lgaName}</h1>
              <p>
                Understand housing supply and demand, housing diversity,
                affordability and availability.
              </p>
              <div className="mobile-menu-wrapper">
                <Collapse isOpen={this.state.mobileMenuIsOpen}>
                  <SideBar id="mobile-sidebar" area={alias} slug={slug} productLinks={productLinks}/>
                </Collapse>
              </div>
            </div>
          </div>
        </PageHeader>
        {!isEmpty(access) && this.state.pass !== access.password ? (
          <div className="row text-center">
            <div className="col-md-6 offset-md-3">
              <div className="card m-4 py-4">
                <div className="login-title">
                  <h4>Enter password</h4>
                </div>
                <div className="card-body d-flex justify-content-center">
                  <form className="form-inline" onSubmit={this.handleSubmit}>
                    <input
                      className="form-control m-1"
                      name="pass"
                      type="password"
                    />
                    <button className="btn btn-primary m-1" type="submit">
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row py-4">
              <div className="col-md-3 d-none d-md-block">
                <SideBar id="desktop-sidebar" area={alias} slug={slug} productLinks={productLinks}/>
              </div>
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header="Need for affordable housing"
                      icon="AffordableHousing"
                      linkto={`${location.pathname}/affordable-housing-need`}
                    >
                      <p className="info-card__text__large">
                        {`${formatNumber(data.housingNeed.Total_need_number)} households`}
                      </p>
                      <p>
                        {`(${formatPercentage(data.housingNeed.Total_Need_Per)} of all households)`}
                      </p>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header="Household type in most need of affordable housing"
                      icon="HouseholdType"
                      linkto={`${location.pathname}/affordable-housing-need#what-types-of-households-are-in-need-of-affordable-housing`}
                    >
                      <p className="info-card__text__large">
                        {clientData.Household_type_need}
                      </p>
                      <p>
                        {`(${formatNumber(clientData.Household_type_need_number)} households)`}
                      </p>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`Median Price (${medianSalesData.Period_Name})`}
                      linkto={`${location.pathname}/prices-incomes#how-do-housing-prices-compare`}
                    >
                      <table className="info-card__table">
                        <tbody>
                          <tr>
                            <th colSpan={2} scope="col">
                              Median House Price (
                              {medianSalesData.Period_Name})
                            </th>
                          </tr>
                          <tr>
                            <td>{clientData.LGA_NAME}</td>
                            <td className="text-end">
                              {formatNumberNA(medianSalesData.Median_House,'$0,0')}
                            </td>
                          </tr>
                          <tr>
                            <td>{benchmarkName}</td>
                            <td className="text-end">
                              {formatNumber(
                                head(medianBMSalesData.filter(node => node.PropertyType == 'House')).Median
                              ,'$0,0')}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="info-card__table">
                        <tbody>
                          <tr>
                            <th colSpan={2} scope="col">
                              Median Unit Price ({medianSalesData.Period_Name}
                              )
                            </th>
                          </tr>
                          <tr>
                            <td>{clientData.LGA_NAME}</td>
                            <td className="text-end">
                              {formatNumberNA(medianSalesData.Median_Unit,'$0,0')}
                            </td>
                          </tr>
                          <tr>
                            <td>{benchmarkName}</td>
                            <td className="text-end">
                              {formatNumber(
                                head(medianBMSalesData.filter(node => node.PropertyType == 'Unit')).Median
                              ,'$0,0')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`Median Rental (${medianRentalsData.Period_Name})`}
                      linkto={`${location.pathname}/prices-incomes#how-do-rental-costs-compare`}
                    >
                      <table className="info-card__table">
                        <tbody>
                          <tr>
                            <th colSpan={2} scope="col">
                              Median House Rental (
                              {medianRentalsData.Period_Name})
                            </th>
                          </tr>
                          <tr>
                            <td>{clientData.LGA_NAME}</td>
                            <td className="text-end">
                              {formatNumberNA(medianRentalsData.Median_House,'$0,0')}
                              /week
                            </td>
                          </tr>
                          <tr>
                            <td>{benchmarkName}</td>
                            <td className="text-end">
                              {formatNumberNA(
                                head(medianBMRentalsData.filter(node => node.PropertyType == 'House')).Median
                              ,'$0,0')}
                              /week
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="info-card__table">
                        <tbody>
                          <tr>
                            <th colSpan={2} scope="col">
                              Median Unit Rental (
                              {medianRentalsData.Period_Name})
                            </th>
                          </tr>
                          <tr>
                            <td>{clientData.LGA_NAME}</td>
                            <td className="text-end">
                              {formatNumberNA(medianRentalsData.Median_Unit, '$0,0')}
                              /week
                            </td>
                          </tr>
                          <tr>
                            <td>{benchmarkName}</td>
                            <td className="text-end">
                              {formatNumberNA(
                                head(medianBMRentalsData.filter(node => node.PropertyType == 'Unit')).Median
                              , '$0,0')}
                              /week
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`Number of housing sales affordable to low income households (${availabilitySalesData.Period_Name})`}
                      icon="HousingSales"
                      linkto={`${location.pathname}/affordability-and-availability#how-affordable-is-buying-a-home`}
                    >
                      <p className="info-card__text__large">
                        {formatNumber(availabilitySalesData.Total.Low)}{' '}
                        sales
                      </p>
                      <p>
                        ({formatPercentage(availabilitySalesData.Total.Low_Percent)} of all sales)
                      </p>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`Number of rentals affordable to low income households (${availabilityRentalsData.Period_Name})`}
                      icon="Rentals"
                      linkto={`${location.pathname}/affordability-and-availability#how-affordable-is-renting`}
                    >
                      <p className="info-card__text__large">
                        {formatNumber(availabilityRentalsData.Total.Low)} rentals
                      </p>
                      <p>
                        (
                        {formatPercentage(
                          availabilityRentalsData.Total.Low_Percent
                        )} of all rentals)
                      </p>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`Population ${ Moment(clientData.Population_time_period, "MMM-YY").format("MMM YYYY")} (and annual change)`}
                      icon="Population"
                      linkto={`${location.pathname}/population-households`}
                    >
                      <p className="info-card__text__large">
                        {formatNumber(clientData.Population)}
                      </p>
                      <p>
                        {`(${formatPercentage(
                          clientData.Population_growth_rate_annual
                        )} p.a.)`}
                      </p>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`New dwelling approvals ${approvals[1].Year}-${approvals[1].Year + 1} FY (change from previous year)`}
                      linkto={`${location.pathname}/housing-and-approvals#where-is-new-building-happening`}
                    >
                      <table className="info-card__table" role="presentation">
                        <tbody>
                          <tr className="info-card__table__padding_bottom">
                            <td>Separate houses</td>
                            <td className="text-end">
                              {formatNumber(approvals[1].Number_House)}{' '}
                              (
                              {formatNumber(
                                approvals[1].Number_House - approvals[0].Number_House
                              ,'+0, 0')}
                              )
                            </td>
                          </tr>
                          <tr>
                            <td>Medium and higher density</td>
                            <td className="text-end">
                              {formatNumber(
                                approvals[1].Number_Other
                              )}{' '}
                              (
                              {formatNumber(
                                approvals[1].Number_Other - approvals[0].Number_Other
                              ,'+0, 0')}
                              )
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header="Average household size (2016-2021)"
                      linkto={`${location.pathname}/housing-consumption#what-is-the-household-size-for-each-dwelling-type`}
                    >
                      <table className="info-card__table">
                        <tbody>
                          <tr className="info-card__table__padding_bottom">
                            <td>{clientData.LGA_NAME}</td>
                            <td className="text-end">
                              {formatNumber(
                                clientData.Average_household_size
                              , '0.00')}{' '}
                              (
                              {formatNumber(
                                clientData.Average_household_size_change
                              ,'+0.00')}
                              )
                            </td>
                          </tr>
                          <tr>
                            <td>{benchmarkName}</td>
                            <td className="text-end">
                              {formatNumber(
                                clientData.Average_household_size_benchmark
                              , '0.00')}{' '}
                              (
                              {formatNumber(
                                clientData.Average_household_size_benchmark_change
                              ,'+0.00')}
                              )
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </InfoCard>
                  </div>
                  <div className="col-md-6 pb-4">
                    <InfoCard
                      header={`Dominant dwelling type (2021 Census)`}
                      linkto={`${location.pathname}/housing-and-approvals#what-is-the-dominant-housing-type`}
                    >
                      <table className="info-card__table">
                        <tbody>
                          <tr>
                            <td>{clientData.Dominant_dwelling_type_name}</td>
                            <td className="text-end">
                              (
                              {formatPercentage(
                                clientData.Dominant_dwelling_type_Per
                              )}
                              )
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="info-card__table">
                        <tbody>
                          <tr>
                            <th colSpan={2} scope="col">
                              Emerging dwelling type
                            </th>
                          </tr>
                          <tr>
                            <td>{clientData.Emerging_dwelling_type_name}</td>
                            <td className="text-end">
                              (
                              {formatNumber(
                                clientData.Emerging_dwelling_type_change
                              ,'+0, 0')}
                              )
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </InfoCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} 
      </Layout>
    )
  }
}

export const PageQuery = graphql`query ClientHome($geocode: String!, $lgacode: String!, $benchmarkCode: String!) {
  allFile(filter: {name: {eq: $geocode}}) {
    edges {
      node {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }

  housingNeed: housingStressNeed21XlsxHousingNeed(Area_Id: { eq: $lgacode }) {
    Total_need_number
    Total_Need_Per
  }

  medianPrices: allPricesIncomesMedians(filter: { lga: { eq: $lgacode } }) {
    nodes {
      data {
        Rentals {
          Periods {
            Median_Unit
            Median_House
            Period_Name
          }
        }
        Sales{
          Periods {
            Median_Unit
            Median_House
            Period_Name
          }
        }
      }
    }
  }

  bmPricePointsRentalSales: allRentalAndSalesByGccsaCsvSheet1(
    filter: { GCCSACode: { eq: $benchmarkCode } }
    sort: { fields: [Period], order: DESC }
  ) {
      nodes {
        ListingType
        PropertyType
        Median
        Period
    }
  }
    availability: allAffordabilityAvailability(filter: {lga: {eq: $lgacode }}) {
      nodes {
        Rentals {
          Periods {
            Period_Name
            Total {
              Low
              Low_Percent
            }
          }
        }
        Sales {
          Periods {
            Period_Name
            Total {
              Low
              Low_Percent
            }
          }
        }
      }
    }

    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      Alias
    }

    
    approvals_lga: allBuildingApprovalsLga(
      filter: {lga: {eq: $lgacode}}
    ) {
      edges {
        node {
          data {
            LGA {
              Number_House
              Number_Other
              Year
              Year_Label
            }
          }
        }
      }
    }

    allHomePageSummaryXlsxResult(filter: { LGA_CODE: { eq: $lgacode } }) {
      nodes {
        LGA_CODE
        LGA_NAME
        Household_type_need
        Household_type_need_number
        Population
        Population_growth_rate_annual
        Population_time_period
        Average_household_size
        Average_household_size_change
        Average_household_size_benchmark
        Average_household_size_benchmark_change
        Dominant_dwelling_type_name
        Dominant_dwelling_type_Per
        Emerging_dwelling_type_name
        Emerging_dwelling_type_change
      }
    }
  }
`

export default ClientHomePage
