import React from 'react'
import WorkerAffordabilityLgaChart from './worker-affordability-lga-chart'
import * as Datasource from '../../config/text-constants'

const WorkerAffordabilityRentalLgaChart = ({
  alias,
  pricePoints,
  interest,
  dataNotes,
  areaName,
  data,
  affordableData
}) => (
  <WorkerAffordabilityLgaChart
    alias={alias}
    title="How affordable is renting for local workers?"
    dataSource={Datasource.Proptrack}
    dataNotes={dataNotes}
    name="worker-affordable-rental-lga"
    pricePoints={pricePoints}
    interest={interest}
    type={"rental"}
    areaName={areaName}
    data={data}
    affordableData = {affordableData}
    proptrackDisclaimer={true}
  />
)

export default WorkerAffordabilityRentalLgaChart
