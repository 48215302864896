import React from 'react'
import { pill_select } from './style.module.scss'

const PillSelect = props => {
  const { className, children } = props

  if (typeof children === 'object' && children[0].type === 'option') {
    return (
      <select {...props} className={`${pill_select} ${className}`}>
        {children}
      </select>
    )
  }

  return (
    <span {...props} className={`${pill_select} ${className}`}>
      {children}
    </span>
  )
}

export default PillSelect
