import React from 'react'

const HousingConsumptionDwellingsInfo = ({ houseHoldType, LGAName, clientAlias }) => {
  return (
    <div>
      <p>
        These charts break down the housing consumption of {houseHoldType.toLowerCase()} by number
        of bedrooms, and also show how this has changed over time and how it varies between the
        small areas in {LGAName}.
      </p>
      <p>
        This information is more detailed and is useful for analysing household types – particularly
        those which make up a large share of {LGAName}’s population, or those which are main
        emerging groups. For instance, it may be that Lone Persons have an increasing share living
        in 4-bedroom separate houses in particular areas, which could be due to a lack of affordable
        downsizing options for elderly population. Evidence that lone persons are downsizing in
        other parts of the region can show the need for greater housing diversity in particular
        places. On the other hand, a large share of one-parent families in two bedroom separate
        homes could indicate housing affordability issues as this household type is often
        particularly disadvantaged.
      </p>
      <p>
        Data are shown in comparison to a benchmark, so users can see how the distribution of a
        particular household type among housing types differs or is similar to the wider region.
        Care should be taken, however and the data should be analysed in conjunction with actual
        dwelling supply data from the{' '}
        <strong>
          <a href={`/${clientAlias}/housing-and-approvals`}>Housing Supply</a>
        </strong>{' '}
        page. In many cases occupancy is also about availability of particular housing types. Also
        households may have a high percentage in a certain dwelling type but not high numbers in
        total, so check the number as well as the percentage.
      </p>
    </div>
  )
}

export default HousingConsumptionDwellingsInfo
