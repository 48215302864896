import React, {useState} from 'react'
import HelpModal from '../helpModal'

const FooterHelpButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const toggle = () => setModalIsOpen(!modalIsOpen)

  return (
    <>
      <button className="btn btn-link" onClick={toggle}>
        Help
      </button>
      <HelpModal modalIsOpen={modalIsOpen} toggle={toggle} />
    </>
  )
}

export default FooterHelpButton
