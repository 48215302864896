import React from 'react'
import { map, flow, max, flatten, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import { formatNumber } from '../../functions/formatNumber'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'

class HousingNeedByHouseholdTypeChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'number',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    try {
      const { data, benchmarkData, pageContext, dataNotes } = this.props
      const benchmarkName = pageContext.benchmarkName

      if (isEmpty(data) || isEmpty(benchmarkData)) {
        return (
            <ErrorMessageChart error="No data for chart"/>
        )
      }

      const dataDefinition = [
        {
          name: 'Couple',
          key: 'Couple_with_no_children_',
        },
        {
          name: 'Family',
          key: 'Families_',
        },
        {
          name: 'Lone person',
          key: 'Lone_person_household_',
        },
        {
          name: 'Group household',
          key: 'Group_household_',
        }
      ]
  
      const chartData = dataDefinition.map(definition => {
        return {
          name: definition.name,          
          Very_Low_Number: data[definition.key + 'Very_Low_Number'],
          Very_Low_Percent: data[definition.key + 'Very_Low_Percent'],
          BM_Very_Low_Percent:
            benchmarkData[definition.key + 'Very_Low_Percent'],
          Low_Number: data[definition.key + 'Low_Number'],
          Low_Percent: data[definition.key + 'Low_Percent'],
          BM_Low_Percent: benchmarkData[definition.key + 'Low_Percent'],
          Moderate_Number: data[definition.key + 'Moderate_Number'],
          Moderate_Percent: data[definition.key + 'Moderate_Percent'],
          BM_Moderate_Percent:
            benchmarkData[definition.key + 'Moderate_Percent'],
          Total: data[definition.key + 'Total_households'],
        }
      })

      const maxValue = flow([
        x =>
          map(x, item => {
            if (this.state.dataType === 'number') {
              return item.Very_Low_Number + item.Low_Number + item.Moderate_Number
            }
            return item.Very_Low_Percent + item.Low_Percent + item.Moderate_Percent
            // max([
            //   item.Very_Low_Percent + item.Low_Percent + item.Moderate_Percent,
            //   item.BM_Very_Low_Percent +
            //     item.BM_Low_Percent +
            //     item.BM_Moderate_Percent,
            // ])
          }),
        flatten,
        max,
      ])(chartData)

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(4)
        .ticks(4)

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}:{' '}
                  {this.state.dataType === 'percent'
                    ? formatPercentage(item.value)
                    : formatNumber(item.value)}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`housing-need-by-household-type`}
          title={`What types of households are in need of affordable housing?`}
          subTitle={`Households unable to access market priced rental housing by
          household type, 2021`}
          dataSource={Datasource.ABS2021}
          dataNotes={dataNotes}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none mb-2">
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
              </ButtonGroup>
              <div className="chart__viz" style={{ width: '100%', height: 360 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData} margin={{ right: 30, bottom: 20 }} layout="vertical">
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <YAxis type="category" dataKey="name" tickLine={false} width={140} />
                    <XAxis
                      type="number"
                      unit={this.state.dataType === 'number' ? '' : '%'}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.count + 1}
                      tickFormatter={value => formatNumber(value)}
                    >
                      <Label
                        value={
                          this.state.dataType === 'number'
                            ? 'Number of households'
                            : 'Percentage of household type'
                        }
                        offset={-15}
                        position="insideBottomLeft"
                      />
                    </XAxis>
                    <Tooltip content={<CustomTooltip />} />
                    {this.state.dataType === 'number' && (
                      <Bar
                        dataKey="Very_Low_Number"
                        name="Very low income"
                        stackId="a"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-primary')
                        }
                      />
                    )}
                    {this.state.dataType === 'number' && (
                      <Bar
                        dataKey="Low_Number"
                        name="Low income"
                        stackId="a"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-muted20')
                        }
                      />
                    )}
                    {this.state.dataType === 'number' && (
                      <Bar
                        dataKey="Moderate_Number"
                        name="Moderate income"
                        stackId="a"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-muted')
                        }
                        label={props => (
                          <CustomHorizontalLabel {...props} formatter={(value) => formatNumber(value)} />
                        )}
                      />
                    )}

                    {this.state.dataType === 'percent' && [
                      <Bar
                        dataKey="Very_Low_Percent"
                        name="Very low income"
                        stackId="a"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-primary')
                        }
                        key={1}
                      />,
                      <Bar
                        dataKey="Low_Percent"
                        name="Low income"
                        stackId="a"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-muted20')
                        }
                        key={2}
                      />,
                      <Bar
                        dataKey="Moderate_Percent"
                        name="Moderate income"
                        stackId="a"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-muted')
                        }
                        key={3}
                        label={props => (
                          <CustomHorizontalLabel {...props} formatter={(value) => formatPercentage(value)} />
                        )}
                      />,
                    ]}

                    {this.state.dataType === 'percent' && [
                      <Bar
                        dataKey="BM_Very_Low_Percent"
                        name={`${benchmarkName} Very low income`}
                        stackId="b"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-700')
                        }
                        key={4}
                      />,
                      <Bar
                        dataKey="BM_Low_Percent"
                        name={`${benchmarkName} Low income`}
                        stackId="b"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                        key={5}
                      />,
                      <Bar
                        dataKey="BM_Moderate_Percent"
                        name={`${benchmarkName} Moderate income`}
                        stackId="b"
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-300')
                        }
                        key={6}
                        label={props => (
                          <CustomHorizontalLabel {...props} formatter={(value) => formatPercentage(value)} />
                        )}
                      />,
                    ]}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

HousingNeedByHouseholdTypeChart.propTypes = {
  data: PropTypes.object.isRequired,
  benchmarkData: PropTypes.object.isRequired,
  dataNotes: PropTypes.object,
}

export default HousingNeedByHouseholdTypeChart
