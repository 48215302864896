import React from 'react'
import HousingStressPercentageChart from './housing-stress-percentage'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

const HousingStressMortgagePercentageChart = ({
  data,
  benchmarkData,
  areaName,
  benchmarkName,
  dataNotes,
}) => {
  try {
    const chartData = [
      {
        group: areaName,
        Total_Per: data.Total_Mortgage_Stress_Per,
        Total_Num: data.Total_Mortgage_Stress_No,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary'),
      },
      {
        group: benchmarkName,
        Total_Per: benchmarkData.Total_Mortgage_Stress_Per,
        Total_Num: benchmarkData.Total_Mortgage_Stress_No,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500'),
      },
    ]

    return (
      <HousingStressPercentageChart
        chartData={chartData}
        dataNotes={dataNotes}
        name={`mortgage-stress-percentage`}
        title={`Mortgage stress compared`}
        subTitle={`Households with a mortgage in housing stress`}
        dataSource={Datasource.ABS2021}
      />
    )
  } catch (error) {
    console.error(error)

    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

export default HousingStressMortgagePercentageChart
