import React, { useContext, useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import { Button, ButtonGroup } from 'reactstrap'
import { PropTypes } from 'prop-types'
import ChartWrapper from './../chartWrapper/index'
import PillSelect from '../pillSelect'
import TimePeriodDropdown from '../timePeriodDropdown'
import { isEmpty, minBy, maxBy } from 'lodash'
import Moment from 'moment'
import { scaleLinear } from 'd3-scale'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../../components/error-message-chart'
import HouseholdTypeDropdown from '../householdTypeDropdown'
import { AvailabilityContext } from '../../hooks/availabilityContext'
import DwellingSizeDropdown from '../dwellingSizeDropdown'
import { formatNumber } from '../../functions/formatNumber'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'
import {compareEndValueToPastvalue} from '../../functions/affordabilityChartHelpers'

const HousingAvailabilityChart = ({
  availabilityData,
  dataNotes,
  name,
  title,
  type,
  proptrackDisclaimer,
}) => {
  const context = useContext(AvailabilityContext)
  if (isEmpty(availabilityData)) {
    return <ErrorMessageChart error="No data for chart" />
  }
  const data = availabilityData
  const periods = data.Periods.sort((a, b) =>
    Moment(b.Period_Name, 'MMM YYYY').diff(Moment(a.Period_Name, 'MMM YYYY'))
  ).map(p => p.Period_Name)
  const comparisonPeriods = periods && periods.length > 1 && periods.slice(1)

  const [dataType, setDataType] = useState('percent')
  const activePeriod = context.activePeriod == null ? periods[0] : context.activePeriod
  const latestPeriod = periods && periods.length > 0 && periods[0]

  useEffect(() => {
    if (type === 'mortgage') {
      context.setAvailabilitySalesLow(
        data.Periods.find(p =>
          context.activePeriod
            ? p.Period_Name == context.activePeriod
            : p.Period_Name == latestPeriod
        )[context.householdType].Low_Percent
      )
    } else {
      context.setAvailabilityRentalsLow(
        data.Periods.find(p =>
          context.activePeriod
            ? p.Period_Name == context.activePeriod
            : p.Period_Name == latestPeriod
        )[context.householdType].Low_Percent
      )
    }
  }, [context.householdType, context.activePeriod])

  useEffect(() => {
    if (!context.activePeriod) {
      context.setActivePeriod(latestPeriod)
    }
    if (!context.activeChangeStartPeriod && periods.length > 1) {
      context.setActiveChangeStartPeriod(periods[1])
    }
  }, [])

  const handleComparisonPeriodDropdownOnChange = event => {
    context.setActiveChangeStartPeriod(event.target.value)
  }

  const handleActivePeriodDropdownOnChange = event => {
    context.setActivePeriod(event.target.value)
  }

  const handleHouseHoldTypeDropdownOnChange = event => {
    context.setHouseholdType(event.target.value)
    if (type === 'mortgage') {
      context.setAvailabilitySalesLow(
        data.Periods.find(p => p.Period_Name == activePeriod)[event.target.value].Low_Percent
      )
    } else {
      context.setAvailabilityRentalsLow(
        data.Periods.find(p => p.Period_Name == activePeriod)[event.target.value].Low_Percent
      )
    }
  }

  const handleDwellingSizeDropdownOnChange = event => {
    context.setDwellingSize(event.target.value)
  }

  const SubTitle = () => {
    if (dataType === 'change')
      return (
        <>
          Change in affordable {type === 'mortgage' ? 'sales' : 'rental listings'} of{' '}
          <DwellingSizeDropdown
            onChange={handleDwellingSizeDropdownOnChange}
            value={context.dwellingSize}
          />{' '}
          between (12 months ending){' '}
          {periods.length > 2 && (
            <TimePeriodDropdown
              onChange={handleComparisonPeriodDropdownOnChange}
              periods={comparisonPeriods}
              currentPeriod={context.activeChangeStartPeriod}
            />
          )}
          {periods.length === 2 && <PillSelect>{context.activeChangeStartPeriod}</PillSelect>} and{' '}
          <PillSelect>{latestPeriod}</PillSelect> for{' '}
          <HouseholdTypeDropdown
            onChange={handleHouseHoldTypeDropdownOnChange}
            value={context.householdType}
          />
        </>
      )

    return (
      <>
        Affordable {type === 'mortgage' ? 'sales' : 'rental listings'} of{' '}
        <DwellingSizeDropdown
          onChange={handleDwellingSizeDropdownOnChange}
          value={context.dwellingSize}
        />{' '}
        for the 12 months to{' '}
        {periods.length > 1 && (
          <TimePeriodDropdown
            onChange={handleActivePeriodDropdownOnChange}
            periods={periods}
            currentPeriod={activePeriod}
          />
        )}{' '}
        for{' '}
        <HouseholdTypeDropdown
          onChange={handleHouseHoldTypeDropdownOnChange}
          value={context.householdType}
        />
      </>
    )
  }

  try {
    const dwellingSizeAll = 'BedroomAll'
    const dwellingSizeBedroom1 = 'Bedroom1'
    const dwellingSizeBedroom2 = 'Bedroom2'
    const dwellingSizeBedroom3Plus = 'Bedroom3Plus'
    const dwellingSizeNotStated = 'BedroomNotStated'

    const dataTypeChange = 'change'
    const dataTypeNumber = 'number'
    const dataTypePercent = 'percent'
    const dataTypePercentOfAffordableDwellings = 'percentOfAffordableDwellings'

    const total = 'Total_Value'
    const totalChange = 'Total_Change'

    const chartDataKeys = {
      [total]: total,
      [totalChange]: totalChange,
      [dwellingSizeAll]: {
        [dataTypeChange]: 'change',
        [dataTypeNumber]: 'number',
        [dataTypePercent]: 'percent',
      },
      [dwellingSizeBedroom1]: {
        [dataTypeChange]: 'bedrooms1Change',
        [dataTypeNumber]: 'bedrooms1Number',
        [dataTypePercent]: 'bedrooms1Percent',
        [dataTypePercentOfAffordableDwellings]: 'bedrooms1PercentOfAffordableDwellings',
      },
      [dwellingSizeBedroom2]: {
        [dataTypeChange]: 'bedrooms2Change',
        [dataTypeNumber]: 'bedrooms2Number',
        [dataTypePercent]: 'bedrooms2Percent',
        [dataTypePercentOfAffordableDwellings]: 'bedrooms2PercentOfAffordableDwellings',
      },
      [dwellingSizeBedroom3Plus]: {
        [dataTypeChange]: 'bedrooms3PlusChange',
        [dataTypeNumber]: 'bedrooms3PlusNumber',
        [dataTypePercent]: 'bedrooms3PlusPercent',
        [dataTypePercentOfAffordableDwellings]: 'bedrooms3PlusPercentOfAffordableDwellings',
      },
      [dwellingSizeNotStated]: {
        [dataTypeChange]: 'bedroomsNotStatedChange',
        [dataTypeNumber]: 'bedroomsNotStatedNumber',
        [dataTypePercent]: 'bedroomsNotStatedPercent',
      },
    }

    const getChartDataKey = (dwellingContext, dwellingSize, dataType) => {
      if (
        dataType === 'percent' &&
        dwellingContext !== dwellingSizeAll &&
        dwellingContext === dwellingSize
      ) {
        return chartDataKeys[dwellingSize][dataTypePercentOfAffordableDwellings]
      }
      return chartDataKeys[dwellingSize][dataType]
    }

    const chartDataWithChange = (data, latestPeriod, comparisonPeriod) => {
      const dataNode = data.Periods.find(p => p.Period_Name == latestPeriod)
      const dataNodeHouseholdType = dataNode[context.householdType]

      const comparisonNode = comparisonPeriod
        ? data.Periods.find(p => p.Period_Name == comparisonPeriod)
        : data.Periods.length > 1
        ? data.Periods[1]
        : null

      const comparisonNodeHouseholdType = comparisonNode && comparisonNode[context.householdType]
      return [
        {
          name: 'Very low income',
          [chartDataKeys[total]]: dataNode.Total_Value,
          [chartDataKeys[totalChange]]: comparisonNode
            ? compareEndValueToPastvalue(dataNode.Total_Value, comparisonNode.Total_Value)
            : null,
          [chartDataKeys[dwellingSizeAll][dataTypeNumber]]: dataNodeHouseholdType.Very_Low,
          [chartDataKeys[dwellingSizeAll][dataTypePercent]]: dataNodeHouseholdType.Very_Low_Percent,
          [chartDataKeys[dwellingSizeAll][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Very_Low,
                comparisonNodeHouseholdType.Very_Low
              )
            : null,
          color:
            typeof window !== `undefined` &&
            getComputedStyle(document.body).getPropertyValue('--bs-primary'),
          [chartDataKeys[dwellingSizeBedroom1][dataTypeNumber]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_1,
          [chartDataKeys[dwellingSizeBedroom1][dataTypePercent]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_1_Percent,
          [chartDataKeys[dwellingSizeBedroom1][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes
              .Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All,
          [chartDataKeys[dwellingSizeBedroom1][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_1,
                comparisonNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_1
              )
            : null,
          [chartDataKeys[dwellingSizeBedroom2][dataTypeNumber]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_2,
          [chartDataKeys[dwellingSizeBedroom2][dataTypePercent]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_2_Percent,
          [chartDataKeys[dwellingSizeBedroom2][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes
              .Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All,
          [chartDataKeys[dwellingSizeBedroom2][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_2,
                comparisonNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_2
              )
            : null,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeNumber]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_3Plus,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercent]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_3Plus_Percent,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes
              .Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_3Plus,
                comparisonNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_3Plus
              )
            : null,
          [chartDataKeys[dwellingSizeNotStated][dataTypeNumber]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_Not_Stated,
          [chartDataKeys[dwellingSizeNotStated][dataTypePercent]]:
            dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_Not_Stated_Percent,
          [chartDataKeys[dwellingSizeNotStated][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_Not_Stated,
                comparisonNodeHouseholdType.Very_Low_Dwelling_Sizes.Bedrooms_Not_Stated
              )
            : null,
        },
        {
          name: 'Low income',
          [chartDataKeys[total]]: dataNode.Total_Value,
          [chartDataKeys[totalChange]]: comparisonNode
            ? compareEndValueToPastvalue(dataNode.Total_Value, comparisonNode.Total_Value)
            : null,
          [chartDataKeys[dwellingSizeAll][dataTypeNumber]]: dataNodeHouseholdType.Low,
          [chartDataKeys[dwellingSizeAll][dataTypePercent]]: dataNodeHouseholdType.Low_Percent,
          [chartDataKeys[dwellingSizeAll][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(dataNodeHouseholdType.Low, comparisonNodeHouseholdType.Low)
            : null,
          color:
            typeof window !== `undefined` &&
            getComputedStyle(document.body).getPropertyValue('--bs-muted20'),
          [chartDataKeys[dwellingSizeBedroom1][dataTypeNumber]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_1,
          [chartDataKeys[dwellingSizeBedroom1][dataTypePercent]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_1_Percent,
          [chartDataKeys[dwellingSizeBedroom1][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes
              .Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All,
          [chartDataKeys[dwellingSizeBedroom1][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_1,
                comparisonNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_1
              )
            : null,
          [chartDataKeys[dwellingSizeBedroom2][dataTypeNumber]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_2,
          [chartDataKeys[dwellingSizeBedroom2][dataTypePercent]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_2_Percent,
          [chartDataKeys[dwellingSizeBedroom2][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes
              .Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All,
          [chartDataKeys[dwellingSizeBedroom2][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_2,
                comparisonNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_2
              )
            : null,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeNumber]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_3Plus,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercent]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_3Plus_Percent,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes
              .Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_3Plus,
                comparisonNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_3Plus
              )
            : null,
          [chartDataKeys[dwellingSizeNotStated][dataTypeNumber]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_Not_Stated,
          [chartDataKeys[dwellingSizeNotStated][dataTypePercent]]:
            dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_Not_Stated_Percent,
          [chartDataKeys[dwellingSizeNotStated][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_Not_Stated,
                comparisonNodeHouseholdType.Low_Dwelling_Sizes.Bedrooms_Not_Stated
              )
            : null,
        },
        {
          name: 'Moderate income',
          [chartDataKeys[total]]: dataNode.Total_Value,
          [chartDataKeys[totalChange]]: comparisonNode
            ? compareEndValueToPastvalue(dataNode.Total_Value, comparisonNode.Total_Value)
            : null,
          [chartDataKeys[dwellingSizeAll][dataTypeNumber]]: dataNodeHouseholdType.Moderate,
          [chartDataKeys[dwellingSizeAll][dataTypePercent]]: dataNodeHouseholdType.Moderate_Percent,
          [chartDataKeys[dwellingSizeAll][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Moderate,
                comparisonNodeHouseholdType.Moderate
              )
            : null,
          color:
            typeof window !== `undefined` &&
            getComputedStyle(document.body).getPropertyValue('--bs-muted'),
          [chartDataKeys[dwellingSizeBedroom1][dataTypeNumber]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_1,
          [chartDataKeys[dwellingSizeBedroom1][dataTypePercent]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_1_Percent,
          [chartDataKeys[dwellingSizeBedroom1][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes
              .Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All,
          [chartDataKeys[dwellingSizeBedroom1][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_1,
                comparisonNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_1
              )
            : null,
          [chartDataKeys[dwellingSizeBedroom2][dataTypeNumber]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_2,
          [chartDataKeys[dwellingSizeBedroom2][dataTypePercent]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_2_Percent,
          [chartDataKeys[dwellingSizeBedroom2][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes
              .Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All,
          [chartDataKeys[dwellingSizeBedroom2][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_2,
                comparisonNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_2
              )
            : null,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeNumber]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_3Plus,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercent]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_3Plus_Percent,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercentOfAffordableDwellings]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes
              .Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All,
          [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_3Plus,
                comparisonNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_3Plus
              )
            : null,
          [chartDataKeys[dwellingSizeNotStated][dataTypeNumber]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_Not_Stated,
          [chartDataKeys[dwellingSizeNotStated][dataTypePercent]]:
            dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_Not_Stated_Percent,
          [chartDataKeys[dwellingSizeNotStated][dataTypeChange]]: comparisonNodeHouseholdType
            ? compareEndValueToPastvalue(
                dataNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_Not_Stated,
                comparisonNodeHouseholdType.Moderate_Dwelling_Sizes.Bedrooms_Not_Stated
              )
            : null,
        },
      ]
    }
   
    const chartData = chartDataWithChange(
      data,
      dataType === 'change' ? latestPeriod : activePeriod,
      context.activeChangeStartPeriod
    )

    //const chartDataKey = chartDataKeys[context.dwellingSize][dataType]
    const chartDataKey = getChartDataKey(context.dwellingSize, context.dwellingSize, dataType)
    const maxValue = maxBy(chartData, d => d[chartDataKey])[chartDataKey]
    const minValue = minBy(chartData, d => d[chartDataKey])[chartDataKey]

    const domain = [dataType === 'change' ? minValue : 0, maxValue < 0 ? 0 : maxValue]

    const ticks = scaleLinear()
      .domain(domain)
      .nice(9)
      .ticks(9)

    const CustomTooltip = props => {
      const { active } = props
      const salesOrrentals = type === 'mortgage' ? 'sales' : 'rental listings'

      const ChangeBadge = ({ value, children }) => {
        const className = value >= 0 ? 'text-success' : 'text-danger'

        return <span className={className}>{children}</span>
      }

      if (active) {
        const { payload, label } = props
        const dwellingSizeText = (() => {
          switch (context.dwellingSize) {
            case dwellingSizeBedroom1:
              return '1 bedroom dwellings'
            case dwellingSizeBedroom2:
              return '2 bedroom dwellings'
            case dwellingSizeBedroom3Plus:
              return '3+ bedroom dwellings'
            default:
              return 'dwelling size'
          }
        })()
        const householdTypeText = (() => {
          switch (context.householdType) {
            case 'Total':
              return 'households'
            case 'Lone_person':
              return 'lone person households'
            case 'Couple_no_children':
              return 'couples without children'
            case 'Couple_or_one_parent_family':
              return 'families with children'
            case 'One_parent_family':
              return 'one parent families'
            case 'Couple_family_with_children':
              return 'couple families with children'
            default:
              return 'household type'
          }
        })()
        const percentageValue = formatPercentage(
          payload[0].payload[context.dwellingSize + 'PercentOfAffordableDwellings']
        )

        const numericValue = formatNumber(payload[0].payload[context.dwellingSize + 'Number'])

        const incomeBand = payload[0].payload.name.toLowerCase()
        const purchaseOrRent = type === 'mortgage' ? 'purchase' : 'rent'

        if (dataType === 'percent' && context.dwellingSize !== 'BedroomAll') {
          return (
            <TooltipWrapper>
              <div>
                <p className="recharts-tooltip-value m-0 font-weight-bold">{label}</p>
                <p className="recharts-tooltip-value m-0">
                  Proportion of {dwellingSizeText} affordable to {purchaseOrRent} for {incomeBand}{' '}
                  {householdTypeText}: {percentageValue}
                </p>
                <p className="recharts-tooltip-value m-0">
                  Number of {dwellingSizeText} affordable to {purchaseOrRent} for {incomeBand}{' '}
                  {householdTypeText}: {numericValue}{' '}
                </p>
              </div>
            </TooltipWrapper>
          )
        } else
          return (
            <TooltipWrapper>
              <p className="recharts-tooltip-label m-0 font-weight-bold">
                {label}{' '}
                {periods.length > 1 && (
                  <span>
                    <br />
                    (compared to period ending{' '}
                    {Moment(context.activeChangeStartPeriod, 'MMM YYYY').format('MMM YYYY')})
                  </span>
                )}
              </p>
              <div style={{ marginTop: 10 }}>
                <p className="recharts-tooltip-value m-0">
                  Total number of {salesOrrentals}:{' '}
                  {formatNumber(payload[0].payload.Total_Value)}{' '}
                  {periods.length > 1 && (
                    <>
                      (
                      <ChangeBadge value={payload[0].payload[totalChange]}>
                        {formatNumber(payload[0].payload[totalChange],'+0,0')}
                      </ChangeBadge>
                      )
                    </>
                  )}
                </p>
              </div>
              <div style={{ marginTop: 10 }}>
                <p className="recharts-tooltip-value m-0">
                  Number of affordable {salesOrrentals}:{' '}
                  {formatNumber(payload[0].payload.number)}{' '}
                  {periods.length > 1 && (
                    <>
                      (
                      <ChangeBadge value={payload[0].payload.change}>
                        {formatNumber(payload[0].payload.change,'+0,0')}
                      </ChangeBadge>
                      )
                    </>
                  )}
                </p>
                <p className="recharts-tooltip-value m-0 text-muted">
                  Proportion of all {salesOrrentals}: {formatPercentage(payload[0].payload.percent)}
                </p>
              </div>
              <div style={{ marginTop: 10 }}>
                <p className="recharts-tooltip-value m-0">
                  1 bedroom dwellings: {formatNumber(payload[0].payload.bedrooms1Number)} (
                  <ChangeBadge value={payload[0].payload.bedrooms1Change}>
                    {formatNumber(payload[0].payload.bedrooms1Change,'+0,0')}
                  </ChangeBadge>
                  )
                </p>
                <p className="recharts-tooltip-value m-0 text-muted">
                  Proportion of all {salesOrrentals}:{' '}
                  {formatPercentage(payload[0].payload.bedrooms1Percent)}
                </p>
              </div>
              <div style={{ marginTop: 10 }}>
                <p className="recharts-tooltip-value m-0">
                  2 bedroom dwellings: {formatNumber(payload[0].payload.bedrooms2Number)} (
                  <ChangeBadge value={payload[0].payload.bedrooms2Change}>
                    {formatNumber(payload[0].payload.bedrooms2Change,'+0,0')}
                  </ChangeBadge>
                  )
                </p>
                <p className="recharts-tooltip-value m-0 text-muted">
                  Proportion of all {salesOrrentals}:{' '}
                  {formatPercentage(payload[0].payload.bedrooms2Percent)}
                </p>
              </div>
              <div style={{ marginTop: 10 }}>
                <p className="recharts-tooltip-value m-0">
                  3+ bedroom dwellings:{' '}
                  {formatNumber(payload[0].payload.bedrooms3PlusNumber)} (
                  <ChangeBadge value={payload[0].payload.bedrooms3PlusChange}>
                    {formatNumber(payload[0].payload.bedrooms3PlusChange,'+0,0')}
                  </ChangeBadge>
                  )
                </p>
                <p className="recharts-tooltip-value m-0 text-muted">
                  Proportion of all {salesOrrentals}:{' '}
                  {formatPercentage(payload[0].payload.bedrooms3PlusPercent)}
                </p>
              </div>
              <div style={{ marginTop: 10 }}>
                <p className="recharts-tooltip-value m-0">
                  Dwelling size not stated:{' '}
                  {formatNumber(payload[0].payload.bedroomsNotStatedNumber)} (
                  <ChangeBadge value={payload[0].payload.bedroomsNotStatedChange}>
                    {formatNumber(payload[0].payload.bedroomsNotStatedChange,'+0,0')}
                  </ChangeBadge>
                  )
                </p>
                <p className="recharts-tooltip-value m-0 text-muted">
                  Proportion of all {salesOrrentals}:{' '}
                  {formatPercentage(payload[0].payload.bedroomsNotStatedPercent)}
                </p>
              </div>
            </TooltipWrapper>
          )
      }
      return null
    }

    const getFormatedNumber = number => {
      if (dataType === 'percent') {
        return formatPercentage(number)
      }
      if (dataType === 'number') {
        return formatNumber(number)
      }
      if (dataType === 'change') {
        return formatNumber(number,'+0,0')
      }
    }

    const legendContent = [
      {
        id: '1 bedroom dwellings',
        type: 'square',
        color: 'rgb(200, 161, 227)',
        value: '1 bedroom dwellings',
      },
      {
        id: '2 bedroom dwellings',
        type: 'square',
        color: 'rgb(158, 90, 205)',
        value: '2 bedroom dwellings',
      },
      {
        id: '3+ bedroom dwellings',
        type: 'square',
        color: '#7513B8',
        value: '3+ bedroom dwellings',
      },
      {
        id: 'Dwelling size not stated',
        type: 'square',
        color: `${typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}`,
        value: 'Dwelling size not stated',
      },
    ]

    return (
      <ChartWrapper
        name={name}
        title={title}
        subTitle={<SubTitle />}
        dataSource={Datasource.Proptrack}
        dataNotes={dataNotes}
        proptrackDisclaimer={proptrackDisclaimer}
        body={
          <div>
            <div className="chart__controls form-inline d-print-none mb-2">
              <ButtonGroup size="sm" className="d-print-none  mb-2 me-2">
                <Button
                  outline
                  onClick={() => setDataType('percent')}
                  active={dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={() => setDataType('number')}
                  active={dataType === 'number'}
                >
                  Number
                </Button>
                {periods.length > 1 && (
                  <Button
                    outline
                    onClick={() => setDataType('change')}
                    active={dataType === 'change'}
                  >
                    Change
                  </Button>
                )}
              </ButtonGroup>
            </div>

            <div className="chart__viz" style={{ width: '100%', height: 200 }}>
              <ResponsiveContainer>
                <BarChart data={chartData} margin={{ bottom: 15, right: 40 }} layout="vertical">
                  <Tooltip content={<CustomTooltip />} />
                  <YAxis type="category" dataKey="name" tickLine={false} width={100} />
                  <XAxis
                    type="number"
                    tickFormatter={getFormatedNumber}
                    ticks={ticks}
                    interval={0}
                    tickCount={ticks.count + 1}
                  />
                  <CartesianGrid horizontal={false} vertical={false} />
                  <Legend
                    align="left"
                    verticalAlign="bottom"
                    layout="horizontal"
                    payload={legendContent}
                    iconSize={16}
                    wrapperStyle={{ bottom: 5 }}
                    formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                  />
                  {(context.dwellingSize === 'BedroomAll' ||
                    context.dwellingSize === 'Bedroom1') && (
                    <Bar
                      dataKey={getChartDataKey(
                        context.dwellingSize,
                        dwellingSizeBedroom1,
                        dataType
                      )}
                      name="No. of sales"
                      unit={dataType === 'percent' ? '%' : ''}
                      isAnimationActive={false}
                      stackId="a"
                      label={(props) => 
                        <CustomHorizontalLabel {...props} 
                          formatter={(value) => context.dwellingSize === 'Bedroom1'?  getFormatedNumber(value): null}
                      />}
                    >
                      {chartData.map((entry, index) => (
                        <Cell fill="rgb(200, 161, 227)" key={`cell-${index}`} />
                      ))}
                    </Bar>
                  )}
                  {(context.dwellingSize === 'BedroomAll' ||
                    context.dwellingSize === 'Bedroom2') && (
                    <Bar
                      dataKey={getChartDataKey(
                        context.dwellingSize,
                        dwellingSizeBedroom2,
                        dataType
                      )}
                      name="No. of sales"
                      unit={dataType === 'percent' ? '%' : ''}
                      isAnimationActive={false}
                      stackId="a"
                      label={(props) => 
                        <CustomHorizontalLabel {...props} 
                          formatter={(value) => context.dwellingSize === 'Bedroom2'?  getFormatedNumber(value): null}
                      />}
                      >
                      {chartData.map((entry, index) => (
                        <Cell fill="rgb(158, 90, 205)" key={`cell-${index}`} />
                      ))}
                    </Bar>
                  )}
                  {(context.dwellingSize === 'BedroomAll' ||
                    context.dwellingSize === 'Bedroom3Plus') && (
                    <Bar
                      dataKey={getChartDataKey(
                        context.dwellingSize,
                        dwellingSizeBedroom3Plus,
                        dataType
                      )}
                      name="No. of sales"
                      unit={dataType === 'percent' ? '%' : ''}
                      isAnimationActive={false}
                      stackId="a"
                      label={(props) => 
                        <CustomHorizontalLabel {...props} 
                          formatter={(value) => context.dwellingSize === 'Bedroom3Plus'?  getFormatedNumber(value): null}
                      />}
                    >
                      {chartData.map((entry, index) => (
                        <Cell fill="#7513B8" key={`cell-${index}`} />
                      ))}
                    </Bar>
                  )}
                  {context.dwellingSize === 'BedroomAll' && (
                    <Bar
                      dataKey={getChartDataKey(
                        context.dwellingSize,
                        dwellingSizeNotStated,
                        dataType
                      )}
                      name="No. of sales"
                      unit={dataType === 'percent' ? '%' : ''}
                      isAnimationActive={false}
                      stackId="a"
                      label={(props) => 
                        <CustomHorizontalLabel {...props} 
                          formatter={(value) => context.dwellingSize === 'BedroomAll'?  getFormatedNumber(value): null}
                      />}
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`}
                          fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}
                        />
                      ))}
                    </Bar>
                  )}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

HousingAvailabilityChart.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  type: PropTypes.oneOf(['mortgage', 'rental']).isRequired,
  dataNotes: PropTypes.object,
  availabilityData: PropTypes.object.isRequired,
  proptrackDisclaimer: PropTypes.bool,
}

export default HousingAvailabilityChart
