import React from 'react'
import {ReactComponent as House} from '../img/landcom-icons/landcom-icon-house.svg'
import {ReactComponent as Apartments} from '../img/landcom-icons/landcom-icon-apartments.svg'
import {ReactComponent as Townhouse} from '../img/landcom-icons/landcom-icon-townhouses.svg'
import {ReactComponent as Information} from '../img/landcom-icons/landcom-icon-info.svg'
import {ReactComponent as Hamburger} from '../img/id-icons/SVG/hamburger.svg'
import {ReactComponent as Close} from '../img/id-icons/SVG/cross.svg'

const baseClassName = 'landcom-icon';

export const LandcomIconHouse = (props) => (<House className={`${baseClassName} ${props.className}`} />);
export const LandcomIconApartments = (props) => (<Apartments className={`${baseClassName} ${props.className}`} />);
export const LandcomIconTownhouse = (props) => (<Townhouse className={`${baseClassName} ${props.className}`} />);
export const LandcomIconInformation = (props) => (<Information className={`${baseClassName} ${props.className}`} />);
export const HamburgerIcon = (props) => (<Hamburger className={`${baseClassName} ${props.className}`} />);
export const CloseIcon = (props) => (<Close className={`${baseClassName} ${props.className}`} />);