import React from 'react'
import ChartReferences from '../chartReferences'

const NumberPanel = (props) => {

    return (
        <div className="chart">
            <div className="chart__inner">
                <p className="chart__title">{props.title}</p>
                {props.subTitle && <p className="chart__sub-title">{props.subTitle}</p> }
                <div className="chart__viz">
                    {props.children}
                </div>
                <ChartReferences source={props.source} dataNotes={props.dataNotes}/>
            </div>
        </div>
    )
}

export default NumberPanel