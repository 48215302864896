import React from 'react'

const AffordableHousing = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="40 40 424 420"
  >
    <defs />
    <path d="M246.6 332.3v58.4h-88.4v-61.9c-2.6-.9-5.2-2-7.8-3.1v65H91.6v-91.9h18.3c-2.6-2.5-5.2-5.2-7.7-7.9H83.8v99.8H71.5V285.6h26.2c-2.2-2.6-4.2-5.2-6.2-8H56.6l24.3-17.5c-1.2-2.4-2.4-4.8-3.4-7.2l-45.1 32.5h31.2v113.1h190.8v-68.7z" />
    <path d="M467.6 418.7L352.4 303.6l-13.1 13c-6.9-6.8-13.6-13.6-20.3-20.3 27.8-31.7 41.3-68.6 38.5-110.9S337 108 304.8 80.5C246 30.2 158.1 33.2 102.4 87.7S42.2 231.2 91.9 290.9c25.7 30.9 58.8 48.6 98.7 53.5 45 4.5 84.3-8.4 118.1-37.9l20.4 20.4-13.2 13.2a5.9 5.9 0 01.8.8l114.4 114.4h.3l-.2-.2c12.2-12.2 24.4-24.3 36.4-36.4zm-260.5-87.2c-75.2-.3-136-61.7-135.8-136.5S132.4 58.4 208.2 58.7 345.1 120.6 344 197.1c-1 74.3-62 134.7-136.9 134.4zm224 104l-.4-.3-94.7-94.7c-.1-.1-.2-.2-.1-.2l16.7-16.8 95.4 95.3-16.8 16.8a.1.1 0 01-.1-.1z" />
    <path d="M80.3 248.9h81.2v81.3q6.5 1.8 12.3 3v-96.6H75.5q2.3 6.3 4.8 12.3z" />
    <path d="M64.2 179.3l110.3-79.6 158 116H68.9c1.1 4.2 2.3 8.4 3.7 12.6h240.2v62.1a130.4 130.4 0 0012.3-13.9V228h25.1a112.1 112.1 0 003.3-12.2l-50.5-37v-64.4h-66.7v15.4l-61.8-45.4L65.8 163a98.9 98.9 0 00-1.6 16.3zm184.4-52.6h42.1v43.1l-42.1-31z" />
    <path d="M261.7 323.8v-13.3h20.1v5.8a117.8 117.8 0 0012.3-6.3v-63.1H217v87.9a363.6 363.6 0 0044.7-11zm0-64.6h20.1v11.9h-20.1zm0 24.2h20.1v14.8h-20.1zm-32.4-24.2h20.1v11.9h-20.1zm0 24.2h20.1v14.8h-20.1zm0 27.1h20.1v14.8h-20.1zM225.9 337.5v8h-12.6v-7.1c-2.6.1-5.2.2-7.8.1v7h-12.7v-7.6c-2.6-.2-5.2-.5-7.7-.9v31.5h48.6v-31.8zm-20.4 23.2h-12.7v-7.5h12.7zm20.4 0h-12.6v-7.5h12.6z" />
  </svg>
)

export { AffordableHousing }
