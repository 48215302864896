import React from 'react'
import './index.scss'


const ProductLinksComponent = ({products, LGAName}) => {
    const productLinksContent = () => (
        <div className="product-links">
            <div className="container">
                <div className="product-links__inner">
                    <h2>More demographic resources</h2>
                    <ul className="list-unstyled">
                        {products.map((product, index) => {
                            if (product.SubdomainName !== "housing")
                                return (
                            <li key={index}>
                                <a className={`product-link product-link--${product.SubdomainName}`}
                                   href={product.Url}
                                   title={`${LGAName} ${product.FullName}`}
                                   >{LGAName} {product.FullName}</a>
                            </li>
                            )
                            else 
                                return null
                            }
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );


    return products.length > 0 ? productLinksContent() : null;

    };

export default ProductLinksComponent