import Moment from 'moment'
import {filter} from 'lodash'

const calculateBMMedianForPropertyTypes = (bmData, listingType) => {

    const filteredBMData = filter(bmData.edges, ({ node }) => {
        if (node.ListingType == listingType) {

            return { ...node }
        }
    })

    const propertyTypeData = ['House', 'Unit'].map(x => {

        return filter(filteredBMData, ({ node }) => node.PropertyType == x)

    })
    
    const flattenedData = propertyTypeData[0].map((x, index) => {

        const node = {
            Period_Name: Moment(propertyTypeData[0][index].node.Period, "YYYY-MM-DDThh:mm:ss.SSSZ").format("MMM YYYY"), Entry_House: propertyTypeData[0][index].node.Percentile25, Median_House: propertyTypeData[0][index].node.Median, Upper_House: propertyTypeData[0][index].node.Percentile75,
            Entry_Unit: propertyTypeData[1][index].node.Percentile25, Median_Unit: propertyTypeData[1][index].node.Median, Upper_Unit: propertyTypeData[1][index].node.Percentile75,
        }

        return node
    })

    return flattenedData
}


export default calculateBMMedianForPropertyTypes