import React, { useState, useEffect } from 'react'
import axios from 'axios'
import 'leaflet/dist/leaflet.css'
import './style.scss'
import { Map, TileLayer, LayersControl } from 'react-leaflet'
import { extent } from 'd3-array'
import MapLegend from './components/map-legend'
import scaleCluster from '../../functions/scaleCluster'
import { formatThematicData } from '../../functions/thematicMapHelpers'
import colorRange from './utils/color-range'
import ThematicTable from '../tables/thematic-table'
import { PropTypes } from 'prop-types'
import { Input } from 'reactstrap'
import { ErrorMessageChart } from '../error-message-chart'
import CustomFeatureGroup from './components/feature-group'

const initialBounds = [
  [-0, 0],
  [-40.787, 147.7],
]

const ThematicMapCustomAreas = ({
  propertyToMap,
  data,
  dataDisplayType,
  dataConfiguration,
  children,
  pageContext,
  totalRow = null,
  extraOverlayData,
}) => {
  const {
    geoDataType,
    dataSuppressRule,
    dataMappingField,
    tooltipTemplate,
    showThematicTable,
  } = dataConfiguration
  const [bounds, setBounds] = useState(initialBounds)
  const [lgaGeoData, setLgaGeoData] = useState({ features: [] })
  const [overlayData, setOverlayData] = useState({ features: [] })
  const [mapData, setMapData] = useState({ features: [] })
  const [mapDataDictByAreaId, setMapDataDictByAreaId] = useState({})
  const [selectedArea, setSelectedArea] = useState(null)
  const [scaleData, setScaleData] = useState([])
  const [tooltipContent, setTooltipContent] = useState({ ...tooltipTemplate })
  const [opacity, setOpacity] = useState(0.75)
  const [showOverlay, setOverlay] = useState(false)

  // Create array with all the data values to be displayed in the legend
  useEffect(() => {
    setScaleData(
      data.map(item => item[dataDisplayType ? propertyToMap.number : propertyToMap.percentage])
    )
  }, [data, dataDisplayType, propertyToMap])

  // Get geo json files (small areas and LGA)
  useEffect(() => {
    const fetchGeoData = async (url, handle) => {
      try {
        const resp = await axios.get(url)
        handle({ ...resp.data })
      } catch (err) {
        console.error(err)
      }
    }

    ;[
      { type: 'lga', handle: setLgaGeoData },
      { type: geoDataType, handle: setOverlayData },
    ].map(item => {
      const dataUrl = `/${item.type}/${pageContext.geocode}_${pageContext.LGASlug}_${item.type}.json`
      fetchGeoData(dataUrl, item.handle)
    })
  }, [])

  // Merge small area geo json with data array and create a dictionary for easy value retrieval
  // Also account for Extra Overlay data
  useEffect(() => {
    if (overlayData.features.length > 0) {
      const _mapData = { features: [] }
      const _mapDataDictByAreaId = {}

      overlayData.features.forEach(feature => {
        const matchedEdge =
          showOverlay === true
            ? extraOverlayData.filter(
                x =>
                  parseInt(x[dataMappingField.dataId]) ==
                  parseInt(feature.properties[dataMappingField.geoId])
              )
            : data.filter(
                x =>
                  parseInt(x[dataMappingField.dataId]) ==
                  parseInt(feature.properties[dataMappingField.geoId])
              )

        if (matchedEdge.length > 0) {
          const newEdge = {
            ...feature,
            properties: {
              ...matchedEdge[0],
              ...feature.properties,
            },
          }
          _mapData.features.push(newEdge)
          _mapDataDictByAreaId[feature.properties[dataMappingField.geoId]] = {
            ...newEdge,
            color: colorForValue(
              applySuppressionRule(
                newEdge,
                dataDisplayType ? propertyToMap.number : propertyToMap.percentage
              )
            ),
          }
        }
      })

      setMapData(_mapData)
      setMapDataDictByAreaId(_mapDataDictByAreaId)
    }
  }, [
    overlayData,
    data,
    dataMappingField,
    dataDisplayType,
    propertyToMap,
    scaleData,
    showOverlay,
    extraOverlayData,
  ])

  useEffect(() => {
    if (selectedArea != null) {
      setTooltipContent(tooltipContentSetup(selectedArea))
    }
  }, [mapData, selectedArea, propertyToMap])

  const calculateScale = () => {
    return scaleCluster()
      .domain(scaleData)
      .range(colorRange)
  }
  const colorForValue = value => {
    if (value === null) return 'white'
    else return calculateScale()(value)
  }

  const styleFeature = feature => {
    if (
      selectedArea != null &&
      selectedArea.properties[dataMappingField.geoId] == feature.properties[dataMappingField.geoId]
    )
      return {
        color: 'white',
        fillColor: mapDataDictByAreaId[feature.properties[dataMappingField.geoId]].color,
        weight: 7,
        zIndex: 7,
        fillOpacity: opacity,
      }

    return {
      color: 'white',
      weight: 2,
      fillColor: mapDataDictByAreaId[feature.properties[dataMappingField.geoId]].color,
      fillOpacity: opacity,
    }
  }

  const handleOnLayerAdd = event => {
    setBounds(event.sourceTarget.getBounds())
  }

  const getDataForFeature = (feature, property) => {
    if (typeof property === undefined || property === null) return null

    const matchedFeature = mapDataDictByAreaId[feature.properties[dataMappingField.geoId]]

    return applySuppressionRule(matchedFeature, property)
  }

  const applySuppressionRule = (matchedFeature, property) => {
    if (typeof matchedFeature === undefined || matchedFeature === null) return null

    const { checkingColumName, threshold, isApplicable } = dataSuppressRule

    const isRuleAppliedProperty =
      isApplicable &&
      (property == propertyToMap.number || property == propertyToMap.percentage) &&
      matchedFeature.properties[checkingColumName] < threshold &&
      matchedFeature.properties[propertyToMap.number] == 0

    return isRuleAppliedProperty ? null : matchedFeature.properties[property]
  }

  const tooltipContentSetup = feature => {
    const propertyToMapNumber = formatThematicData(
      getDataForFeature(feature, propertyToMap.number),
      'number'
    )

    const propertyToMapPercentage = formatThematicData(
      getDataForFeature(feature, propertyToMap.percentage),
      'percentage'
    )

    const mainToolTips = tooltipTemplate.tooltipLiterals(
      feature,
      propertyToMapNumber,
      propertyToMapPercentage
    )

    const extraToolTips = tooltipTemplate.extra.map(x => ({
      isBold: false,
      content: `${x.label}: ${formatThematicData(getDataForFeature(feature, x.value), x.type)}`,
    }))

    return [...mainToolTips, ...extraToolTips]
  }

  const handleEventsOnEachFeature = (feature, layer) => {
    layer.on({
      mouseover: e => {
        var layer = e.target
        setSelectedArea(layer.feature)
      },
      mouseout: e => {
        setSelectedArea(null)
      },
    })
  }

  try {
    if (!(lgaGeoData.features.length > 0) || !(overlayData.features.length > 0)) {
      return <div className="alert alert-info">Loading...</div>
    }

    if (typeof window !== 'undefined') {
      return (
        <div>
          {mapData.features.length > 0 && (
            <div className="chart__map" aria-hidden="true">
              <Map bounds={bounds} style={{ height: '100%', zIndex: 2 }}>
                <LayersControl collapsed={false}>
                  <LayersControl.BaseLayer checked name="Map">
                    <TileLayer
                      attribution="&amp;copy Mapbox"
                      url={`https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaG91c2luZy1pZCIsImEiOiJjanN5M3NwOTgwbGZtNDNxeGZ4MnZqNXB4In0.y-9Mn_kBRja_9txXICWiTQ`}
                      tileSize={512}
                      zoomOffset={-1}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <TileLayer
                      attribution="&amp;copy Mapbox"
                      url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaG91c2luZy1pZCIsImEiOiJjanN5M3NwOTgwbGZtNDNxeGZ4MnZqNXB4In0.y-9Mn_kBRja_9txXICWiTQ`}
                      tileSize={512}
                      zoomOffset={-1}
                    />
                  </LayersControl.BaseLayer>
                </LayersControl>
                <LayersControl>
                  <LayersControl.BaseLayer checked name={mapData.features[0].properties.Area_Group}>
                    <CustomFeatureGroup
                      standardMapData={mapData.features.map(f => f.properties.Area_Id).toString()}
                      handleOnAdd={handleOnLayerAdd}
                      mapData={mapData}
                      styleFeature={styleFeature}
                      handleEventsOnEachFeature={handleEventsOnEachFeature}
                      tooltipContent={tooltipContent}
                      lgaGeoData={lgaGeoData}
                      selectedArea={selectedArea}
                    />
                  </LayersControl.BaseLayer>
                  {extraOverlayData !== undefined && extraOverlayData.length > 0 ? (
                    <LayersControl.BaseLayer
                      name={
                        overlayData.features.filter(
                          f => extraOverlayData[0].Area_Id == f.properties.Area_Id
                        )[0].properties?.Area_Group
                      }
                    >
                      <CustomFeatureGroup
                        standardMapData={mapData.features.map(f => f.properties.Area_Id).toString()}
                        handleOnAdd={() => setOverlay(true)}
                        mapData={mapData}
                        styleFeature={styleFeature}
                        handleEventsOnEachFeature={handleEventsOnEachFeature}
                        tooltipContent={tooltipContent}
                        lgaGeoData={lgaGeoData}
                        handleOnRemove={() => setOverlay(false)}
                        selectedArea={selectedArea}
                      />
                    </LayersControl.BaseLayer>
                  ) : (
                    ''
                  )}
                </LayersControl>
              </Map>
              <div className="opacity-slider">
                <Input
                  type="range"
                  name="opacity"
                  onChange={event => setOpacity(event.target.value / 100)}
                  defaultValue={opacity * 100}
                />
              </div>
              <MapLegend
                domain={calculateScale().clusters()}
                range={calculateScale().range()}
                extent={extent(scaleData)}
                isPercentage={!dataDisplayType}
              />
              {children}
            </div>
          )}

          {mapData.features.length > 0 && scaleData.length > 0 && showThematicTable && (
            <ThematicTable
              dataConfiguration={dataConfiguration}
              scale={calculateScale()}
              data={mapData.features.map(x => x.properties)}
              dicData={mapDataDictByAreaId}
              propertyToMap={propertyToMap}
              selectedArea={selectedArea}
              dataDisplayType={dataDisplayType}
              selectedAreaHandle={setSelectedArea}
              totalRow={totalRow}
            />
          )}
        </div>
      )
    } else {
      return null
    }
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

ThematicMapCustomAreas.propTypes = {
  data: PropTypes.array.isRequired,
  propertyToMap: PropTypes.object.isRequired,
  dataDisplayType: PropTypes.bool.isRequired,
  dataConfiguration: PropTypes.object.isRequired,
}

export default ThematicMapCustomAreas
