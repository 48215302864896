import numeral from 'numeral'

/*
These functions specific return 'N/A' to fit thematic map/table set up
*/

const formatThematicData = (input, inputType) => {

    if (input === null || input === '') return 'N/A'

    switch (inputType) {
        case 'number': return `${numeral(input).format('0,0')}`
        case 'percentage': return `${numeral(input).format('0.0')}%`
        case 'currency': return `${numeral(input).format('$0,0')}`
    }

    return input
}


export { formatThematicData }