import React from 'react'
import { map, flow, capitalize, maxBy, sortBy, max, groupBy, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import CustomLegend from '../../components/legend/index.js'
import numeral from 'numeral'
import { scaleLinear } from 'd3-scale'
import {
  LandcomIconHouse,
  LandcomIconTownhouse,
  LandcomIconApartments,
} from '../landcom-icons'
import { ButtonGroup, Button } from 'reactstrap'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

class HousingTypesByBedroomChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    const { data, areaName, dataNotes } = this.props
    if (isEmpty(data)) {
      return (
          <ErrorMessageChart error="No data for chart"/>
      )
    }

    try {
      const benchmarkName = data.edges[0].node.BenchmarkName

      const areaColor = typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')
      const benchmarkColor = typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')

      // Calculate the max value in the data to use in the range
      const maxItem = maxBy(data.edges,
          this.state.dataType === 'percent'
            ? ({ node }) => max([node.bPer2021, node.Per2021])
            : ({ node }) => node.Num2021
        )

      const maxValue =
        this.state.dataType === 'percent'
          ? max([maxItem.node.bPer2021, maxItem.node.Per2021])
          : maxItem.node.Num2021

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(4)
        .ticks(4)

      const legendItems = () => {
        const legendItems = [{ label: areaName, color: areaColor }]

        if (this.state.dataType === 'percent') {
          legendItems.push({ label: benchmarkName, color: benchmarkColor })
        }

        return legendItems
      }

      const chartIcon = dwellingType => {
        switch (dwellingType) {
          case 'Separate house':
            return (
              <LandcomIconHouse className="landcom-icon-lg landcom-icon-purple" />
            )
          case 'Medium density':
            return (
              <LandcomIconTownhouse className="landcom-icon-lg landcom-icon-purple" />
            )
          case 'High density':
            return (
              <LandcomIconApartments className="landcom-icon-lg landcom-icon-purple" />
            )
          default:
            return false
        }
      }

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label} bedrooms</strong>
              {payload.map((item, key) => (
                <p
                  key={key}
                  className="recharts-tooltip-label m-0"
                  style={{ color: item.color }}
                >
                  {item.name}:{' '}
                  {this.state.dataType === 'percent'
                    ? formatPercentage(item.value)
                    : numeral(item.value).format('0,0')}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      // Prepare the data
      const chartData = flow([
        x => map(x, d => d.node),
        x => groupBy(x, d => d.DwellingStructure),
        x => map(x, (value, key) => {
          value = sortBy(value, ['BedroomNumber'])
          return { type: key, data: value }
        })
      ])(data.edges)

      const charts = map(chartData, (item, index) => {
        return (
          <div className="col-md-4" key={index}>
            <div style={{ width: '100%', height: 200 }}>
              <ResponsiveContainer>
                <BarChart className="overflow-visible" data={item.data}>
                  <XAxis dataKey="fields.BedroomNumber">
                    <Label
                      value="Bedrooms"
                      offset={-5}
                      position="insideBottom"
                    />
                  </XAxis>
                  <YAxis
                    tickLine={false}
                    ticks={ticks}
                    interval={0}
                    tickFormatter={
                      this.state.dataType === 'percent'
                        ? value => `${numeral(value).format('0[.]0')}%`
                        : value => numeral(value).format('0,0')
                    }
                    tickCount={ticks.count + 1}
                    width={30}
                  />
                  <CartesianGrid vertical={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey={
                      this.state.dataType === 'percent' ? 'Per2021' : 'Num2021'
                    }
                    fill={areaColor}
                    name={areaName}
                  />
                  {this.state.dataType === 'percent' && (
                    <Bar
                      dataKey="bPer2021"
                      fill={benchmarkColor}
                      name={benchmarkName}
                    />
                  )}
                </BarChart>
              </ResponsiveContainer>
            </div>
            <p className="text-center m-0 mt-2">
              {chartIcon(capitalize(item.type))}
              <br /> {capitalize(item.type)}
            </p>
          </div>
        )
      })

      return (
        <ChartWrapper
          name={`housing-mix-bedrooms`}
          title={`What is the mix of housing?`}
          subTitle={`Occupied dwellings by dwelling type and no. of bedrooms, 2021`}
          dataSource={Datasource.ABS2021}
          dataNotes={dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
              <div className="chart-group">
                <CustomLegend items={legendItems()} />
                <div className="row">{charts}</div>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return (
        <ErrorMessageChart error={error}/>
      )
    }
  }
}

export default HousingTypesByBedroomChart
