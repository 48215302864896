import React from 'react'

const IncomeChangeNote = ({ LGAName, benchmarkName }) => {
  return (
    <div>
      <p>
        This chart compares the past 3 Censuses of household income in {LGAName} with a comparison
        to {benchmarkName}. The median income (midpoint of all household incomes) is used, and this
        dataset is not inflation adjusted. This chart should be used to show how incomes have
        tracked over time and whether income growth is outpacing or falling behind the benchmark. It
        should be viewed in conjunction with house price and rental increases below to see whether
        incomes are increasing in line with housing costs.
      </p>
    </div>
  )
}

export default IncomeChangeNote
