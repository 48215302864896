import React from 'react'
import HousingAffordabilityLgaChart from './affordability-lga-chart'
import * as Datasource from '../../config/text-constants'

const AffordabilityRentalLgaChart = ({
  alias,
  pricePoints,
  incomeRangesByYear,
  dataNotes,
}) => (
  <HousingAffordabilityLgaChart
    alias={alias}
    title="How affordable is renting?"
    dataSource={Datasource.Proptrack}
    dataNotes={dataNotes}
    name="affordable-rental-lga"
    pricePoints={pricePoints}
    incomeRangesByYear={incomeRangesByYear}
    type="rental"
    proptrackDisclaimer={true}
  />
)

export default AffordabilityRentalLgaChart
