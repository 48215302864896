import React, { useState } from 'react'
import { map, flow, maxBy, minBy, groupBy, sortBy, isEmpty } from 'lodash'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts'
import CustomLegend from '../legend/index.js'
import { scaleLinear } from 'd3-scale'
import {
    LandcomIconHouse,
    LandcomIconTownhouse,
    LandcomIconApartments,
} from '../landcom-icons'
import { ButtonGroup, Button } from 'reactstrap'
import ChartWrapper from '../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { PropTypes } from 'prop-types'
import { ErrorMessageChart } from '../error-message-chart'
import { formatNumber } from '../../functions/formatNumber.js'


const HousingConsumptionPatternsChart = ({ data, areaName, dataNotes, houseHoldType }) => {

    if (isEmpty(data)) {
        return (
            <ErrorMessageChart error="No data for chart"/>
        )
    }

    const [dataType, setDataType] = useState('number')

    const dataKey = `${houseHoldType.value}_Percentage`


    const calculateTicks = () => {

        const selectedDataKey = dataType === 'percent' ? dataKey : houseHoldType.value

        const maxValueItem = maxBy(data, selectedDataKey)

        const minValueItem = minBy(data, selectedDataKey)

        const ticks = scaleLinear()
            .domain([minValueItem[selectedDataKey], maxValueItem[selectedDataKey]])
            .nice(4)
            .ticks(4)

        return ticks
    }

    const legendItems = () => {

        const legendItems = [{ label: areaName, color: areaColor }]

        return legendItems
    }

    const areaColor = typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')
    const CustomTooltip = props => {
        const { active } = props

        if (active) {
            const { payload, label } = props

            return (
                <TooltipWrapper>
                    <strong>{label} bedrooms</strong>
                    {payload.map((item, key) => (
                        <p
                            key={key}
                            className="recharts-tooltip-label m-0"
                            style={{ color: item.color }}
                        >
                            {item.name}:{' '}
                            {dataType === 'percent'
                                ? formatPercentage(item.value)
                                : formatNumber(item.value)}
                        </p>
                    ))}
                </TooltipWrapper>
            )
        }
        return null
    }

    const chartIcon = dwellingType => {
        switch (dwellingType) {
            case 'Separate house':
                return (
                    <LandcomIconHouse className="landcom-icon-lg landcom-icon-purple" />
                )
            case 'Medium density':
                return (
                    <LandcomIconTownhouse className="landcom-icon-lg landcom-icon-purple" />
                )
            case 'High density':
                return (
                    <LandcomIconApartments className="landcom-icon-lg landcom-icon-purple" />
                )
            default:
                return false
        }
    }

    const chartData = flow([
        x => groupBy(x, d => d.Dwelling_Type),
        x => map(x, (value, key) => {
            value = sortBy(value, ['Dwelling_Composition'])
            return { type: key, data: value }
            })
    ])(data)

    const charts = chartData.map((item, index) => {
        return (
            <div className="col-md-4" key={index}>
                <div style={{ width: '100%', height: 200 }}>
                    <ResponsiveContainer>
                        <BarChart className="overflow-visible" data={item.data}>
                            <XAxis dataKey="Dwelling_Composition">
                                <Label
                                    value="Bedrooms"
                                    offset={-5}
                                    position="insideBottom"
                                />
                            </XAxis>
                            <YAxis
                                tickLine={false}
                                ticks={calculateTicks()}
                                interval={0}
                                tickFormatter={
                                    dataType === 'percent'
                                        ? value => formatPercentage(value)
                                        : value => formatNumber(value)
                                }
                                tickCount={calculateTicks().count + 1}
                                width={30}
                            />
                            <CartesianGrid vertical={false} />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar
                                dataKey={
                                    dataType === 'percent'
                                        ? dataKey
                                        : houseHoldType.value
                                }
                                fill={areaColor}
                                name={areaName}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <p className="text-center m-0 mt-2" style={{ paddingLeft: '32px' }}>
                    {chartIcon(item.type)}
                    <br /> {item.type}
                </p>
            </div>
        )
    })

    try {
        return (
            <ChartWrapper
                name={`housing-consumption-patterns`}
                title={`How have housing consumption patterns changed for ${houseHoldType.name.toLowerCase()} households?`}
                subTitle={`Change in occupied dwellings by dwelling type and no. of bedrooms, 2016-2021`}
                dataSource={Datasource.ABS2016}
                dataNotes={dataNotes}
                body={
                    <div>
                        <ButtonGroup size="sm" className="d-print-none mb-1">
                            <Button
                                outline
                                onClick={() => setDataType('number')}
                                active={dataType === 'number'}
                            >
                                Number
                            </Button>
                            <Button
                                outline
                                onClick={() => setDataType('percent')}
                                active={dataType === 'percent'}
                            >
                                Percent
                            </Button>
                        </ButtonGroup>
                        <div className="chart-group">
                            <CustomLegend items={legendItems()} />
                            <div className="row">{charts}</div>
                        </div>
                    </div>
                }
            />
        )
    } catch (error) {
        return (
            <ErrorMessageChart error={error}/>
        )
    }
}

HousingConsumptionPatternsChart.propTypes = {
    data: PropTypes.array.isRequired,
    areaName: PropTypes.string.isRequired,
    dataNotes: PropTypes.object.isRequired,
    houseHoldType: PropTypes.object.isRequired
}


export default HousingConsumptionPatternsChart