import React, { useState } from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { formatNumber } from '../../../functions/formatNumber'
import { isEmpty, filter } from 'lodash'
import ChartWrapper from '../../chartWrapper/index'
import TooltipWrapper from '../../tooltip-wrapper'
import { ErrorMessageChart } from '../../error-message-chart'
import { ButtonGroup, Button, Table, Card, CardText } from 'reactstrap'
import formatPercentage from '../../../functions/formatPercentage'
import {
  sortByAscendingDatetime,
  sortByDescendingDatetime,
} from '../../../functions/datetimeHelpers'
import _ from 'lodash'
import * as func from './functions'
import { DateTime } from 'luxon'
import * as Constants from '../../constants'

const GenericPriceLineChart = props => {
  const [dataType, changeDataType] = useState(Constants.Number)
  const [lineType, changeLineType] = useState(Constants.Median)

  let chartDataWithPercentage = func.addPercentageData(props.chartData)

  // Allow a fair number of missing data points
  let anyNullDataPoints = filter(chartDataWithPercentage, item => item.median === 0).length > 10

  // Find the minimum year periods in the chartDataWithPercentage array
  const sortedData = sortByAscendingDatetime(chartDataWithPercentage, 'Period_Name', 'MMM YYYY')
  const minPeriods = sortedData.slice(0, 4).map(p => p.Period_Name)

  // Filter out data for the minimum year because we can't calculate change compared to previous year
  let filteredChartPercentageData = chartDataWithPercentage.filter(
    item => !minPeriods.includes(item.Period_Name)
  )

  // Removing 0 values from chart data
  let filteredNumberData = func.updatedChartData(props.chartData)

  const CustomTooltip = props => {
    const { active } = props

    if (active) {
      const { payload, label } = props

      return (
        <TooltipWrapper>
          <strong>{label}</strong>
          {payload.map((item, key) => (
            <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
              {item.name}:{' '}
              {dataType === Constants.Number
                ? formatNumber(item.value, '$0,0')
                : item.payload[item.dataKey] === '0%'
                ? 'N/A'
                : formatPercentage(item.payload[item.dataKey])}
            </p>
          ))}
        </TooltipWrapper>
      )
    }
    return null
  }

  const renderChart = () => {
    sortByAscendingDatetime(filteredNumberData, 'Period_Name', 'MMM YYYY')

    // Filter out values with all zeros in their attributes
    filteredChartPercentageData = func.spliceIfAllZero(chartDataWithPercentage)

    // Blacktown and Greater Sydney & Maribyrnong specific config
    if (props.areaName.includes('Blacktown') || props.areaName.includes('Maribyrnong')) {
      filteredChartPercentageData = func.removePropertiesWithValue(filteredChartPercentageData, [
        -100,
      ])
      filteredNumberData = func.removePropertiesWithValue(filteredNumberData, [0])
    }

    /*
  Apologies for the repeated code with the lineType below
  It doesn't play well with the 'One parent for multiple JSX elements' rule, so I had to repeat the code below
  For context, this was done to eliminate the legends for the entry_level and median values as Recharts does not 
  provide for any built-in technique to remove the legends. So this was the hack.
   */
    return (
      <div className="chart__viz" style={{ width: '100%', height: 320 }}>
        {anyNullDataPoints && (
          <div className="chart__overlay-message">
            <p>There were insufficient data in this LGA to provide data for this chart</p>
          </div>
        )}
        <ResponsiveContainer>
          <LineChart
            data={dataType === Constants.Percent ? filteredChartPercentageData : filteredNumberData}
            margin={{ right: 30 }}
          >
            <XAxis dataKey={props.dataKeyPeriod} tickLine={false}></XAxis>
            <YAxis
              tickLine={false}
              interval={0}
              tickFormatter={value =>
                dataType === Constants.Number
                  ? formatNumber(value, '$0,0')
                  : formatPercentage(value)
              }
            />
            <CartesianGrid vertical={false} />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="top"
              align="left"
              height={50}
              formatter={value => <span style={{ color: 'black' }}>{value}</span>}
            />
            {lineType === Constants.EntryLevel ? (
              <>
                <Line
                  dataKey={
                    dataType === Constants.Number
                      ? props.dataKeyEntry
                      : `${props.dataKeyEntry}_percent`
                  }
                  name={`${props.areaName} entry level`}
                  stroke={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-primary')
                  }
                  strokeWidth={2}
                />
                <Line
                  dataKey={
                    dataType === Constants.Number
                      ? props.dataKeyEntryBM
                      : `${props.dataKeyEntryBM}_percent`
                  }
                  name={`${props.benchmarkName} entry level`}
                  stroke={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                  }
                  strokeWidth={2}
                />
              </>
            ) : (
              <>
                <Line
                  dataKey={
                    dataType === Constants.Number
                      ? props.dataKeyMedian
                      : `${props.dataKeyMedian}_percent`
                  }
                  name={`${props.areaName} median`}
                  stroke={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-primary')
                  }
                  strokeWidth={2}
                />
                <Line
                  dataKey={
                    dataType === Constants.Number
                      ? props.dataKeyMedianBM
                      : `${props.dataKeyMedianBM}_percent`
                  }
                  name={`${props.benchmarkName} median`}
                  stroke={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                  }
                  strokeWidth={2}
                />
              </>
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const renderAutoTextParagraph = () => {
    const endYearData = chartDataWithPercentage[chartDataWithPercentage.length - 1]

    let lastYearAndMonth = func.extractYearAndMonth(endYearData.Period_Name)

    const initialDate = DateTime.fromFormat(
      `${lastYearAndMonth.month} ${lastYearAndMonth.year}`,
      'LLL yyyy'
    )

    const fullMonth = initialDate.toFormat('MMMM')

    let changePercentage = formatPercentage(
      func.calculate5YearAnnualAverageChange(lineType, props.chartData).change
    )
    let regionalChangePercentage = formatPercentage(
      func.calculate5YearAnnualAverageChange(lineType, props.chartData).regional_change
    )

    let changePercentageFloat = func.percentageStringToFloat(changePercentage)
    let regionalChangePercentageFloat = func.percentageStringToFloat(regionalChangePercentage)

    // Determine whether the values are increased or decreased
    const changeDescription = changePercentageFloat >= 0 ? 'increased' : 'decreased'
    const regionalChangeDescription =
      regionalChangePercentageFloat >= 0 ? 'an increase' : 'a decrease'

    return (
      <>
        <div style={{ paddingBottom: '0' }}>
          <Card
            body
            style={{
              marginBottom: '2rem',
              borderRadius: '10px',
            }}
          >
            <CardText>
              In the 5 years ending
              {` ${fullMonth} ${lastYearAndMonth.year}`}, {lineType.replace(/_/g, ' ')}{' '}
              {props.chartTypeText} have {changeDescription} by an average of {changePercentage} p.a
              in {props.areaName} compared to {regionalChangeDescription} of{' '}
              {regionalChangePercentage} p.a in {props.benchmarkName}.{' '}
            </CardText>
          </Card>
        </div>
      </>
    )
  }

  const renderTable = () => {
    // Reversing the data for the table
    sortByDescendingDatetime(chartDataWithPercentage, 'Period_Name', 'MMM YYYY')

    const tableHeaders = [
      'Period Quarter',
      `${props.areaName} entry level`,
      `${props.areaName} median`,
      `${props.benchmarkName} entry level`,
      `${props.benchmarkName} median`,
    ]

    const columnHeaders = [
      '',
      '$',
      '12 month change %',
      '$',
      '12 month change %',
      '$',
      '12 month change %',
      '$',
      '12 month change %',
    ]

    return (
      <Table bordered responsive>
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index} colSpan={index === 0 ? 1 : 2}>
                {header}
              </th>
            ))}
          </tr>
          <tr>
            {columnHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {chartDataWithPercentage.map(data => (
            <tr key={data[props.dataKeyPeriod]}>
              <td>{data[props.dataKeyPeriod]}</td>
              {[
                Constants.EntryLevel,
                Constants.Median,
                'regional_entry_level',
                'regional_median',
              ].map(key => (
                <React.Fragment key={key}>
                  <td>
                    {data[key] == -1 || data[key] === 0 ? 'N/A' : formatNumber(data[key], '$0,0')}
                  </td>
                  <td>
                    {data[`${key}_percent`] == -100
                      ? 'N/A'
                      : formatPercentage(data[`${key}_percent`])}
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  try {
    if (isEmpty(props.chartData)) {
      return <ErrorMessageChart error="No data for chart" />
    }

    return (
      <>
        <ChartWrapper
          name={props.name}
          title={props.title}
          subTitle={props.subTitle}
          dataSource={props.dataSource}
          dataNotes={props.dataNotes}
          proptrackDisclaimer={true}
          body={
            <div>
              <div className="row">
                <div className="col-12">
                  <ButtonGroup size="sm" className="d-print-none mb-2 me-2">
                    <Button
                      outline
                      onClick={() => {
                        changeDataType(Constants.Number)
                      }}
                      active={dataType === Constants.Number}
                      disabled={anyNullDataPoints}
                    >
                      Number
                    </Button>
                    <Button
                      outline
                      onClick={() => {
                        changeDataType(Constants.Percent)
                      }}
                      active={dataType === Constants.Percent}
                      disabled={anyNullDataPoints}
                    >
                      Percent
                    </Button>
                    <Button
                      outline
                      onClick={() => {
                        changeDataType(Constants.Table)
                      }}
                      active={dataType === Constants.Table}
                      disabled={anyNullDataPoints}
                    >
                      Table
                    </Button>
                  </ButtonGroup>

                  <ButtonGroup size="sm" className="d-print-none mb-2 ms-4">
                    <Button
                      outline
                      onClick={() => {
                        changeLineType(Constants.EntryLevel)
                      }}
                      active={lineType === Constants.EntryLevel}
                      disabled={dataType === Constants.Table || anyNullDataPoints}
                    >
                      Entry Level
                    </Button>
                    <Button
                      outline
                      onClick={() => {
                        changeLineType(Constants.Median)
                      }}
                      active={lineType === Constants.Median}
                      disabled={dataType === Constants.Table || anyNullDataPoints}
                    >
                      Median
                    </Button>
                  </ButtonGroup>
                  {dataType === Constants.Table ? renderTable() : renderChart()}
                </div>
              </div>
            </div>
          }
        />
        {dataType === Constants.Table ? '' : renderAutoTextParagraph()}
      </>
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default GenericPriceLineChart
