import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import ChartReferences from './../chartReferences'
import { trackEvent } from '../../analytics'
import ExportDropdownMenu from './export-dropdown-menu'
import UnsupportedBrowserModal from './unsupportedBrowserModal'
import Bowser from 'bowser'
import { Modal, ModalHeader, ModalBody} from 'reactstrap'
import {LandcomIconInformation} from './../landcom-icons'
import { toPng, toBlob } from 'html-to-image';

const ChartWrapper = ({
  body,
  dataNotes,
  dataSource,
  name,
  subTitle,
  title,
  topControls,
  chartNotes, 
  proptrackDisclaimer,
  chartInfo = null
}) => {
  const [isTimerActive, setIsTimerActive] = useState(false)
  const currentUrl = typeof window !== 'undefined' ? window.location.href : ''

  const [modal, setModal] = useState(false)
  const toggleModal = () => setModal(!modal)
  
  const [chartInfoOpen, setChartInfoOpen] = useState(false)
  const toggleChartInfoOpen = () => setChartInfoOpen(!chartInfoOpen)
  const chart = useRef(null)

  useEffect(() => {
    let interval = null
    if (isTimerActive) {
      interval = setInterval(() => {
        trackEvent({
          category: name,
          action: `Hovered 3 secs`,
        })

        clearInterval(interval)
      }, 3000)
    }

    if (!isTimerActive) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [isTimerActive, name])

  const handleExportToImageDownload = useCallback(() =>  {
    const supported = isExportSupported()

    trackEvent({
      category: 'Export chart',
      action: `Download as image`,
      label: supported ? `Browser supported` : `Browser not supported`,
    })
    
    chart.current.querySelectorAll(".d-print-none").forEach(el => el.style.display='none');
    toPng(chart.current, { cacheBust: true })
    .then((dataUrl) => {
      const link = document.createElement('a')
      link.download = name
      link.href = dataUrl
      link.click()
      chart.current.querySelectorAll(".d-print-none").forEach(el => el.style.display='inline-block');
    })
    .catch((err) => {
      console.log(err)
      chart.current.querySelectorAll(".d-print-none").forEach(el => el.style.display='inline-block');
    })
  }, [chart])

  const handleExportToClipboard = () => {
    const supported = isExportSupported()

    trackEvent({
      category: 'Export chart',
      action: `Export to clipboard`,
      label: supported ? `Browser supported` : `Browser not supported`,
    })

    chart.current.querySelectorAll(".d-print-none").forEach(el => el.style.display='none');
    toBlob(chart.current, { cacheBust: true, })
    .then((blob) => {
      const clipboardItemInput = new ClipboardItem({[blob.type]: blob  });
      navigator.clipboard.write([clipboardItemInput]);
      chart.current.querySelectorAll(".d-print-none").forEach(el => el.style.display='inline-block');
    })
    .catch((err) => {
      console.log(err)
      chart.current.querySelectorAll(".d-print-none").forEach(el => el.style.display='inline-block');
    })
  }

  const handleClick = () => {
    trackEvent({
      category: name,
      action: `Clicked in chart`,
    })
  }
  
  return (
    <div
      className={`chart`}
      data-name={name}
      onClick={handleClick}
      onMouseOver={() => setIsTimerActive(true)}
      onMouseOut={() => setIsTimerActive(false)}
    >
      <div className="chart__inner"
      ref={chart}>
        <div className="d-flex">
          <div className="chart__header">
            <p className="chart__title">
              {title}
              {chartInfo !== null ?
                 <>
                   <button
                    className="chart-references chart-references__info d-print-none no-style-button"
                    onClick={toggleChartInfoOpen}
                  >
                    <LandcomIconInformation aria-label="Open chart information"/>{' '}
                    <span className="chart-references__data-note-text">Chart info</span>
                  </button>
                <Modal isOpen={chartInfoOpen} toggle={toggleChartInfoOpen} className="modal-lg">
                    <ModalHeader toggle={toggleChartInfoOpen}>Chart information</ModalHeader>
                    <ModalBody><div dangerouslySetInnerHTML={{ __html: chartInfo.html }} /></ModalBody>
                </Modal>
                </>
                : null}
            </p>
            {subTitle && <p className="chart__sub-title">{subTitle}</p>}
          </div>
          <div className="chart__utility-menu d-print-none">
            <ExportDropdownMenu
            // Using useCallback hook (in combination with the memo hook on ExportDropdownMenu)
            // so that export dropdown doesn't re-render for every render of ChartWrapper 
              downloadCallback={handleExportToImageDownload}
              clipboardCallback={handleExportToClipboard}
              chartTitle={title}
              currentUrl={currentUrl}
            />
          </div>
        </div>
        {topControls}
        <ErrorBoundary>{body}</ErrorBoundary>
        <ChartReferences source={dataSource} dataNotes={dataNotes} chartNotes={chartNotes} proptrackDisclaimer={proptrackDisclaimer} />
        {modal ?? <UnsupportedBrowserModal modal={modal} toggle={toggleModal} />}
      </div>
    </div>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="alert alert-danger" role="alert">
          <strong>Data error</strong>. The chart can't shown because something
          went wrong getting the data
        </div>
      )
    }
    return this.props.children
  }
}

export default ChartWrapper

ChartWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.element,
  dataSource: PropTypes.string,
  dataNotes: PropTypes.object,
  proptrackDisclaimer: PropTypes.bool
}

const isExportSupported = () => {
  if (typeof window === 'undefined') {
    return false
  }
  const browser = Bowser.getParser(window.navigator.userAgent)
  try {
    return browser.satisfies({
      chrome: '>48',
      firefox: '>44',
    })
  } catch (error) {
    console.error(error)
  }
}
