import React from 'react'
import './index.scss'
import isElementInViewportHorizontally from '../../functions/isElementInViewportHorizontally'

const ProductTabs = ({ products }) => {
  products.sort(function(a, b){return a.ApplicationId - b.ApplicationId})
  // A callback that's fired each time the ref changes (from null to a DOM node)
  const activeRef = React.useCallback(node => {
    if (node !== null) {
      // test if the tab is in view (horizontally)
      // if not, scroll the tab into view
      if (!isElementInViewportHorizontally(node)) {
        node.scrollIntoView(false)
      }
    }
  }, [])
  
  return (
    <div>
      <ul className='container productTabContainer'>
        {products.map((product, index) =>{
          const cssname = product.IsDisabled ? 'disabledProductTab' : product.SubdomainName
          return (          
          <li key={'product-' + index}>
            <a
            key={index}
            className={`productTab ${cssname}`}
            href={product.Url}  
            ref={product.SubdomainName === 'housing'? activeRef: null}
            target={product.SubdomainName === 'housing'? "_self":"_blank"}
            >
              {product.FullName}
            </a>
          </li>
          )}
        )}
      </ul>
    </div>
  )
}

export default ProductTabs
