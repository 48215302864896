import React, { useState } from 'react'
import { flatMapDeep, flow, find, max, map, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import numeral from 'numeral'
import { scaleLinear } from 'd3-scale'
import {
  LandcomIconHouse,
  LandcomIconTownhouse,
  LandcomIconApartments,
} from '../landcom-icons'
import ChartWrapper from './../chartWrapper/index'
import { Button, ButtonGroup } from 'reactstrap'
import CustomLegend from '../../components/legend/index.js'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

const HousingTypesChart = ({
  data,
  dataNotes,
  benchmarkData,
  areaName,
  benchmarkName,
  chartInfo
}) => {
  const [dataType, setDataType] = useState('percent')
  const changeToPercent = () => setDataType('percent')
  const changeToNumber = () => setDataType('number')

  try {
    if (isEmpty(data)) {
      return (
          <ErrorMessageChart error="No data for chart"/>
      )
    }
    const chartData = data.edges.map(({ node }, index) => {
      const dataNode = node
      const benchmarkNode = find(
        benchmarkData.edges,
        ({ node }) => node.DwellingStructure === dataNode.DwellingStructure
      ).node

      const data = [2006, 2011, 2016, 2021].map(year => ({
        year: year,
        value: node[`n${year}`],
        per: node[`per${year}`],
        value_bm: benchmarkNode[`n${year}`],
        per_bm: benchmarkNode[`per${year}`],
      }))

      return {
        dwellingStructure: node.DwellingStructure,
        data: data,
      }
    })

    const maxValue = flow([
      x => flatMapDeep(x, ({ data }) => {
        return map(data, item =>
          dataType === 'number' ? item.value : [item.per, item.per_bm]
        )
      }),
      max
    ])(chartData)

    const ticks = scaleLinear()
      .domain([0, maxValue])
      .nice(4)
      .ticks(4)

    const yAxisFormatter = value =>
      dataType === 'percent'
        ? `${numeral(value).format('0[.]0')}%`
        : numeral(value).format('0,0')

    const legendItems = () => {
      const legendItems = [{ label: areaName, color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary') }]

      if (dataType === 'percent') {
        legendItems.push({ label: benchmarkName, color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500') })
      }

      return legendItems
    }

    const chartIcon = dwellingType => {
      switch (dwellingType) {
        case 'Separate house':
          return (
            <LandcomIconHouse className="landcom-icon-lg landcom-icon-purple" />
          )
        case 'Medium density':
          return (
            <LandcomIconTownhouse className="landcom-icon-lg landcom-icon-purple" />
          )
        case 'High density':
          return (
            <LandcomIconApartments className="landcom-icon-lg landcom-icon-purple" />
          )
        default:
          return null
      }
    }

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p
                key={key}
                className="recharts-tooltip-label m-0"
                style={{ color: item.color }}
              >
                {item.name}:{' '}
                {dataType === 'percent'
                  ? formatPercentage(item.value)
                  : numeral(item.value).format('0,0')}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    const charts = chartData.map(({ dwellingStructure, data }, index) => {
      return (
        <div className="col-md-4" key={index}>
          <div className="chart__viz" style={{ width: '100%', height: 200 }}>
            <ResponsiveContainer>
              <BarChart data={data} margin={{ top: 10 }}>
                <CartesianGrid vertical={false} />
                <YAxis
                  tickLine={false}
                  tickFormatter={yAxisFormatter}
                  scale="linear"
                  ticks={ticks}
                  tickCount={ticks.count + 1}
                  interval={0}
                  width={30}
                />
                <XAxis dataKey="year" />
                <Tooltip content={<CustomTooltip />} />
                {dataType === 'percent' && (
                  <Bar dataKey="per" fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')} name={areaName} />
                )}
                {dataType === 'percent' && (
                  <Bar
                    dataKey="per_bm"
                    fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}
                    name={benchmarkName}
                  />
                )}

                {dataType === 'number' && (
                  <Bar dataKey="value" fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')} name="LGA"
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          </div>
          <p className="text-center m-0">
            {chartIcon(dwellingStructure)}
            <br /> {dwellingStructure}
          </p>
        </div>
      )
    })

    return (
      <ChartWrapper
        name={`dominant-housing-type`}
        title={`What is the dominant housing type?`}
        subTitle={`Dwellings by dwelling type, 2006 to 2021`}
        dataSource={Datasource.ABS2006}
        dataNotes={dataNotes}
        chartInfo={chartInfo}
        body={
          <>
            <div className="mb-1">
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={changeToPercent}
                  active={dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={changeToNumber}
                  active={dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
            </div>
            <CustomLegend items={legendItems()} />
            <div className="row">{charts}</div>
          </>
        }
      />
    )
  } catch (error) {
    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

export default HousingTypesChart
