import React from 'react'
import { map, flow, flattenDeep, flatten, max, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import { LandcomIconHouse, LandcomIconTownhouse, LandcomIconApartments } from '../landcom-icons'
import numeral from 'numeral'
import ChartWrapper from '../chartWrapper'
import CustomLegend from '../../components/legend'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'

const viz_height = 340
const benchmarkColor =
  typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')

const Chart = ({ data, benchmarkData, benchmarkName, areaName }) => {
  const legendItems = [
    {
      color:
        typeof window !== `undefined` &&
        getComputedStyle(document.body).getPropertyValue('--bs-primary'),
      label: areaName,
    },
    {
      color: benchmarkColor,
      label: benchmarkName,
    },
  ]

  try {
    if (isEmpty(data)) {
      return <ErrorMessageChart error="No data for chart" />
    }
    const chartDataByHousing = [
      {
        type: 'Separate house',
        data: [
          {
            type: 'Lone persons',
            per: data.Lone_person_Separate_house_Per,
            bm_per: benchmarkData.Lone_person_Separate_house_Per,
            num: data.Lone_person_Separate_house,
            bm_num: benchmarkData.Lone_person_Separate_house,
          },
          {
            type: 'Couples',
            per: data.Couple_Separate_house_Per,
            bm_per: benchmarkData.Couple_Separate_house_Per,
            num: data.Couple_Separate_house,
            bm_num: benchmarkData.Couple_Separate_house,
          },
          {
            type: 'Families',
            per: data.Family_Separate_house_Per,
            bm_per: benchmarkData.Family_Separate_house_Per,
            num: data.Family_Separate_house,
            bm_num: benchmarkData.Family_Separate_house,
          },
          {
            type: 'Groups',
            per: data.Group_Separate_house_Per,
            bm_per: benchmarkData.Group_Separate_house_Per,
            num: data.Group_Separate_house,
            bm_num: benchmarkData.Group_Separate_house,
          },
        ],
      },
      {
        type: 'Medium density',
        data: [
          {
            type: 'Lone persons',
            per: data.Lone_person_Medium_density_Per,
            bm_per: benchmarkData.Lone_person_Medium_density_Per,
            num: data.Lone_person_Medium_density,
            bm_num: benchmarkData.Lone_person_Medium_density,
          },
          {
            type: 'Couples',
            per: data.Couple_Medium_density_Per,
            bm_per: benchmarkData.Couple_Medium_density_Per,
            num: data.Couple_Medium_density,
            bm_num: benchmarkData.Couple_Medium_density,
          },
          {
            type: 'Families',
            per: data.Family_Medium_density_Per,
            bm_per: benchmarkData.Family_Medium_density_Per,
            num: data.Family_Medium_density,
            bm_num: benchmarkData.Family_Medium_density,
          },
          {
            type: 'Groups',
            per: data.Group_Medium_density_Per,
            bm_per: benchmarkData.Group_Medium_density_Per,
            num: data.Group_Medium_density,
            bm_num: benchmarkData.Group_Medium_density,
          },
        ],
      },
      {
        type: 'High density',
        data: [
          {
            type: 'Lone persons',
            per: data.Lone_person_High_density_Per,
            bm_per: benchmarkData.Lone_person_High_density_Per,
            num: data.Lone_person_High_density,
            bm_num: benchmarkData.Lone_person_High_density,
          },
          {
            type: 'Couples',
            per: data.Couple_High_density_Per,
            bm_per: benchmarkData.Couple_High_density_Per,
            num: data.Couple_High_density,
            bm_num: benchmarkData.Couple_High_density,
          },
          {
            type: 'Families',
            per: data.Family_High_density_Per,
            bm_per: benchmarkData.Family_High_density_Per,
            num: data.Family_High_density,
            bm_num: benchmarkData.Family_High_density,
          },
          {
            type: 'Groups',
            per: data.Group_High_density_Per,
            bm_per: benchmarkData.Group_High_density_Per,
            num: data.Group_High_density,
            bm_num: benchmarkData.Group_High_density,
          },
        ],
      },
    ]

    const maxValue = flow([
      x => map(x, item => item.data),
      flattenDeep,
      x => map(x, item => [item.per, item.bm_per]),
      flatten,
      max,
    ])(chartDataByHousing)

    const domain = scaleLinear()
      .domain([0, maxValue])
      .nice(5)
      .domain()

    const gridLines = () => {
      let arr = []
      for (let i = 1; i <= chartDataByHousing[0].data.length - 1; i++) {
        arr.push((viz_height / chartDataByHousing[0].data.length) * i)
      }
      return arr
    }

    const chartIcon = dwellingType => {
      switch (dwellingType) {
        case 'Separate house':
          return <LandcomIconHouse className="landcom-icon-lg landcom-icon-purple" />
        case 'Medium density':
          return <LandcomIconTownhouse className="landcom-icon-lg landcom-icon-purple" />
        case 'High density':
          return <LandcomIconApartments className="landcom-icon-lg landcom-icon-purple" />
        default:
          return null
      }
    }

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {formatPercentage(item.value)} (
                {numeral(item.dataKey === 'per' ? item.payload.num : item.payload.bm_num).format(
                  '0,0'
                )}
                )
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    const charts = chartDataByHousing.map((item, index) => (
      <div className="col-4" key={index}>
        <div className="chart__viz" style={{ width: '100%', height: viz_height }}>
          <ResponsiveContainer>
            <BarChart
              barCategoryGap={15}
              className="overflow-visible"
              data={item.data}
              margin={{ right: 0 }}
              layout="vertical"
            >
              <YAxis type="category" dataKey="type" width={50} hide />
              <XAxis type="number" hide domain={domain} />
              <CartesianGrid vertical={false} horizontalPoints={gridLines()} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="per"
                name={areaName}
                unit="%"
                fill={
                  typeof window !== `undefined` &&
                  getComputedStyle(document.body).getPropertyValue('--bs-primary')
                }
                background={{
                  fill:
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-gray-200'),
                }}
                label={props => (
                  <CustomHorizontalLabel {...props} formatter={(value) => formatPercentage(value)} />
                )}
              />
              <Bar
                dataKey="bm_per"
                name={benchmarkName}
                unit="%"
                fill={benchmarkColor}
                background={{
                  fill:
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-gray-200'),
                }}
                label={props => (
                  <CustomHorizontalLabel {...props} formatter={(value) => formatPercentage(value)} />
                )}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    ))

    return (
      <div className="chart__body">
        <CustomLegend items={legendItems} />
        <div className="row">
          <div className="col-md-1 col-2"></div>
          <div className="col-md-11 col-10">
            <div className="row">
              {chartDataByHousing.map((item, index) => (
                <div className="col-4" key={index}>
                  <p className="text-center small">
                    {chartIcon(item.type)}
                    <br />
                    {item.type}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1 col-2">
            <div className="chart__viz" style={{ width: 50, height: viz_height }}>
              <ResponsiveContainer>
                <BarChart
                  barCategoryGap={15}
                  className="overflow-visible"
                  data={chartDataByHousing[0].data}
                  margin={{ right: 0 }}
                  layout="vertical"
                >
                  <YAxis type="category" dataKey="type" tickLine={false} width={50} />
                  <XAxis type="number" hide domain={domain} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-md-11 col-10">
            <div className="row">{charts}</div>
          </div>
        </div>
      </div>
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

const HouseholdsByDwellingTypeChart = ({
  data,
  dataNotes,
  benchmarkData,
  benchmarkName,
  areaName,
  chartInfo,
}) => (
  <ChartWrapper
    name={`households-by-dwelling-type`}
    title={`Who lives in what type of housing?`}
    subTitle={`Percent of households in dwelling types by household type, 2021`}
    dataSource={Datasource.ABS2021}
    dataNotes={dataNotes}
    chartInfo={chartInfo}
    body={
      <Chart
        data={data}
        benchmarkData={benchmarkData}
        benchmarkName={benchmarkName}
        areaName={areaName}
        chartInfo={chartInfo}
      />
    }
  />
)

export default HouseholdsByDwellingTypeChart
