import React from 'react'

const HouseholdType = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="80 80 350 320"
  >
    <defs />
    <path d="M404.6 299.4L352 167.9l-15.2 39-38.1 53.2-3.7-4-25.3 23.5-5.1-12.7a14 14 0 10-3.7-.1l-7.5 19.1-11.7 14.5-8.7-32.7h-35.6l-11.5-14.3-1.3 1-17.2-82.2h-72v91.4h8.2v-83.2h57.2l16.8 80.6 3.3-.7 12.6 15.6h33.2l11.2 42.2 5.7-7-11 28.2h19.8v41.8h8.2v-41.8h7.3v41.8h8.2v-41.8h17.5L273 287.8l25.3-23.5 5 3.5 21.4-30-24 61.6h35.6v82h8.2v-82h20.9v82h8.1v-82zm-147.3-46.3a5.8 5.8 0 115.7 5.7 5.8 5.8 0 01-5.7-5.7zm-12.7 78l18.2-46.7 18.7 46.7zM344 210.8l.3-.3h-.1l7.9-20.3 40.4 101h-79.8zM352.6 163.7a22.6 22.6 0 10-22.6-22.5 22.6 22.6 0 0022.6 22.5zm0-36.9a14.4 14.4 0 11-14.4 14.4 14.4 14.4 0 0114.4-14.4z" />
    <path d="M113.6 273.1h8.2v108.27h-8.2zM142.7 273.1h8.2v108.27h-8.2zM129.9 163.7a22.6 22.6 0 10-22.5-22.5 22.6 22.6 0 0022.5 22.5zm0-36.9a14.4 14.4 0 11-14.3 14.4 14.3 14.3 0 0114.3-14.4zM201 323.4h8.2v57.71H201zM216.5 323.4h8.2v57.71h-8.2zM211.6 267a14 14 0 10-13.9-13.9 14 14 0 0013.9 13.9zm0-19.7a5.8 5.8 0 11-5.7 5.8 5.8 5.8 0 015.7-5.8zM244 212.9a7.5 7.5 0 003.3.7 9.2 9.2 0 005.8-1.9l4-3.4c9.2-7.6 18.7-15.5 24.5-26.5 7.3-13.6 5.3-26.6-5.1-33.9-8.9-6.2-19.9-5.3-29.3 2.2l-.8-.6a23.8 23.8 0 00-23-4.3 21.3 21.3 0 00-14.7 15.6c-5.9 25.8 22.7 45 35.3 52.1zm-27.3-50.3a13.6 13.6 0 019.3-9.7 18.1 18.1 0 015.1-.8 15 15 0 0110 3.6l2.1 1.9 3.9 3.8 2.8-2.8c7.2-7.1 15.4-8.6 21.9-4 10.2 7.1 4.6 19.7 2.6 23.3-5.1 9.6-14 17-22.6 24.2l-3.8 3.2h-.5c-9.1-5-35.5-22.1-30.8-42.7z" />
  </svg>
)

export { HouseholdType }
