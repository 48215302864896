import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import HousingIllustration from '../img/housing-illustration.svg'

class ClientHomeDisabledPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { lgaName, slug, productLinks } = this.props.pageContext    

    productLinks.map((product) => {
      if (product.ApplicationID == 1016)
        product.IsDisabled = false //enable it so the tab is highlighted
    })    

    return (
      <Layout
        clientName={lgaName}
        clientAlias={slug}
        productLinks={productLinks}
        clientLogo=''>
        <PageHeader
          className="home"
          background={HousingIllustration}>
          <h1>How can housing.id inform your decision making?</h1>
          <p>This is an optional module.</p>
          <p>.id's housing monitor, <strong>housing.id</strong>, is an online evidence base created to help local governments influence and advocate for their community's housing needs, and monitor progress toward strategic planning goals. Designed for strategic planning and advocacy teams, <strong>housing.id</strong> combines housing, demographic, economic and population forecast data in one place.</p>
          <p>
            <a href="https://content.id.com.au/housing-monitor" target="_blank" className="productPromotionBtn">Learn more about housing.id</a>
          </p>
        </PageHeader>
      </Layout>
    )
  }
}

export default ClientHomeDisabledPage
