import React from 'react'
import { maxBy } from 'lodash'
import numeral from 'numeral'

const IncomeMixNote = ({ incomeRangesByYear, benchmarkName }) => {
  const latestIncomes = maxBy(incomeRangesByYear.nodes[0].data.Incomes, range => range.Year)
  const priceFormatterLong = value => numeral(value).format('$0,0')

  return (
    <div>
      <p>
        For {benchmarkName} in {latestIncomes.Year}, the following ranges are used in this chart, 
        and the Affordability Monitor section to calculate the availability of affordable housing 
        to fixed income levels. Census base income levels shown in this chart are adjusted to 
        current and past years from a base year of 2021, {' '}
        <a href="https://www.abs.gov.au/statistics/labour/earnings-and-working-conditions/average-weekly-earnings-australia/latest-release">
          Average Weekly Total Earnings
        </a>{' '}
        for employees in the State.
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Very Low</th>
            <th>Low</th>
            <th>Moderate</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(latestIncomes).map(
            (key, index) =>
              latestIncomes[key].Very_Low != null && (
                <tr key={'household-type-' + index}>
                  <td>
                    <strong>{latestIncomes[key].Label}</strong>
                  </td>
                  <td>Up to {priceFormatterLong(latestIncomes[key].Very_Low)}</td>
                  <td>Up to {priceFormatterLong(latestIncomes[key].Low)}</td>
                  <td>Up to {priceFormatterLong(latestIncomes[key].Moderate)}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  )
}

export default IncomeMixNote
