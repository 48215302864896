import React from 'react'
import HousingAffordabilityLgaChart from './affordability-lga-chart'
import * as Datasource from '../../config/text-constants'

const AffordabilityMortgageLgaChart = ({
  alias,
  pricePoints,
  incomeRangesByYear,
  dataNotes
}) => (
  <HousingAffordabilityLgaChart
    alias={alias}
    title={`How affordable is buying a home?`}
    dataSource={Datasource.Proptrack}
    dataNotes={dataNotes}
    name={'affordable-mortgage-lga'}
    pricePoints={pricePoints}
    incomeRangesByYear={incomeRangesByYear}
    type={'mortgage'}
    proptrackDisclaimer={true}
    xoffset={10}
  />
)

export default AffordabilityMortgageLgaChart
