import React, { useState, useContext, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from '../chartWrapper/index'
import Moment from 'moment'
import { scaleLinear } from 'd3-scale'
import formatPercentage from '../../functions/formatPercentage'
import {compareEndValueToPastvalue} from '../../functions/affordabilityChartHelpers'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import { formatNumber } from '../../functions/formatNumber'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabelWorkerAffordbilityChart'
import TimePeriodDropdown from '../timePeriodDropdown'
import { WorkerAffordabilityContext } from '../../hooks/workerAffordabilityContext'
import PillSelect from '../pillSelect'
import { PropTypes } from 'prop-types'

const WorkerHousingAvailabilityChart = ({
  availabilityData,
  dataNotes,
  name,
  title,
  type,
  areaName,
  proptrackDisclaimer,
}) => {

  try {
    const context = useContext(WorkerAffordabilityContext)
    const data = availabilityData

    const periods = data.sort((a, b) =>
      Moment(b.Period_Name, 'MMM YYYY').diff(Moment(a.Period_Name, 'MMM YYYY'))
    ).map(p => p.Period_Name)


    const dataTypeChange = 'change'
    const dataTypeNumber = 'number'
    const dataTypePercent = 'percent'

    const [dataType, setDataType] = useState(dataTypePercent)
    const activePeriod = (type === 'mortgage') ? (context.activeSalePeriod == null ? periods[0] : context.activeSalePeriod)
      : (context.activeRentalPeriod == null ? periods[0] : context.activeRentalPeriod)
    const latestPeriod = periods && periods.length > 0 && periods[0]
    const comparisonPeriods = periods && periods.length > 1 && periods.slice(1)

    //set up default value for periods
    useEffect(() => {
      if (type === 'mortgage') {
        if (!context.activeSalePeriod) {
          context.setActiveSalePeriod(latestPeriod)
        }
        if (!context.activeChangeStartSalePeriod && periods.length > 1) {
          context.setActiveChangeStartSalePeriod(periods[1])
        }
      }
      else {
        if (!context.activeRentalPeriod) {
          context.setActiveRentalPeriod(latestPeriod)
        }
        if (!context.activeChangeStartRentalPeriod && periods.length > 1) {
          context.setActiveChangeStartRentalPeriod(periods[1])
        }
      }
    }, [])

    const handleComparisonPeriodDropdownOnChange = event => {
      if (type === 'mortgage')
        context.setActiveChangeStartSalePeriod(event.target.value)
      else
        context.setActiveChangeStartRentalPeriod(event.target.value)
    }

    const handleActivePeriodDropdownOnChange = event => {
      if (type === 'mortgage')
        context.setActiveSalePeriod(event.target.value)
      else
        context.setActiveRentalPeriod(event.target.value)
    }

    const SubTitle = () => {
      if (dataType === 'change')
        return (
          <>
            Change in affordable {type === 'mortgage' ? 'sales' : 'rentals'} for top occupations working in the {areaName} between 12 months ended{' '}
            {periods.length > 2 && (
              <TimePeriodDropdown
                onChange={handleComparisonPeriodDropdownOnChange}
                periods={comparisonPeriods}
                currentPeriod={type === 'mortgage' ? context.activeChangeStartSalePeriod : context.activeChangeStartRentalPeriod}
              />
            )}
            {periods.length === 2 && <PillSelect>{type === 'mortgage' ? context.activeChangeStartSalePeriod : context.activeChangeStartRentalPeriod}</PillSelect>} and {' '}
            <PillSelect>{latestPeriod}</PillSelect>.
          </>
        )
      return (
        <>
          Affordable {type === 'mortgage' ? 'sales' : 'rentals'} (by median household income) in 12 months to {' '}
          <TimePeriodDropdown
            onChange={handleActivePeriodDropdownOnChange}
            periods={periods}
            currentPeriod={activePeriod}
          /> for the top occupations working in {areaName}.
        </>
      )
    }

    const dwellingSizeAll = 'BedroomAll'
    const dwellingSizeBedroom1 = 'Bedroom1'
    const dwellingSizeBedroom2 = 'Bedroom2'
    const dwellingSizeBedroom3Plus = 'Bedroom3Plus'
    const dwellingSizeNotStated = 'BedroomNotStated'

    const total = 'Total_Value'
    const totalChange = 'Total_Change'
    const labelPosition = 'Label_Position'
    const labelData = 'Label_Data'

    const chartDataKeys = {
      [total]: total,
      [totalChange]: totalChange,
      [dwellingSizeAll]: {
        [dataTypeChange]: 'change',
        [dataTypeNumber]: 'number',
        [dataTypePercent]: 'percent',
      },
      [dwellingSizeBedroom1]: {
        [dataTypeChange]: 'bedrooms1Change',
        [dataTypeNumber]: 'bedrooms1Number',
        [dataTypePercent]: 'bedrooms1Percent',
      },
      [dwellingSizeBedroom2]: {
        [dataTypeChange]: 'bedrooms2Change',
        [dataTypeNumber]: 'bedrooms2Number',
        [dataTypePercent]: 'bedrooms2Percent',
      },
      [dwellingSizeBedroom3Plus]: {
        [dataTypeChange]: 'bedrooms3PlusChange',
        [dataTypeNumber]: 'bedrooms3PlusNumber',
        [dataTypePercent]: 'bedrooms3PlusPercent',

      },
      [dwellingSizeNotStated]: {
        [dataTypeChange]: 'bedroomsNotStatedChange',
        [dataTypeNumber]: 'bedroomsNotStatedNumber',
        [dataTypePercent]: 'bedroomsNotStatedPercent',
      },
    }

    const getChartDataKey = (dwellingSize, dataType) => {
      //maybe more logic will be implemented    
      return chartDataKeys[dwellingSize][dataType]
    }

    const getChartData = (data, inputLatestPeriod, inputComparisonPeriod) => {

      const dataNode = data.find(p => p.Period_Name == inputLatestPeriod)

      const comparisonData = inputComparisonPeriod
        ? data.find(p => p.Period_Name == inputComparisonPeriod)
        : data.length > 1
          ? data[1]
          : null

      let selectedChartData = []

      if (dataNode != undefined && comparisonData != null) {
        for (let i = 0; i < dataNode.Calculated_Afforability.length; i++) {
          let fromNode = dataNode.Calculated_Afforability[i]
          let endNode = comparisonData.Calculated_Afforability[i]

          selectedChartData.push({
            name: fromNode.Occupation,
            [chartDataKeys[total]]: dataNode.Total_Value,
            [chartDataKeys[totalChange]]: compareEndValueToPastvalue(dataNode.Total_Value, comparisonData.Total_Value),
            [chartDataKeys[dwellingSizeAll][dataTypeNumber]]: fromNode.Dwelling_Size.All,
            [chartDataKeys[dwellingSizeAll][dataTypePercent]]: fromNode.Dwelling_Size.All_Percent,
            [chartDataKeys[dwellingSizeAll][dataTypeChange]]: compareEndValueToPastvalue(
              fromNode.Dwelling_Size.All,
              endNode.Dwelling_Size.All
            ),
            color:
              typeof window !== `undefined` &&
              getComputedStyle(document.body).getPropertyValue('--bs-primary'),
            [chartDataKeys[dwellingSizeBedroom1][dataTypeNumber]]:
              fromNode.Dwelling_Size.Bedrooms_1,
            [chartDataKeys[dwellingSizeBedroom1][dataTypePercent]]:
              fromNode.Dwelling_Size.Bedrooms_1_Percent,
            [chartDataKeys[dwellingSizeBedroom1][dataTypeChange]]: compareEndValueToPastvalue(
              fromNode.Dwelling_Size.Bedrooms_1,
              endNode.Dwelling_Size.Bedrooms_1
            ),
            [chartDataKeys[dwellingSizeBedroom2][dataTypeNumber]]:
              fromNode.Dwelling_Size.Bedrooms_2,
            [chartDataKeys[dwellingSizeBedroom2][dataTypePercent]]:
              fromNode.Dwelling_Size.Bedrooms_2_Percent,
            [chartDataKeys[dwellingSizeBedroom2][dataTypeChange]]: compareEndValueToPastvalue(
              fromNode.Dwelling_Size.Bedrooms_2,
              endNode.Dwelling_Size.Bedrooms_2
            ),
            [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeNumber]]:
              fromNode.Dwelling_Size.Bedrooms_3Plus,
            [chartDataKeys[dwellingSizeBedroom3Plus][dataTypePercent]]:
              fromNode.Dwelling_Size.Bedrooms_3Plus_Percent,
            [chartDataKeys[dwellingSizeBedroom3Plus][dataTypeChange]]: compareEndValueToPastvalue(
              fromNode.Dwelling_Size.Bedrooms_3Plus,
              endNode.Dwelling_Size.Bedrooms_3Plus
            ),
            [chartDataKeys[dwellingSizeNotStated][dataTypeNumber]]:
              fromNode.Dwelling_Size.Bedrooms_Not_Stated,
            [chartDataKeys[dwellingSizeNotStated][dataTypePercent]]:
              fromNode.Dwelling_Size.Bedrooms_Not_Stated_Percent,
            [chartDataKeys[dwellingSizeNotStated][dataTypeChange]]: compareEndValueToPastvalue(
              fromNode.Dwelling_Size.Bedrooms_Not_Stated,
              endNode.Dwelling_Size.Bedrooms_Not_Stated
            ),
          })
        }
      }
      return selectedChartData
    }

    //calculate local accumulated Max, Min for Domain and to decide the position of label, particular for change mode  
    const calculateLocalMinMaxIndex = (inputChartData, inputDataType) => {

      let dicData = {}
      const rangeSizes = [dwellingSizeBedroom1, dwellingSizeBedroom2, dwellingSizeBedroom3Plus, dwellingSizeNotStated]

      var stackedValue = inputChartData[getChartDataKey(dwellingSizeAll, inputDataType)]

      dicData[inputChartData.name] = {}
      dicData[inputChartData.name][labelData] = stackedValue

      if (inputDataType !== dataTypeChange) {
        dicData[inputChartData.name][labelPosition] = dwellingSizeNotStated
        dicData.domain = [0, stackedValue]
      }
      else {
        let acccAmount = 0
        let labelField = dwellingSizeBedroom1

        let maxValue = Number.MIN_VALUE
        let minValue = Number.MAX_VALUE

        for (let j = 0; j < rangeSizes.length; j++) {

          let currField = rangeSizes[j]
          let currFieldKey = getChartDataKey(currField, dataTypeChange)

          acccAmount += inputChartData[currFieldKey]

          //the label should be on the right ==> just want to keep track the position of local max
          //still need to keep track of the value for local min
          if (stackedValue >= 0) {
            if (acccAmount >= maxValue) {
              maxValue = acccAmount
              labelField = currField
            }

            if (acccAmount < minValue) {
              minValue = acccAmount
            }
          }
          //the label should be on the left ==> just want to keep track the position of local min 
          //still need to keep track of the value for local max
          else {
            if (acccAmount < minValue) {
              minValue = acccAmount
              labelField = currField
            }

            if (acccAmount >= maxValue) {
              maxValue = acccAmount
            }
          }
        }
        dicData[inputChartData.name][labelPosition] = labelField
        dicData.domain = stackedValue >= 0 ? [minValue > 0 ? 0 : minValue, maxValue] : [minValue, maxValue < 0 ? 0 : maxValue]
      }
      return dicData
    }

    const calculateDomainLabelPosition = (inputChartData, inputDataType) => {

      let refDicChartData = {}
      let maxValue = Number.MIN_VALUE
      let minValue = Number.MAX_VALUE

      //go through each occupation in 10 occupations
      //calculate global max, min
      for (let i = 0; i < inputChartData.length; i++) {

        let currData = inputChartData[i]

        let localData = calculateLocalMinMaxIndex(currData, inputDataType)

        refDicChartData[currData.name] = localData[currData.name] //dictionary by occupation name for label position look up

        if (maxValue < localData.domain[1])
          maxValue = localData.domain[1]

        if (minValue > localData.domain[0])
          minValue = localData.domain[0]
      }

      refDicChartData.domain = [1.1 * minValue, 1.1 * maxValue] //label rendered either left or right, increase range to leave enought space

      return refDicChartData
    }

    const chartData = getChartData(
      data,
      dataType === 'change' ? latestPeriod : activePeriod,
      type === 'mortgage' ? context.activeChangeStartSalePeriod : context.activeChangeStartRentalPeriod
    )

    const dicForChartData = calculateDomainLabelPosition(chartData, dataType)

    const ticks = scaleLinear()
      .domain(dicForChartData.domain)
      .nice(9)
      .ticks(9)

    const CustomTooltip = props => {
      const { active } = props
      const salesOrrentals = type === 'mortgage' ? 'sales' : 'rental listings'

      const ChangeBadge = ({ value, children }) => {
        const className = value >= 0 ? 'text-success' : 'text-danger'
        return <span className={className}>{children}</span>
      }

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <p className="recharts-tooltip-label m-0 font-weight-bold">
              {label}{' '}
              {periods.length > 1 && (
                <span>
                  <br />
                  (compared to period ending{' '}
                  {Moment(type === 'mortgage' ? context.activeChangeStartSalePeriod : context.activeChangeStartRentalPeriod, 'MMM YYYY').format('MMM YYYY')})
                </span>
              )}
            </p>
            <div style={{ marginTop: 10 }}>
              <p className="recharts-tooltip-value m-0">
                Total number of {salesOrrentals}:{' '}
                {formatNumber(payload[0].payload.Total_Value)}{' '}
                {periods.length > 1 && (
                  <>
                    (
                    <ChangeBadge value={payload[0].payload[totalChange]}>
                      {formatNumber(payload[0].payload[totalChange], '+0,0')}
                    </ChangeBadge>
                    )
                  </>
                )}
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="recharts-tooltip-value m-0">
                Number of affordable {salesOrrentals}:{' '}
                {formatNumber(payload[0].payload.number)}{' '}
                {periods.length > 1 && (
                  <>
                    (
                    <ChangeBadge value={payload[0].payload.change}>
                      {formatNumber(payload[0].payload.change, '+0,0')}
                    </ChangeBadge>
                    )
                  </>
                )}
              </p>
              <p className="recharts-tooltip-value m-0 text-muted">
                Proportion of all {salesOrrentals}: {formatPercentage(payload[0].payload.percent)}
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="recharts-tooltip-value m-0">
                1 bedroom dwellings: {formatNumber(payload[0].payload.bedrooms1Number)} (
                <ChangeBadge value={payload[0].payload.bedrooms1Change}>
                  {formatNumber(payload[0].payload.bedrooms1Change, '+0,0')}
                </ChangeBadge>
                )
              </p>
              <p className="recharts-tooltip-value m-0 text-muted">
                Proportion of all {salesOrrentals}:{' '}
                {formatPercentage(payload[0].payload.bedrooms1Percent)}
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="recharts-tooltip-value m-0">
                2 bedroom dwellings: {formatNumber(payload[0].payload.bedrooms2Number)} (
                <ChangeBadge value={payload[0].payload.bedrooms2Change}>
                  {formatNumber(payload[0].payload.bedrooms2Change, '+0,0')}
                </ChangeBadge>
                )
              </p>
              <p className="recharts-tooltip-value m-0 text-muted">
                Proportion of all {salesOrrentals}:{' '}
                {formatPercentage(payload[0].payload.bedrooms2Percent)}
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="recharts-tooltip-value m-0">
                3+ bedroom dwellings:{' '}
                {formatNumber(payload[0].payload.bedrooms3PlusNumber)} (
                <ChangeBadge value={payload[0].payload.bedrooms3PlusChange}>
                  {formatNumber(payload[0].payload.bedrooms3PlusChange, '+0,0')}
                </ChangeBadge>
                )
              </p>
              <p className="recharts-tooltip-value m-0 text-muted">
                Proportion of all {salesOrrentals}:{' '}
                {formatPercentage(payload[0].payload.bedrooms3PlusPercent)}
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="recharts-tooltip-value m-0">
                Dwelling size not stated:{' '}
                {formatNumber(payload[0].payload.bedroomsNotStatedNumber)} (
                <ChangeBadge value={payload[0].payload.bedroomsNotStatedChange}>
                  {formatNumber(payload[0].payload.bedroomsNotStatedChange, '+0,0')}
                </ChangeBadge>
                )
              </p>
              <p className="recharts-tooltip-value m-0 text-muted">
                Proportion of all {salesOrrentals}:{' '}
                {formatPercentage(payload[0].payload.bedroomsNotStatedPercent)}
              </p>
            </div>
          </TooltipWrapper>
        )
      }
      return null
    }

    const getFormatedNumber = number => {
      if (dataType === 'percent') {
        return formatPercentage(number)
      }
      if (dataType === 'number') {
        return formatNumber(number)
      }
      if (dataType === 'change') {
        return formatNumber(number, '+0,0')
      }
    }


    const legendContent = [
      {
        id: '1 bedroom dwellings',
        type: 'square',
        color: 'rgb(200, 161, 227)',
        value: '1 bedroom dwellings',
      },
      {
        id: '2 bedroom dwellings',
        type: 'square',
        color: 'rgb(158, 90, 205)',
        value: '2 bedroom dwellings',
      },
      {
        id: '3+ bedroom dwellings',
        type: 'square',
        color: '#7513B8',
        value: '3+ bedroom dwellings',
      },
      {
        id: 'Dwelling size not stated',
        type: 'square',
        color: `${typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}`,
        value: 'Dwelling size not stated',
      },
    ]

    return (
      <ChartWrapper
        name={name}
        title={title}
        subTitle={<SubTitle />}
        dataSource={Datasource.Proptrack}
        dataNotes={dataNotes}
        proptrackDisclaimer={proptrackDisclaimer}
        body={
          <div>
            <div className="chart__controls form-inline d-print-none mb-2">
              <ButtonGroup size="sm" className="d-print-none  mb-2 me-2">
                <Button
                  outline
                  onClick={() => setDataType('percent')}
                  active={dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={() => setDataType('number')}
                  active={dataType === 'number'}
                >
                  Number
                </Button>
                {periods.length > 1 && (
                  <Button
                    outline
                    onClick={() => setDataType('change')}
                    active={dataType === 'change'}
                  >
                    Change
                  </Button>
                )}
              </ButtonGroup>
            </div>

            <div className="chart__viz" style={{ width: '100%', height: 500 }}>
              <ResponsiveContainer>
                <BarChart data={chartData} margin={{ bottom: 15, right: 40 }} layout="vertical">
                  <Tooltip content={<CustomTooltip />} />
                  <YAxis type="category" dataKey="name" tickLine={false} width={150} />
                  <XAxis
                    type="number"
                    tickFormatter={getFormatedNumber}
                    ticks={ticks}
                    interval={0}
                    tickCount={ticks.count + 1}
                  />
                  <CartesianGrid horizontal={false} vertical={false} />
                  <Legend
                    align="left"
                    verticalAlign="bottom"
                    layout="horizontal"
                    payload={legendContent}
                    iconSize={16}
                    wrapperStyle={{ bottom: 5 }}
                    formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                  />
                  <Bar
                    dataKey={getChartDataKey(
                      dwellingSizeBedroom1,
                      dataType
                    )}
                    name="No. of sales"
                    unit={dataType === 'percent' ? '%' : ''}
                    isAnimationActive={false}
                    stackId="a"
                    label={(props) =>
                      <CustomHorizontalLabel {...props}
                        formatter={(value) => getFormatedNumber(value)} labelField={labelPosition} labelDataField={labelData} barCategory={dwellingSizeBedroom1} refData={dicForChartData}
                      />}
                  >
                    {chartData.map((_entry, index) => (
                      <Cell fill="rgb(200, 161, 227)" key={`cell-${index}`} />
                    ))}
                  </Bar>
                  <Bar
                    dataKey={getChartDataKey(
                      dwellingSizeBedroom2,
                      dataType
                    )}
                    name="No. of sales"
                    unit={dataType === 'percent' ? '%' : ''}
                    isAnimationActive={false}
                    stackId="a"
                    label={(props) =>
                      <CustomHorizontalLabel {...props}
                        formatter={(value) => getFormatedNumber(value)} labelField={labelPosition} labelDataField={labelData} barCategory={dwellingSizeBedroom2} refData={dicForChartData}
                      />}
                  >
                    {chartData.map((_entry, index) => (
                      <Cell fill="rgb(158, 90, 205)" key={`cell-${index}`} />
                    ))}

                  </Bar>
                  <Bar
                    dataKey={getChartDataKey(
                      dwellingSizeBedroom3Plus,
                      dataType
                    )}
                    name="No. of sales"
                    unit={dataType === 'percent' ? '%' : ''}
                    isAnimationActive={false}
                    stackId="a"
                    label={(props) =>
                      <CustomHorizontalLabel {...props}
                        formatter={(value) => getFormatedNumber(value)} labelField={labelPosition} labelDataField={labelData} barCategory={dwellingSizeBedroom3Plus} refData={dicForChartData}
                      />}
                  >
                    {chartData.map((_entry, index) => (
                      <Cell fill="#7513B8" key={`cell-${index}`} />
                    ))}
                  </Bar>
                  <Bar
                    dataKey={getChartDataKey(
                      dwellingSizeNotStated,
                      dataType
                    )}
                    name="No. of sales"
                    unit={dataType === 'percent' ? '%' : ''}
                    isAnimationActive={false}
                    stackId="a"
                    label={(props) =>
                      <CustomHorizontalLabel {...props}
                        formatter={(value) => getFormatedNumber(value)} labelField={labelPosition} labelDataField={labelData} barCategory={dwellingSizeNotStated} refData={dicForChartData}
                      />}
                  >
                    {chartData.map((_entry, index) => (
                      <Cell key={`cell-${index}`}
                        fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        }
      />
    )
  }
  catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

WorkerHousingAvailabilityChart.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  type: PropTypes.oneOf(['mortgage', 'rental']).isRequired,
  dataNotes: PropTypes.object,
  availabilityData: PropTypes.array.isRequired,
  areaName: PropTypes.string,
  proptrackDisclaimer: PropTypes.bool,
}


export default WorkerHousingAvailabilityChart
