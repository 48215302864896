import React from 'react'
import {ReactComponent as ChevronRight} from '../../img/id-icons/SVG/chevron-right.svg'
import {ReactComponent as ChevronDown} from '../../img/id-icons/SVG/chevron-down.svg'
import {ReactComponent as ChevronUp} from '../../img/id-icons/SVG/chevron-up.svg'
import {ReactComponent as Map} from '../../img/map.svg'
import {ReactComponent as Share} from '../../img/id-icons/SVG/share.svg'
import '../../img/id-icons/style.css'


const baseClassName = 'icon id-icon';

export const IdIconChevronRight = (props) => (<ChevronRight className={`${baseClassName} ${props.className}`} />);
export const IdIconChevronDown = (props) => (<ChevronDown className={`${baseClassName} ${props.className}`} />);
export const IdIconChevronUp = (props) => (<ChevronUp className={`${baseClassName} ${props.className}`} />);
export const IdIconMap = (props) => (<Map className={`${baseClassName} ${props.className}`} />);
export const IdIconShare = (props) => (<Share className={`${baseClassName} ${props.className}`} />);