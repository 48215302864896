import Moment from 'moment'

const sortByAscendingDatetime = (array, property, format) => {
  return array.sort((a, b) => Moment(a[property], format).diff(Moment(b[property], format)))
}

const sortByDescendingDatetime = (array, property, format) => {
  return array.sort((a, b) => Moment(b[property], format).diff(Moment(a[property], format)))
}

export { sortByAscendingDatetime }
export { sortByDescendingDatetime }
