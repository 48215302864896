import React from 'react'

const OccupancyRatePerBedroom = ({ benchmarkName }) => {
  return (
    <div>
      <p>
        This dataset shows the average number of persons per occupied dwelling,
        by the size of dwelling (number of bedrooms), in the area - compared to{' '}
        {benchmarkName}.
      </p>
      <p>
        Used in conjunction with household type consumption data, this can show
        the level of utilisation of current housing stock and how that is
        changing over time. The occupancy rate dataset is often used in
        negotiating with housing developers for provision of types of affordable
        housing, and developer contributions in new housing areas.
      </p>
      <p>
        Occupancy rate is shown for Census years 2021 and 2016. It is calculated
        as a count of persons enumerated in private dwellings, divided by the
        number of occupied private dwellings in an area. It does not take into
        account unoccupied dwellings on Census night, for which bedroom size is
        not known and which have an occupancy of zero.
      </p>
      <p>Source: ABS Census of Population and Housing, 2021 and 2016</p>
    </div>
  )
}

export default OccupancyRatePerBedroom
