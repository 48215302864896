import React, { useState } from 'react'
import { kebabCase } from 'lodash'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { IdIconShare } from '../idIcon'

const ExportDropdownMenu = ({
  downloadCallback,
  clipboardCallback,
  chartTitle,
  currentUrl,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [modalOpen, setModelOpen] = useState(false)

  const toggle = () => {
    setDropdownOpen(prevState => !prevState)
  }

  const toggleModal = () => {
    setModelOpen(prevState => !prevState)
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          title="Export..."
          color="link"
          className="export-dropdown-button d-print-none"
        >
          <span className="visually-hidden">Export menu</span>
          <IdIconShare />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem header>Export image</DropdownItem>
          <DropdownItem onClick={downloadCallback}>Download</DropdownItem>
          { typeof (ClipboardItem) != typeof(undefined) &&
            <DropdownItem onClick={clipboardCallback}>
              Copy to clipboard
            </DropdownItem>
          }
          <DropdownItem onClick={toggleModal}>Request chart data</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modalOpen} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>Request chart data</ModalHeader>
        <ModalBody>
          <form
            name="chartdata"
            method="POST"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="chartdata" />
            <div className="d-none">
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Email address:</label>
              <input
                type="email"
                name="email"
                className="form-control"
                id={`email-${kebabCase(chartTitle)}`}
                aria-describedby="emailHelp"
                placeholder="joe@example.com"
              />
              <input type="hidden" name="chart" value={chartTitle} />
              <input type="hidden" name="location" value={currentUrl} />
              <small id="emailHelp" className="form-text text-muted">
                We‘ll never share your email with anyone else.
              </small>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit request
            </button>
          </form>
        </ModalBody>
      </Modal>
      {/* Hidden form for netlify https://docs.netlify.com/forms/setup/#javascript-forms */}
      <div className="d-none" aria-hidden="true">
        <form
          name="chartdata"
          method="POST"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="chartdata" />
          <input name="bot-field" />
          <input
            type="email"
            name="email"
            className="form-control"
            id={`email-${kebabCase(chartTitle)}`}
            aria-describedby="emailHelp"
            placeholder="joe@example.com"
          />
          <input type="hidden" name="chart" value={chartTitle} />
          <input type="hidden" name="location" value={currentUrl} />
        </form>
      </div>
    </>
  )
}

export default React.memo(ExportDropdownMenu)
