import React, { useEffect } from 'react'
import { filter, isEmpty, head } from 'lodash'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'
import numeral from 'numeral'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import HousingNeedChart from '../components/charts/housing-need'
import HousingNeedByHouseholdTypeChart from '../components/charts/housing-need-by-household-type'
import { AvailabilityContext } from '../hooks/availabilityContext'
import SectionUpfrontText from '../components/collapsibleUpfrontText/section-upfront-text'
import HomelessnessTypes from '../components/charts/homelessness-types'
import HomelessnessTypesChange from '../components/charts/homelessness-types-change'
import MarginallyHoused from '../components/charts/marginally-housed'
import { formatNumber } from '../functions/formatNumber'
import formatPercentage from '../functions/formatPercentage'

const NeedTemplate = props => {
  useEffect(() => {
    const access = head(filter(passwordProtected, ['lga', pageContext.LGASlug]))
    if (!isEmpty(access) && access.password !== window.pass) {
      navigate(`/${pageContext.LGASlug}`)
    }
  })

  // Destructure passed in object
  const data = props.data
  const pageContext = props.pageContext

  const title = pageContext.title
  const slug = pageContext.slug
  const LGAName = pageContext.lgaName
  const LGACode = pageContext.lgacode
  const LGALongName = data.areas2XlsxAreas.LongName
  const alias = pageContext.LGASlug

  const housingNeedNode = data.housingNeed

  const totalInNeed = housingNeedNode
    ? [
        housingNeedNode.Homeless,
        housingNeedNode.Very_Low_Rental_Stress_No,
        housingNeedNode.Low_Rental_Stress_No,
        housingNeedNode.Moderate_Rental_Stress_No,
        housingNeedNode.Marginal_homeless,
      ].reduce((total, number) => total + number)
    : 0

  const totalPercentInNeed = housingNeedNode ? housingNeedNode.Percent_In_Need : 0

  const totalRentalStressNo = () => {
    try {
      return data.housingStress.Total_Rental_Stress_No
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  return (
    <AvailabilityContext.Consumer>
      {context => (
        <React.Fragment>
          <PageContentWrapper
            title={title}
            LGAName={LGALongName}
            theme={pageContext.theme}
            areas={data.areas2XlsxAreas}
            slug={slug}
            alias={alias}
            currentArea={LGACode}
            productLinks={pageContext.productLinks}
            clientLogo={data.allFile}
          >
            <h2
              className="section-heading anchor-point"
              id="how-many-households-are-in-need-of-affordable-housing"
            >
              Affordable housing need
            </h2>

            <SectionUpfrontText title="What is Affordable Housing Need?">
              <div>
                <blockquote className="blockquote">
                  <p>
                    Affordable housing need is the aggregate of households unable to access market
                    provided housing or requiring some form of housing assistance in the private
                    rental market to avoid a position of rental stress over the long term (greater
                    than a year).
                  </p>
                  <footer className="blockquote-footer">
                    AHURI,{' '}
                    <cite title="Modelling housing need in Australia to 2025">
                      Modelling housing need in Australia to 2025
                    </cite>
                  </footer>
                </blockquote>
                <p>Housing need is estimated for the following groups:</p>
                <ul>
                  <li>Homeless</li>
                  <li>Marginally housed</li>
                  <li>Estimate of Very low, low and moderate income households in long-term rental stress</li>
                  <li>Households in social housing (not included here as social housing is 
                    considered a "met" need for affordable housing).</li>
                </ul>
                <p>
                  In some cases, rental stress is only a temporary state. For example, some
                  households within housing stress may have temporarily lower incomes due to
                  short-term unemployment or on parental leave or may be looking to move into a more
                  affordable house after a break up or separation. Many of these households may exit
                  housing stress once they return to work or move house. The latest HILDA report
                  examined persistence of housing stress from one year to the next. The research
                  found that in the 2013 to 2016 period, 49.2% of those in housing stress in year
                  one were also in housing stress in the next year.
                </p>
                <p>
                  For this reason, the data in this section estimate housing need, assuming that 50%
                  of rental stress is households in temporary stress. Based on these assumptions, it
                  is estimated that around {numeral(totalRentalStressNo() / 2).format('0,0')}{' '}
                  households in rental stress needed affordable housing opportunities in 2021.
                </p>
                <p>
                  This is one method of quantifying housing need, based predominantly on Census
                  data. Another method is to look at affordability of actual housing sales and
                  rentals to different income levels, and this analysis is found in the
                  “Affordability and Availability” page.
                </p>
              </div>
            </SectionUpfrontText>

            <p className="lead">
              It is estimated that {numeral(totalInNeed).format('0,0')} households have an unmet
              need for affordable housing in {LGAName}. This represents{' '}
              {formatPercentage(totalPercentInNeed)} of all households compared to {' '}
              {formatPercentage(data.benchmarkHousingNeed.Percent_In_Need)} for {pageContext.benchmarkName}.
            </p>

            <div className="row">
              <div className="col-12">
                <HousingNeedChart
                  data={data.housingNeed}
                  benchmarkData={data.benchmarkHousingNeed}
                  pageContext={pageContext}
                  dataNotes={data.housingNeedNotes}
                />
              </div>
            </div>

            <div
              className="row anchor-point"
              id="what-types-of-households-are-in-need-of-affordable-housing"
            >
              <div className="col-12">
                <HousingNeedByHouseholdTypeChart
                  data={data.housingNeedByHouseholdType}
                  benchmarkData={data.benchmarkHousingNeedByHouseholdType}
                  pageContext={pageContext}
                  dataNotes={data.housingNeedByHouseholdTypeNotes}
                />
              </div>
            </div>

            <h2
              className="section-heading anchor-point"
              id="what-types-of-homelessness-are-people-experiencing"
            >
              Homelessness
            </h2>

            <SectionUpfrontText title="What is homelessness?">
              <div>
                <p>
                  Homelessness is the most direct and immediate measure of the need for affordable
                  housing in an area. A statistical definition of homelessness is defined by the ABS
                  as a person not having suitable accommodation alternatives, when their current
                  living arrangement:
                </p>
                <ul>
                  <li>is in a dwelling that is inadequate</li>
                  <li>has no tenure, or if their initial tenure is short and not extendable, or</li>
                  <li>
                    does not allow them to have control of, and access to space for social
                    relations.
                  </li>
                </ul>
                <p>
                  Homelessness is not the only measure of affordable housing need in Australia, but
                  it's clear that the population who are considered homeless form at least a
                  baseline for the immediate need for affordable housing in the area.
                </p>
                <p>
                  Homelessness is collected in a range of different categories from the Census every
                  5 years, via a “Homelessness Enumeration Strategy” which provides for the counting
                  of people experiencing homelessness through a range of avenues in the Census,
                  including recruiting people who work with homeless populations as Census field
                  staff. People experiencing homelessness were also encouraged to mark their address
                  as "no usual address" on the form.
                </p>
                <p>
                  Registers of rooming houses and supported accommodation were used to identify
                  locations of temporary accommodation providers in the Census.
                </p>
                <p>
                  Data here are presented in Homeless “operational categories” as provided from the
                  Census, and form a key component of the affordable housing need calculation, which
                  also includes those in financial rental stress long-term.
                </p>
                <p>
                  Marginally housed residents includes additional categories who are close to being
                  homeless while not officially recognised as absolute homelessness. These are
                  presented in the second chart.
                </p>
              </div>
            </SectionUpfrontText>

            <div
              className="row"
            >
              <div className="col-12">
                <HomelessnessTypes
                  data2021={data.homelessness2021}
                  bm2021={data.bm_homelessness2021}
                  pageContext={pageContext}
                  datanote={data.homelessnessTypesInfo}
                />
              </div>
            </div>
            <div className="row anchor-point" id="how-is-homelessness-changing">
              <div className="col-12">
                <HomelessnessTypesChange
                  data2021={data.homelessness2021}
                  bm2021={data.bm_homelessness2021}
                  data2016={data.homelessness2016}
                  bm2016={data.bm_homelessness2016}
                  pageContext={pageContext}
                  datanote={data.homelessnessTypesInfo}
                />
              </div>
            </div>
            <div className="row anchor-point" id="how-many-people-are-marginally-housed">
              <div className="col-12">
                <MarginallyHoused
                  data2021={data.marginallyHoused2021}
                  bm2021={data.bm_marginallyHoused2021}
                  pageContext={pageContext}
                  datanote={data.marginallyHousedInfo}
                />
              </div>
            </div>
          </PageContentWrapper>
        </React.Fragment>
      )}
    </AvailabilityContext.Consumer>
  )
}

export default NeedTemplate

export const dataNoteFragment = graphql`
  fragment DataNoteFragment on MarkdownRemark {
    html
    frontmatter {
      title
      subtitle
    }
  }
`

export const PageQuery = graphql`
  query HousingNeed($lgacode: String!, $benchmarkCode: String!, $geocode: String!) {
    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }

    housingStress: housingStressNeed21XlsxHousingStress(Area_Id: { eq: $lgacode }) {
      Very_Low_Mortgage_Stress_No
      Very_Low_Mortgage_Stress_Per
      Low_Mortgage_Stress_No
      Low_Mortgage_Stress_Per
      Moderate_Mortgage_Stress_No
      Moderate_Mortgage_Stress_Per
      Very_Low_Rental_Stress_No
      Very_Low_Rental_Stress_Per
      Low_Rental_Stress_No
      Low_Rental_Stress_Per
      Moderate_Rental_Stress_No
      Moderate_Rental_Stress_Per
      Total_Mortgage_Stress_No
      Total_Mortgage_Stress_Per
      Total_Rental_Stress_No
      Total_Rental_Stress_Per
    }
    housingNeed: housingStressNeed21XlsxHousingNeed(Area_Id: { eq: $lgacode }) {
      Homeless
      Homeless_Per
      Very_Low_Rental_Stress_No: Very_Low_Need_No
      Very_Low_Rental_Stress_Per: Very_Low_Need_Per
      Low_Rental_Stress_No: Low_Need_No
      Low_Rental_Stress_Per: Low_Need_Per
      Moderate_Rental_Stress_No: Moderate_Need_No
      Moderate_Rental_Stress_Per: Moderate_Need_Per
      Total_Households
      Percent_In_Need: Total_Need_Per
      Marginal_homeless
      Marginal_Homeless_per
    }
    benchmarkHousingNeed: housingStressNeed21XlsxHousingNeed(Area_Id: { eq: $benchmarkCode }) {
      Homeless_Per
      Very_Low_Rental_Stress_Per: Very_Low_Need_Per
      Low_Rental_Stress_Per: Low_Need_Per
      Moderate_Rental_Stress_Per: Moderate_Need_Per
      Percent_In_Need: Total_Need_Per
      Marginal_Homeless_per
    }
    housingNeedByHouseholdType: housingNeedByHouseholdType21XlsxHousingNeedByHouseholdType(
      Area_Id: { eq: $lgacode }
    ) {
      Area_Id
      Group_household_Very_Low_Number
      Group_household_Very_Low_Percent
      Group_household_Low_Number
      Group_household_Low_Percent
      Group_household_Moderate_Number
      Group_household_Moderate_Percent
      Group_household_Total_households_Total_households
      Lone_person_household_Very_Low_Number
      Lone_person_household_Very_Low_Percent
      Lone_person_household_Low_Number
      Lone_person_household_Low_Percent
      Lone_person_household_Moderate_Number
      Lone_person_household_Moderate_Percent
      Lone_person_household_Total_households_Total_households
      Couple_with_no_children_Very_Low_Number
      Couple_with_no_children_Very_Low_Percent
      Couple_with_no_children_Low_Number
      Couple_with_no_children_Low_Percent
      Couple_with_no_children_Moderate_Number
      Couple_with_no_children_Moderate_Percent
      Couple_with_no_children_Total_households_Total_households
      Families_Very_Low_Number
      Families_Very_Low_Percent
      Families_Low_Number
      Families_Low_Percent
      Families_Moderate_Number
      Families_Moderate_Percent
      Families_Total_households_Total_households
    }
    benchmarkHousingNeedByHouseholdType: housingNeedByHouseholdType21XlsxHousingNeedByHouseholdType(
      Area_Id: { eq: $benchmarkCode }
    ) {
      Area_Id
      Group_household_Very_Low_Number
      Group_household_Very_Low_Percent
      Group_household_Low_Number
      Group_household_Low_Percent
      Group_household_Moderate_Number
      Group_household_Moderate_Percent
      Group_household_Total_households_Total_households
      Lone_person_household_Very_Low_Number
      Lone_person_household_Very_Low_Percent
      Lone_person_household_Low_Number
      Lone_person_household_Low_Percent
      Lone_person_household_Moderate_Number
      Lone_person_household_Moderate_Percent
      Lone_person_household_Total_households_Total_households
      Couple_with_no_children_Very_Low_Number
      Couple_with_no_children_Very_Low_Percent
      Couple_with_no_children_Low_Number
      Couple_with_no_children_Low_Percent
      Couple_with_no_children_Moderate_Number
      Couple_with_no_children_Moderate_Percent
      Couple_with_no_children_Total_households_Total_households
      Families_Very_Low_Number
      Families_Very_Low_Percent
      Families_Low_Number
      Families_Low_Percent
      Families_Moderate_Number
      Families_Moderate_Percent
      Families_Total_households_Total_households
    }
    households: householdsXlsxHouseholdTypes(Area_Id: { eq: $lgacode }) {
      total_21
    }
    tenure: householdsXlsxTenure(Area_Id: { eq: $lgacode }) {
      N2021_Rented_Private
      Per2016_Rented_Private
      N2021_Rented_Social
      Per2016_Rented_Social
      N2021_Owned_Outright
      Per2016_Owned_Outright
      N2021_Owned_With_Mortgage
      Per2016_Owned_With_Mortgage
      N2021_Total
      N2011_Rented_Private
      Per2011_Rented_Private
      N2011_Rented_Social
      Per2011_Rented_Social
      N2011_Owned_Outright
      Per2011_Owned_Outright
      N2011_Owned_With_Mortgage
      Per2011_Owned_With_Mortgage
      N2011_Total
    }

    homelessness2016: allHomelessnessXlsx2016(filter: { Geocode: { eq: $lgacode } }) {
      nodes {
        People_living_in_improvised_dwellings__tents__or_sleeping_out
        People_in_supported_accommodation_for_the_homeless
        People_staying_temporarily_with_other_households
        People_living_in_boarding_houses
        People_in_other_temporary_lodgings
        People_living_in_severely_crowded_dwellings
        All_homeless_persons
      }
    }
    homelessness2021: allHomelessnessXlsx2021(filter: { Geocode: { eq: $lgacode } }) {
      nodes {
        People_living_in_improvised_dwellings__tents__or_sleeping_out
        People_in_supported_accommodation_for_the_homeless
        People_staying_temporarily_with_other_households
        People_living_in_boarding_houses
        People_in_other_temporary_lodgings
        People_living_in_severely_crowded_dwellings
        All_homeless_persons
      }
    }

    bm_homelessness2016: allHomelessnessXlsx2016(filter: { Geocode: { eq: $benchmarkCode } }) {
      nodes {
        People_living_in_improvised_dwellings__tents__or_sleeping_out
        People_in_supported_accommodation_for_the_homeless
        People_staying_temporarily_with_other_households
        People_living_in_boarding_houses
        People_in_other_temporary_lodgings
        People_living_in_severely_crowded_dwellings
        All_homeless_persons
      }
    }
    bm_homelessness2021: allHomelessnessXlsx2021(filter: { Geocode: { eq: $benchmarkCode } }) {
      nodes {
        People_living_in_improvised_dwellings__tents__or_sleeping_out
        People_in_supported_accommodation_for_the_homeless
        People_staying_temporarily_with_other_households
        People_living_in_boarding_houses
        People_in_other_temporary_lodgings
        People_living_in_severely_crowded_dwellings
        All_homeless_persons
      }
    }

    marginallyHoused2021: allHomelessnessXlsx2021(filter: { Geocode: { eq: $lgacode } }) {
      nodes {
        People_living_in_other_crowded_dwellings
        People_in_other_improvised_dwellings
        People_who_are_marginally_housed_in_caravan_parks
        All_marginally_housed
      }
    }
    bm_marginallyHoused2021: allHomelessnessXlsx2021(filter: { Geocode: { eq: $benchmarkCode } }) {
      nodes {
        People_living_in_other_crowded_dwellings
        People_in_other_improvised_dwellings
        People_who_are_marginally_housed_in_caravan_parks
        All_marginally_housed
      }
    }

    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      LongName
    }

    homelessnessTypesInfo: markdownRemark(
      frontmatter: { slug: { eq: "affordable-housing-need" }, topic: { eq: "homelessness-types" } }
    ) {
      ...DataNoteFragment
    }

    marginallyHousedInfo: markdownRemark(
      frontmatter: { slug: { eq: "affordable-housing-need" }, topic: { eq: "marginally-housed" } }
    ) {
      ...DataNoteFragment
    }
  }
`
