import React from 'react'
import HousingAvailabilityChart from './housing-availability-chart'

const HousingAvailabilityMortgageChart = ({ data, dataNotes, pageContext }) => {
  const title = `How much affordable housing is available to purchase in ${pageContext.lgaName}?`
  const name = `housing-availability-mortgage`

  return (
    <HousingAvailabilityChart
      availabilityData={data}
      dataNotes={dataNotes}
      name={name}
      title={title}
      type={'mortgage'}
      proptrackDisclaimer={true}
    />
  )
}

export default HousingAvailabilityMortgageChart
