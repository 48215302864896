import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import FeedbackForm from '../components/feedbackForm'

const FeedbackPage = () => (
  <Layout pageTitle={`Page not found`}>
    <PageHeader className="home">
      <h1 className="display-1 mb-5">Feedback</h1>
    </PageHeader>
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="mt-4 mb-4"><FeedbackForm /></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default FeedbackPage
