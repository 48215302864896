import React from 'react'
import { map, flow, flatten, max, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'
import { formatNumber } from '../../functions/formatNumber'

class HousingNeedChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    const { data, benchmarkData, pageContext, dataNotes } = this.props
    const benchmarkName = pageContext.benchmarkName
    const lgaName = pageContext.lgaName

    if (isEmpty(data) || isEmpty(benchmarkData)) {
      return <ErrorMessageChart error="No data for chart" />
    }
    try {
      const dataNode = data
      const dataNodeBenchMark = benchmarkData
      const chartData = [
        {
          name: 'Homeless',
          number: dataNode.Homeless,
          percentage: dataNode.Homeless_Per,
          bm_percentage: dataNodeBenchMark.Homeless_Per,
        },
        {
          name: 'Marginally housed',
          number: dataNode.Marginal_homeless,
          percentage: dataNode.Marginal_Homeless_per,
          bm_percentage: dataNodeBenchMark.Marginal_Homeless_per,
        },
        {
          name: 'Very low income in rental stress',
          number: dataNode.Very_Low_Rental_Stress_No,
          percentage: dataNode.Very_Low_Rental_Stress_Per,
          bm_percentage: dataNodeBenchMark.Very_Low_Rental_Stress_Per,
        },
        {
          name: 'Low income in rental stress',
          number: dataNode.Low_Rental_Stress_No,
          percentage: dataNode.Low_Rental_Stress_Per,
          bm_percentage: dataNodeBenchMark.Low_Rental_Stress_Per,
        },
        {
          name: 'Moderate income in rental stress',
          number: dataNode.Moderate_Rental_Stress_No,
          percentage: dataNode.Moderate_Rental_Stress_Per,
          bm_percentage: dataNodeBenchMark.Moderate_Rental_Stress_Per,
        },
      ]

      const maxValue = flow([
        x =>
          map(x, item =>
            this.state.dataType === 'percent' ? [item.percentage, item.bm_percentage] : item.number
          ),
        flatten,
        max,
      ])(chartData)

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(5)
        .ticks(5)

      const totalInNeed = chartData.reduce((total, item) => total + item.number, 0)

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}:{' '}
                  {this.state.dataType === 'percent'
                    ? formatPercentage(item.value)
                    : formatNumber(item.value)}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`housing-need`}
          title={`How many households are in need of affordable housing?`}
          subTitle={`Households unable to access market priced rental housing,
        2021`}
          dataSource={Datasource.Homelessness}
          dataNotes={dataNotes}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none mb-2">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
              <div className="row">
                <div className="col-lg-3">
                  <p>
                    <span className="h3 text-primary">{formatNumber(totalInNeed)} </span>
                    households ({formatPercentage(dataNode.Percent_In_Need)}) are in need of
                    affordable housing in {lgaName}
                  </p>
                </div>
                <div className="col-lg-9">
                  <div className="chart__viz" style={{ width: '100%', height: 360 }}>
                    <ResponsiveContainer>
                      <BarChart data={chartData} margin={{ right: 30 }} layout="vertical">
                        <Legend
                          verticalAlign="top"
                          align="left"
                          height={50}
                          formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                        />
                        <YAxis type="category" dataKey="name" tickLine={false} width={140} />
                        <XAxis
                          type="number"
                          ticks={ticks}
                          interval={0}
                          tickCount={ticks.count + 1}
                          tickFormatter={(value) => this.state.dataType === 'percent'
                            ? formatPercentage(value)
                            : formatNumber(value)
                          }
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar
                          dataKey={this.state.dataType === 'percent' ? 'percentage' : 'number'}
                          fill={
                            typeof window !== `undefined` &&
                            getComputedStyle(document.body).getPropertyValue('--bs-primary')
                          }
                          name={lgaName}
                          minPointSize={1}
                          label={props => (
                            <CustomHorizontalLabel {...props} formatter={(value) => this.state.dataType === 'percent'
                            ? formatPercentage(value)
                            : formatNumber(value)} />
                          )}
                        />
                        {this.state.dataType === 'percent' && (
                          <Bar
                            dataKey="bm_percentage"
                            name={benchmarkName}
                            fill={
                              typeof window !== `undefined` &&
                              getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                            }
                            minPointSize={1}
                            label={props => (
                              <CustomHorizontalLabel {...props} formatter={(value) => formatPercentage(value)}/>
                            )}
                          />
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default HousingNeedChart
