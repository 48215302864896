import React from 'react'
import {
  map,
  get,
  toLower,
  indexOf,
  find,
  concat,
  flow,
  maxBy,
  groupBy,
  filter,
  isEmpty,
} from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from './../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import { ErrorMessageChart } from '../error-message-chart'
import { formatNumber } from '../../functions/formatNumber'

const HouseholdForecastChart = props => {
  if (isEmpty(props.data) || isEmpty(props.data.edges)) {
    return <ErrorMessageChart error="No data for chart" />
  }

  let largeData, smallData, maxNumber, combined, ticks

  try {
    largeData = flow([
      x => map(x, ({ node }) => node),
      x => filter(x, item => indexOf(['Families', 'Groups'], item.Type) !== -1),
      x => groupBy(x, 'Year'),
      x =>
        map(x, (objs, key) => ({
          Year: key,
          NFamilies: find(objs, o => o.Type === 'Families').Number,
          NGroups: find(objs, o => o.Type === 'Groups').Number,
          NTotal:
            find(objs, o => o.Type === 'Families').Number +
            find(objs, o => o.Type === 'Groups').Number,
        })),
    ])(props.data.edges)

    smallData = flow([
      x => map(x, ({ node }) => node),
      x => filter(x, item => indexOf(['Lone persons', 'Couples'], item.Type) !== -1),
      x => groupBy(x, 'Year'),
      x =>
        map(x, (objs, key) => ({
          Year: key,
          NLonePersons: find(objs, o => o.Type === 'Lone persons').Number,
          NCouples: find(objs, o => o.Type === 'Couples').Number,
          NTotal:
            find(objs, o => o.Type === 'Lone persons').Number +
            find(objs, o => o.Type === 'Couples').Number,
        })),
    ])(props.data.edges)

    combined = concat(smallData, largeData)

    maxNumber = maxBy(combined, 'NTotal').NTotal

    ticks = scaleLinear()
      .domain([0, maxNumber])
      .nice(5)
      .ticks(5)

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {formatNumber(item.value)}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    // Find dataNotes text from an array of possible forecast datanotes, based on the Source column of the dataset
    //TODO: Re-engineer datanotes to be queryable based on what clients have
    const source = toLower(get(props.data, 'edges[0].node.Source', ''))
    const dataNotesForForecastSource = props.dataNotes.edges.find(
      ({ node }) => node.frontmatter.sourceID == source
    ).node

    return (
      <ChartWrapper
        name={`forecast-household-types-chart`}
        title={`How are households forecast to change?`}
        dataSource={dataNotesForForecastSource.frontmatter.sourceText}
        dataNotes={dataNotesForForecastSource}
        chartInfo={props.chartInfo}
        body={
          <div className="row">
            <div className="col-md-6">
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={largeData}>
                    <XAxis dataKey="Year" tickLine={false} />
                    <YAxis
                      tickLine={false}
                      tickFormatter={value => formatNumber(value)}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.length}
                      type="number"
                    />
                    <CartesianGrid vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      stackId="a"
                      dataKey="NFamilies"
                      name="Families"
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-primary')
                      }
                    />
                    <Bar
                      stackId="a"
                      dataKey="NGroups"
                      name="Groups"
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-muted')
                      }
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <p className="m-0 text-center">Large households</p>
            </div>
            <div className="col-md-6">
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={smallData}>
                    <XAxis dataKey="Year" tickLine={false} />
                    <YAxis
                      tickLine={false}
                      tickFormatter={value => formatNumber(value)}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.length}
                      type="number"
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Bar
                      stackId="a"
                      dataKey="NLonePersons"
                      name="Lone persons"
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-primary')
                      }
                    />
                    <Bar
                      stackId="a"
                      dataKey="NCouples"
                      name="Couples"
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-muted')
                      }
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <p className="m-0 text-center">Small households</p>
            </div>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default HouseholdForecastChart
