import React from 'react'
import WorkerHousingAvailabilityChart from './worker-housing-availability-chart'

const WorkerHousingAvailabilityMortgageChart = ({ data, dataNotes, areaName }) => {
  const title = `How much affordable housing is available to purchase for local workers?`
  const name = `worker-housing-availability-mortgage`

  return (
    <WorkerHousingAvailabilityChart
      availabilityData={data}
      dataNotes={dataNotes}
      name={name}
      title={title}
      type={'mortgage'}      
      areaName = {areaName}
      proptrackDisclaimer={true}
    />
  )
}

export default WorkerHousingAvailabilityMortgageChart
