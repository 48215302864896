import React from 'react'

const CustomVerticalLabel = ({ x, y, value, width, height, formatter }) => {
  const textFill =  "#000"
  const formattedValue = formatter ? formatter(value) : value
  return (
    <>
      <text
        className="recharts-text recharts-label"
        x={x + width / 2}
        y={y}
        dy={-5}
        fill={textFill}
        stroke="#F8F8F9"
        strokeWidth="2.5"
        textAnchor="middle"
      >
        {formattedValue}
      </text>

      <text
        className="recharts-text recharts-label"
        x={x + width / 2}
        y={y}
        dy={-5}
        fill={textFill}
        
        textAnchor="middle"
      >
        {formattedValue}
      </text>
    </>
  )
}

export default CustomVerticalLabel