import React from 'react'
import WorkerAffordabilityLgaChart from './worker-affordability-lga-chart'
import * as Datasource from '../../config/text-constants'

const WorkerAffordabilityMortgageLgaChart = ({
  alias,
  pricePoints,
  interest,
  dataNotes,
  areaName,
  data,
  affordableData
}) => (
  <WorkerAffordabilityLgaChart
    alias={alias}
    title={`How affordable is buying a home for local workers?`}
    dataSource={Datasource.Proptrack}
    dataNotes={dataNotes}
    name={'worker-affordable-mortgage-lga'}
    pricePoints={pricePoints}
    interest={interest}
    type={'mortgage'}
    areaName={areaName}
    proptrackDisclaimer={true}
    data={data}
    affordableData = {affordableData}
    xoffset={10}
  />

)

export default WorkerAffordabilityMortgageLgaChart
