import React, { useState } from 'react'
import Layout from '../layout'
import PageHeader from '../pageHeader/index.js'
import SideBar from '../sideBar/index.js'
import ProductLinksComponent from '../productLinks/index'
import { Collapse, Button } from 'reactstrap'
import { HamburgerIcon, CloseIcon } from './../landcom-icons'
import './styles.scss'

const PageContentWrapper = ({
  alias,
  LGAName,
  productLinks,
  title,
  slug,
  children,
  geocode,
  clientLogo,
}) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)

  return (
    <Layout
      clientName={LGAName}
      clientAlias={alias}
      pageTitle={title}
      productLinks={productLinks}
      clientLogo={clientLogo}
    >
      <div className="sticky-header">
        <PageHeader clientAlias={alias} clientName={LGAName} geocode={geocode}>
          <div className="row">
            <div className="col-1 d-md-none">
              <Button
                className="mobile-menu-btn"
                aria-label="Mobile menu"
                color="link"
                onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
              >
                {!mobileMenuIsOpen && <HamburgerIcon />}
                {mobileMenuIsOpen && <CloseIcon />}
              </Button>
            </div>
            <div className="col-11 col-md-12">
              <h1>
                {title} <br />
                <span className="area-name">{LGAName} </span>
              </h1>
            </div>
          </div>
          <div className="mobile-menu-wrapper">
            <Collapse isOpen={mobileMenuIsOpen}>
              <SideBar area={alias} slug={slug} productLinks={productLinks}/>
            </Collapse>
          </div>
        </PageHeader>
      </div>

      <div className="container print-100">
        <div className="row pt-4">
          <div className="col-md-3 sidebar-wrapper">
            <SideBar area={alias} slug={slug} productLinks={productLinks} />
          </div>
          <div className="col-md-9 page-content sticky-container">{children}</div>
        </div>
      </div>
      <ProductLinksComponent products={productLinks} LGAName={LGAName} />
    </Layout>
  )
}

export default PageContentWrapper
