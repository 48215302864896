import React from 'react'
import { map, flow, flatten, max } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { isEmpty } from 'lodash'
import { scaleLinear } from 'd3-scale'
import { ButtonGroup, Button } from 'reactstrap'
import numeral from 'numeral'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import {formatNumber} from '../../functions/formatNumber'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'

class IncomeMixChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    if (isEmpty(this.props.data)) {
      return <ErrorMessageChart error="No data for chart"  />
    }
    const props = this.props

    try {
      const chartData = [
        {
          group: 'Very low',
          Total_Per: props.data.v_low_perc,
          Total_Num: props.data.v_low_num,
          Total_Per_BM: props.benchmarkData.v_low_perc,
        },
        {
          group: 'Low',
          Total_Per: props.data.low_perc,
          Total_Num: props.data.low_num,
          Total_Per_BM: props.benchmarkData.low_perc,
        },
        {
          group: 'Moderate',
          Total_Per: props.data.mod_perc,
          Total_Num: props.data.mod_num,
          Total_Per_BM: props.benchmarkData.mod_perc,
        },
        {
          group: 'High & very high',
          Total_Per: props.data.high_perc,
          Total_Num: props.data.high_num,
          Total_Per_BM: props.benchmarkData.high_perc,
        },
      ]

      const maxValue = flow([
        x =>
          map(x, item =>
            this.state.dataType === 'percent'
              ? [item.Total_Per, item.Total_Per_BM]
              : [item.Total_Num]
          ),
        flatten,
        max,
      ])(chartData)

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(3)
        .ticks(3)

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}:{' '}
                  {this.state.dataType === 'percent'
                    ? formatPercentage(item.value)
                    : numeral(item.value).format('0,0')}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`income-mix-chart`}
          title={`What is the mix of incomes?`}
          subTitle={`Households in income brackets, 2021`}
          dataSource={Datasource.ABS2021}
          dataNotes={this.props.dataNotes}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis dataKey="group" tickLine={false} />
                    <YAxis
                      tickLine={false}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.count + 1}
                      tickFormatter={
                        this.state.dataType === 'percent'
                          ? value => `${numeral(value).format('0[.]0')}%`
                          : value => numeral(value).format('0,0')
                      }
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Bar
                      dataKey={this.state.dataType === 'percent' ? 'Total_Per' : 'Total_Num'}
                      name={props.areaName}
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-primary')
                      }
                      unit={this.state.dataType === 'percent' ? '%' : ''}
                      label={props => (
                        <CustomVerticalLabel {...props} formatter={(value) => this.state.dataType === 'percent'
                        ? formatPercentage(value)
                        : formatNumber(value)} />
                      )}
                    />
                    {this.state.dataType === 'percent' && (
                      <Bar
                        dataKey="Total_Per_BM"
                        name={props.benchmarkName}
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                        unit="%"
                        label={props => (
                          <CustomVerticalLabel {...props} formatter={(value)=>formatPercentage(value)} />
                        )}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      console.error(error)

      return <ErrorMessageChart error={error} />
    }
  }
}

export default IncomeMixChart
