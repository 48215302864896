import React, { useState, useContext } from 'react'
import ThematicMapCustomAreas from './thematic-map-custom-areas'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from './../chartWrapper/index'
import TimePeriodDropdown from '../timePeriodDropdown'
import { isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'
import { ErrorMessageChart } from '../error-message-chart'
import { DateTime } from 'luxon'
import { AvailabilityContext } from '../../hooks/availabilityContext'
import HouseholdTypeDropdown from '../householdTypeDropdown'
import _ from 'lodash'
import { filteredDataByFamilyType, removeOverlayFromAreaName } from './components/functions'

const AffordableSalesRentalMap = ({
  name,
  title,
  subTitle,
  dataSource,
  dataNotes,
  data,
  dataConfiguration,
  proptrackDisclaimer,
  pageContext,
  extraOverlayData,
}) => {
  const periods = data
    .map(item => item.Period_Name)
    .sort((a, b) => DateTime.fromFormat(b, 'LLL yyyy') - DateTime.fromFormat(a, 'LLL yyyy')) // Use Luxon's DateTime

  const context = useContext(AvailabilityContext)

  const selectedPeriod = context.activePeriod == null ? periods[0] : context.activePeriod

  const [selectedDataDisplayOption, setDataDisplayOption] = useState(
    dataConfiguration.dataDisplayOptions[dataConfiguration.defaultDataDisplayOption]
  )
  const [selectedDataDisplayType, setDataDisplayType] = useState(
    dataConfiguration.dataDisplayTypes[dataConfiguration.defaultDataDisplayType]
  )

  const handleTimePeriodDropdownChange = event => {
    context.setActivePeriod(event.target.value)
  }

  const changePropertyToMap = option => {
    setDataDisplayOption(option)
  }

  const changeDataDisplayTypeToMap = option => {
    setDataDisplayType(option)
  }

  const TimePeriodInTitle = () => (
    <>
      {periods.length > 1 && (
        <TimePeriodDropdown
          onChange={handleTimePeriodDropdownChange}
          periods={periods}
          currentPeriod={selectedPeriod}
        />
      )}
    </>
  )
  const SubTitle = () => (
    <span>
      {subTitle} for {selectedDataDisplayOption.label.toLowerCase()} over 12 months to{' '}
      {<TimePeriodInTitle />} for{' '}
      <HouseholdTypeDropdown
        onChange={handleHouseHoldTypeDropdownOnChange}
        value={context.householdType}
      />
    </span>
  )

  const modifiedData = filteredDataByFamilyType(data, context, selectedPeriod)
  let modifiedDataForExtraOverlay = filteredDataByFamilyType(
    extraOverlayData,
    context,
    selectedPeriod
  )

  const handleHouseHoldTypeDropdownOnChange = event => {
    context.setHouseholdType(event.target.value)
    modifiedData
    modifiedDataForExtraOverlay
  }

  const { Total, Total_Value, Median_House, Median_Unit } = data.find(
    item => item.Period_Name === selectedPeriod
  )

  const props = ['Low', 'Very_Low', 'Moderate']
  const standardValues = { ...Total, Total_Value }
  for (let prop of props) {
    standardValues[`${prop}_Percent`] =
      context.householdType !== 'Total'
        ? (_.sum(modifiedData.map(el => el.Area_Details[context.householdType][prop])) /
            Total_Value) *
          100
        : Total[`${prop}_Percent`]
    standardValues[prop] =
      context.householdType !== 'Total'
        ? _.sum(modifiedData.map(el => el.Area_Details[context.householdType][prop]))
        : Total[prop]
  }

  const totalRow = {
    label: pageContext.lgaName,
    default: standardValues,
    extra: {
      Median_House,
      Median_Unit,
    },
    Area_Id: -1,
  }

  try {
    if (isEmpty(data)) {
      return <ErrorMessageChart error="No data for chart" />
    }

    return (
      <ChartWrapper
        name={name}
        title={title}
        subTitle={<SubTitle />}
        dataSource={dataSource}
        dataNotes={dataNotes}
        proptrackDisclaimer={proptrackDisclaimer}
        body={
          <div className="chart__map__wrapper">
            <ButtonGroup size="sm" className="d-print-none mb-2">
              {dataConfiguration.dataDisplayOptions.map((option, index) => (
                <Button
                  outline
                  key={index}
                  active={selectedDataDisplayOption.label === option.label}
                  onClick={() => changePropertyToMap(option)}
                >
                  {option.short_label}
                </Button>
              ))}
            </ButtonGroup>

            <ButtonGroup size="sm" className="d-print-none mb-2 ms-4">
              {dataConfiguration.dataDisplayTypes.map((option, index) => (
                <Button
                  outline
                  key={index}
                  active={selectedDataDisplayType.label === option.label}
                  onClick={() => changeDataDisplayTypeToMap(option)}
                >
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
            <ThematicMapCustomAreas
              data={modifiedData}
              extraOverlayData={modifiedDataForExtraOverlay}
              propertyToMap={selectedDataDisplayOption}
              dataDisplayType={selectedDataDisplayType.value === 'number' ? true : false}
              dataConfiguration={dataConfiguration}
              totalRow={totalRow}
              pageContext={pageContext}
            />
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

AffordableSalesRentalMap.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  dataSource: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataNotes: PropTypes.object,
  dataConfiguration: PropTypes.object.isRequired,
  proptrackDisclaimer: PropTypes.bool,
}

export default AffordableSalesRentalMap
