import React from 'react'
import PillSelect from '../pillSelect'

export const DwellingSizes = {
  BedroomAll: 'All dwelling sizes',
  Bedroom1: '1 bedroom dwellings',
  Bedroom2: '2 bedroom dwellings',
  Bedroom3Plus: '3+ bedroom dwellings',
}

const DwellingSizeDropdown = ({ onChange, value }) => {
  return (
    <PillSelect aria-label="Dwelling Size" onChange={onChange} defaultValue={value}>
      {Object.keys(DwellingSizes).map(key => (
        <option value={key} key={DwellingSizes[key]}>
          {DwellingSizes[key]}
        </option>
      ))}
    </PillSelect>
  )
}

export default DwellingSizeDropdown
