import React, {useState} from 'react'
import FeedbackModal from '../feedbackModal'

const FooterFeedbackButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const toggle = () => setModalIsOpen(!modalIsOpen)

  return (
    <>
      <button className="btn btn-link" onClick={toggle}>
        Feedback
      </button>
      <FeedbackModal modalIsOpen={modalIsOpen} toggle={toggle} />
    </>
  )
}

export default FooterFeedbackButton