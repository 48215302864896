import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'

import ClientGrid from '../components/clientGrid'

const IndexPage = ({ data }) => {
  const productLinks = [
    {
      Url: 'https://profile.id.com.au',
      FullName: 'community profile',
      SubdomainName: 'profile',
      ApplicationId: 1
    },
    {
      Url: 'https://atlas.id.com.au',
      FullName: 'social atlas',
      SubdomainName: 'atlas',
      ApplicationId: 2
    },
    {
      Url: 'https://forecast.id.com.au',
      FullName: 'population forecast',
      SubdomainName: 'forecast',
      ApplicationId: 3
    },
    {
      Url: 'https://economy.id.com.au',
      FullName: 'economic profile',
      SubdomainName: 'economy',
      ApplicationId: 4
    },
    {
      Url: 'https://housing.id.com.au',
      FullName: 'housing monitor',
      SubdomainName: 'housing',
      ApplicationId: 1016
    },
    {
      Url: 'https://views.id.com.au',
      FullName: 'community views',
      SubdomainName: 'views',
      ApplicationId: 1018
    },
  ]

  return (
    <Layout productLinks={productLinks}>
      <PageHeader>
        <h1>A new platform for informing and monitoring housing strategy</h1>
        <p className="lead">
          housing.id is an online evidence base: a website created specifically
          for your LGA to help you advocate for your community’s housing needs
          and monitor progress toward strategic planning goals.
        </p>
        <p className="lead">
          Designed for strategic planning and advocacy teams, housing.id
          combines demographic, economic, housing and population forecast data
          in one place.
        </p>
      </PageHeader>

      <div className="container">
        <ClientGrid
          clientLogos={data.allFile}
          clients={data.allAreas2XlsxAreas}
        />
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query ClientLogos($clients: [String!], $clientIds: [Int]) {
    allFile(filter: { name: { in: $clients } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
          }
        }
      }
    }

    allAreas2XlsxAreas(
      filter: { Geocode: { in: $clientIds } }
    ) {
      edges {
        node {
        Area_Id
        Geocode
        Area_Type
        Name
        State_Id
        State_Name
        GCC_Code
        Alias
        LongName
      }
    }
  }
}
`

export default IndexPage
