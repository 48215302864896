import numeral from 'numeral'

const formatPercentage = (number, format = '0.0') => {
    return `${numeral(number).format(format)}%`
}

export const formatPercentageRoundDown = number => {
    if (number > 100) 
        number = 100
    return `${numeral(Math.floor(number)).format('0')}%`
}

export default formatPercentage
