import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import FeedbackForm from '../feedbackForm'

const FeedbackModal = ({ modalIsOpen, toggle }) => {
  return (
    <Modal isOpen={modalIsOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Give feedback</ModalHeader>
      <ModalBody>
        <FeedbackForm/>
      </ModalBody>
    </Modal>
  )
}

export default FeedbackModal