import React, { useState } from 'react'
export const WorkerAffordabilityContext = React.createContext()

const WorkerAffordabilityProvider = props => {
  const [activeRentalPeriod, setActiveRentalPeriod] = useState(null)
  const [activeSalePeriod, setActiveSalePeriod] = useState(null)
  const [activeChangeStartRentalPeriod, setActiveChangeStartRentalPeriod] = useState(null)
  const [activeChangeStartSalePeriod, setActiveChangeStartSalePeriod] = useState(null)
  const context = {
    activeRentalPeriod,
    activeSalePeriod,
    setActiveRentalPeriod,
    setActiveSalePeriod,
    activeChangeStartRentalPeriod, 
    setActiveChangeStartRentalPeriod,
    activeChangeStartSalePeriod, 
    setActiveChangeStartSalePeriod
  }

  return (
    <WorkerAffordabilityContext.Provider value={{ ...context }}>
      {props.children}
    </WorkerAffordabilityContext.Provider>
  )
}

export default (element) => (
  <WorkerAffordabilityProvider>
    {element.children}
  </WorkerAffordabilityProvider>
)
