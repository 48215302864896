import React from 'react'

const HousingSales = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="60 80 380 360"
  >
    <defs>
      <clipPath id="clip-path">
        <path fill="none" d="M157.8 217.5h82.3v146.77h-82.3z" />
      </clipPath>
    </defs>
    <g id="Group_2032" data-name="Group 2032">
      <path
        id="Path_2321"
        data-name="Path 2321"
        d="M370.5 172.9l-43.1-31.6-12.6-9.3-63.2-46.4L48.4 232.4h50.3v182h306.9v-182h46L383 182.1z"
        stroke="#751bb8"
        strokeMiterlimit="10"
        strokeWidth="12"
        fill="none"
      />
    </g>
    <path
      id="Path_2327"
      data-name="Path 2327"
      d="M299.5 384.8l42.4-45.3h-30.6V231h-22.5l.2 108.3h-31.6l42.1 45.6z"
    />
    <path
      id="Path_2334"
      data-name="Path 2334"
      d="M299.8 190.6l-42.4 45.3H288v108.5h22.4V236.1h31.5l-42.1-45.6z"
    />
    <g clipPath="url(#clip-path)" id="Group_2036" data-name="Group 2036">
      <g id="Group_2035" data-name="Group 2035">
        <path
          id="Path_2326"
          data-name="Path 2326"
          d="M203.7 277.3V245a13.7 13.7 0 018.6 5.6 24.1 24.1 0 013.6 12.2h19.6v-.5c0-10.1-2.7-17.9-8.1-23.5s-13.3-8.9-23.7-9.7v-11.6h-9.2v11.9c-10.8 1.2-19.1 4.8-25.1 10.7a30.8 30.8 0 00-8.9 23 28.2 28.2 0 007.9 21c5.4 5.2 14.1 9.3 26.1 12.2v35.2a17.4 17.4 0 01-11.6-7 25.6 25.6 0 01-3.9-15v-.7h-21.2v2.8c0 11.6 3.1 20.6 9.4 27s15.4 9.7 27.3 10v15.7h9.2v-16.1c11.7-1.5 20.7-5.3 27-11.6a33.8 33.8 0 009.4-24.9c0-9.1-2.6-16.1-7.7-21.1s-14.7-9.4-28.7-13.3m-9.2-2.9a23.3 23.3 0 01-10.2-6.1 13.8 13.8 0 01-3.5-9.3 13.6 13.6 0 013.7-9.4 18 18 0 0110.1-5zm19.6 51.4a18.6 18.6 0 01-10.4 5.7v-32a25.7 25.7 0 0110.5 6.3 13.8 13.8 0 013.5 9.5 14.8 14.8 0 01-3.6 10.5"
        />
      </g>
    </g>
  </svg>
)

export { HousingSales }
