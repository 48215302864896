import React from 'react'
import * as Datasource from '../../config/text-constants'
import _ from 'lodash'
import calculateBMMedianForPropertyTypes from '../../functions/benchmarkMedianDataHelper'
import GenericPricePointsChart from './generic-price-points'
import { PropTypes } from 'prop-types'

const PricePointsRentalChart = ({
  alias,
  data, 
  areaName,
  dataNotes,
  benchmarkName,
  dataBM
}) => {


  const flattenedBMData = calculateBMMedianForPropertyTypes(dataBM,'Rentals')

  return (
    <GenericPricePointsChart
      alias={alias}
      title="How much does it cost to rent a home?"
      type={`rentals`}
      name={`rental-price-points`}
      areaName={areaName}
      benchmarkName={benchmarkName}
      dataSource={Datasource.Proptrack}
      dataNotes={dataNotes}
      dataBm={flattenedBMData}
      data={data}
      proptrackDisclaimer={true}
    />
  )
}

PricePointsRentalChart.propTypes = {
  alias: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataBM: PropTypes.object,
  dataNotes: PropTypes.object,  
  areaName: PropTypes.string.isRequired,  
  benchmarkName: PropTypes.string,   
}


export default PricePointsRentalChart
