import React, {useState} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from '../chartWrapper/index'
import {isEmpty} from 'lodash'
import * as Datasource from '../../config/text-constants'
import formatPercentage from '../../functions/formatPercentage'
import {formatNumber} from '../../functions/formatNumber'
import { ErrorMessageChart } from '../error-message-chart'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'
import TooltipWrapper from '../tooltip-wrapper'
import CustomLegend from '../legend'

const HomelessnessTypes = ({data2021, bm2021, pageContext, datanote}) => {
  if (isEmpty(data2021) || isEmpty(bm2021)){
    return (
        <ErrorMessageChart error="No data for chart"/>
    )
  }

  try {
    const [dataType, setDataType] = useState('Percent')    
    
    const dataDefinition = [
      {
        name: "People living in improvised dwellings, tents or sleeping out",
        key: "People_living_in_improvised_dwellings__tents__or_sleeping_out"
      },{
        name: "People living in supported accommodation for the homeless",
        key: "People_in_supported_accommodation_for_the_homeless"
      },{
        name: "People staying temporarily with other households",
        key: "People_staying_temporarily_with_other_households"
      },{
        name: "People living in boarding houses",
        key: "People_living_in_boarding_houses"
      },{
        name: "People in other temporary lodgings",
        key: "People_in_other_temporary_lodgings"
      },{
        name: "People living in 'severely' crowded dwellings",
        key: "People_living_in_severely_crowded_dwellings"
      }
    ]

    const chartData = dataDefinition.map(definition => {
      return {
        name: definition.name,
        Number: data2021.nodes[0][definition.key],
        Percent: 100*data2021.nodes[0][definition.key]/data2021.nodes[0].All_homeless_persons,
        bm_Number: bm2021.nodes[0][definition.key],
        bm_Percent: 100*bm2021.nodes[0][definition.key]/bm2021.nodes[0].All_homeless_persons,
      }
    })

    const CustomTooltip = props => {
      const { payload, label } = props

      return (
        <TooltipWrapper>
          <strong>{label}</strong>
          {payload.map((item, key) => (
            <p
              key={key}
              className="recharts-tooltip-label m-0"
              style={{ color: item.color }}
            >
              {item.name}: { dataType === "Percent"? formatPercentage(item.value): formatNumber(item.value)}
            </p>
          ))}
        </TooltipWrapper>
      )
    }
  
    const legendItems = [
      { 
        label: pageContext.lgaName, 
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')
      },
      {
        label: pageContext.benchmarkName,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
      }
    ]

    return (
      <ChartWrapper
        name={"what-types-of-homelessness-are-people-experiencing"}
        title={"What types of homelessness are people experiencing?"}
        subTitle={`Types of homelessness for the ${formatNumber(data2021.nodes[0].All_homeless_persons, "0,0")} people experiencing homelessness in ${pageContext.lgaName}`}
        dataSource={Datasource.ABS2021}
        dataNotes={datanote}
        body={
          <div>
           <div className="chart__controls form-inline d-print-none mb-2">
              <ButtonGroup size="sm" className="d-print-none  mb-2 me-2">
                <Button
                  outline
                  onClick={() => setDataType('Number')}
                  active={dataType === 'Number'}
                >
                  Number
                </Button>
                <Button
                  outline
                  onClick={() => setDataType('Percent')}
                  active={dataType === 'Percent'}
                >
                  Percent
                </Button>
              </ButtonGroup>
              <CustomLegend  items={legendItems}/>
            </div>
            <div
                className="chart__viz"
                style={{ width: '100%', height: 440 }}
              >
                <ResponsiveContainer>
                  <BarChart data={chartData} layout='vertical'>
                    <YAxis
                      dataKey="name"
                      tickLine={false}
                      type="category"
                      interval={0}
                      textAnchor="end"
                      width={200}
                    />
                    <XAxis
                      tickLine={false}
                      unit={dataType === 'Percent' ? '%' : ''}
                      type="number"
                    />
                    <CartesianGrid vertical={true} horizontal={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      dataKey={dataType}
                      name={pageContext.lgaName}
                      fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')}
                      label={(props) => 
                        <CustomHorizontalLabel {...props} 
                          formatter={(value) => dataType === "Percent"? formatPercentage(value): formatNumber(value)}
                      />}
                    />
                    {dataType === 'Percent' && (
                      <Bar
                        dataKey={`bm_${dataType}`}
                        name={pageContext.benchmarkName}
                        fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}
                        label={(props) => 
                          <CustomHorizontalLabel {...props} 
                            formatter={(value) => dataType === "Percent"? formatPercentage(value): formatNumber(value)}
                        />}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
          </div>
        }
      />
    )
  } catch (error) {
    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

export default HomelessnessTypes