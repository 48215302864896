import React from 'react'
import { groupBy, flow, map, flatten, flattenDeep, max } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import { LandcomIconHouse, LandcomIconTownhouse, LandcomIconApartments } from '../landcom-icons'
import { isEmpty } from 'lodash'
import ChartWrapper from '../chartWrapper'
import TooltipWrapper from '../tooltip-wrapper'
import { formatPercentageRoundDown } from '../../functions/formatPercentage'
import * as Datasource from '../../config/text-constants'
import { PropTypes } from 'prop-types'
import { ErrorMessageChart } from '../error-message-chart'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'
import { formatNumber } from '../../functions/formatNumber'

let viz_height = 680

const Chart = ({ data, houseHoldType, areaName }) => {
  if (isEmpty(data)) {
    return <ErrorMessageChart error="No data for chart" />
  }
  const color =
    typeof window !== `undefined` &&
    getComputedStyle(document.body).getPropertyValue('--bs-primary')

  const backgroundColor =
    typeof window !== `undefined` &&
    getComputedStyle(document.body).getPropertyValue('--bs-gray-200')

  var grouped = groupBy(data, type => type.Dwelling_Type)
  const dataKey = `${houseHoldType.value}_Percentage`

  try {
    const chartDataByHousing = [
      {
        type: 'Separate house',
        data: [...grouped['Separate house']],
      },
      {
        type: 'Medium density',
        data: [...grouped['Medium density']],
      },
      {
        type: 'High density',
        data: [...grouped['High density']],
      },
    ]

    viz_height = 54 * chartDataByHousing[0].data.length

    const maxValue = flow([
      x => map(x, item => item.data),
      flattenDeep,
      x => map(x, item => item[dataKey]),
      flatten,
      max,
    ])(chartDataByHousing)

    const domain = scaleLinear()
      .domain([0, maxValue])
      .nice(5)
      .domain()

    const gridLines = () => {
      let arr = []
      for (let i = 1; i <= chartDataByHousing[0].data.length - 1; i++) {
        arr.push((viz_height / chartDataByHousing[0].data.length) * i)
      }
      return arr
    }

    const chartIcon = dwellingType => {
      switch (dwellingType) {
        case 'Separate house':
          return <LandcomIconHouse className="landcom-icon-lg landcom-icon-purple" />
        case 'Medium density':
          return <LandcomIconTownhouse className="landcom-icon-lg landcom-icon-purple" />
        case 'High density':
          return <LandcomIconApartments className="landcom-icon-lg landcom-icon-purple" />
        default:
          return null
      }
    }

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.payload.Dwelling_Type}: {formatPercentageRoundDown(item.value)} (
                {formatNumber(item.payload[houseHoldType.value])})
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    const charts = chartDataByHousing.map((item, index) => {
      return (
        <div className="col-4" key={index}>
          <div className="chart__viz" style={{ width: '100%', height: viz_height }}>
            <ResponsiveContainer>
              <BarChart
                barCategoryGap={15}
                className="overflow-visible"
                data={item.data}
                margin={{ right: 0, left: 0 }}
                layout="vertical"
              >
                <YAxis type="category" dataKey="Area_Name" hide />
                <XAxis type="number" hide domain={domain} />
                <CartesianGrid vertical={false} horizontalPoints={gridLines()} />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey={dataKey}
                  name={areaName}
                  unit="%"
                  fill={color}
                  background={backgroundColor}
                  label={props => (
                    <CustomHorizontalLabel {...props} formatter={(value) => formatPercentageRoundDown(value)}/>
                  )}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    })

    return (
      <div className="chart__body">
        <div className="row">
          <div className="col-9 col-lg-10 offset-3 offset-lg-2">
            <div className="row">
              {chartDataByHousing.map((item, index) => (
                <div className="col-4" key={index}>
                  <p className="text-center small">
                    {chartIcon(item.type)}
                    <br />
                    {item.type}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-3 col-lg-2">
            <div className="chart__viz" style={{ height: viz_height }}>
              <ResponsiveContainer>
                <BarChart
                  barCategoryGap={15}
                  className="overflow-visible"
                  data={chartDataByHousing[0].data}
                  margin={{ right: 10, left: -20 }}
                  layout="vertical"
                >
                  <YAxis type="category" dataKey="Area_Name" tickLine={false} width={130} />
                  <XAxis type="number" hide domain={domain} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-9 col-lg-10">
            <div className="row">{charts}</div>
          </div>
        </div>
      </div>
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

const HousingConsumptionSuburb = ({ data, dataNotes, areaName, houseHoldType }) => (
  <ChartWrapper
    name={`housing-consumption-suburb`}
    title={`Does the housing consumption pattern for ${houseHoldType.name.toLowerCase()} households differ by area?`}
    subTitle={`Percent of households in dwelling types by household type, 2021`}
    dataSource={Datasource.ABS2021}
    dataNotes={dataNotes}
    body={<Chart data={data} areaName={areaName} houseHoldType={houseHoldType} />}
  />
)

HousingConsumptionSuburb.propTypes = {
  data: PropTypes.array.isRequired,
  areaName: PropTypes.string.isRequired,
  dataNotes: PropTypes.object.isRequired,
  houseHoldType: PropTypes.object.isRequired,
}

export default HousingConsumptionSuburb
