import React from 'react'
import { FeatureGroup, GeoJSON, Tooltip } from 'react-leaflet'

const RenderTooltips = ({ tooltipContent }) => {
  return (
    <div>
      {tooltipContent.map((x, index) =>
        x.isBold ? (
          <div key={'tooltip-' + index}>
            <strong>{x.content}</strong>
          </div>
        ) : (
          <div key={'tooltip-' + index}>{x.content}</div>
        )
      )}
    </div>
  )
}

export default function CustomFeatureGroup({
  standardMapData,
  mapData,
  lgaGeoData,
  styleFeature,
  handleEventsOnEachFeature,
  handleOnAdd,
  handleOnRemove,
  tooltipContent,
  selectedArea,
}) {
  return (
    <FeatureGroup>
      <GeoJSON
        key={standardMapData}
        onAdd={handleOnAdd}
        onRemove={handleOnRemove}
        data={mapData}
        style={styleFeature}
        onEachFeature={handleEventsOnEachFeature}
      >
        {selectedArea != null && (
          <Tooltip sticky>
            <RenderTooltips tooltipContent={tooltipContent} />
          </Tooltip>
        )}
      </GeoJSON>
      <GeoJSON
        interactive={false}
        data={lgaGeoData}
        style={{ color: 'white', fillOpacity: 0, weight: 3 }}
      />
    </FeatureGroup>
  )
}
