import React, { useState, useEffect } from 'react'
import { formatThematicData } from '../../functions/thematicMapHelpers'
import '../maps/style.scss'
import { Button } from 'reactstrap'
import {orderBy} from 'lodash'
import { PropTypes } from 'prop-types'
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'


const ThematicTable = ({
    dataConfiguration,
    data,
    dicData,
    propertyToMap,
    scale,
    selectedArea,
    dataDisplayType,
    selectedAreaHandle, 
    totalRow
}) => {
    const { defaultSorting, tableTemplate, dataMappingField, numberOfRows, dataSuppressRule, isTableIsCollapsed } = dataConfiguration
    const [sorting, setSorting] = useState(defaultSorting)
    const [tableIsCollapsed, setTableIsCollapsed] = useState(isTableIsCollapsed)
    const [sortableData, setSortableData] = useState(orderBy(data, sorting.columnName))
   
    useEffect(() => {

        setSortableData(orderBy(data, sorting.columnName, sorting.direction))

    }, [data])

    useEffect(() => {

        setSortableData(orderBy(sortableData, sorting.columnName, sorting.direction))

    }, [sorting])

    useEffect(() => {

        if (sorting.columnName != tableTemplate.main.value && tableTemplate.extra.filter(x => x.value == sorting.columnName).length == 0) {

            if (sorting.columnName.toUpperCase().indexOf('PER') > 0)
                setSorting({ columnName: propertyToMap.percentage, direction: sorting.direction })
            else
                setSorting({ columnName: propertyToMap.number, direction: sorting.direction })
        }

    }, [propertyToMap])


    const NewColorChip = props => {
        const color = scale(props.feature[dataDisplayType ? propertyToMap.number : propertyToMap.percentage])
        return (
            <span
                className="map-table__color-chip"
                style={{ borderColor: color }}
            ></span>
        )
    }

    const handleSortingByColumn = (columnName) => {

        let newDirection = 'asc'

        if (sorting.columnName == columnName)
            newDirection = sorting.direction === 'asc' ? 'desc' : 'asc'

        setSorting({ direction: newDirection, columnName: columnName })
    }       

    const RenderHeader = ({ header, mapping }) => {
        let direction = <></>

        if (mapping == sorting.columnName) {
            direction = sorting.direction == 'asc' ? <TiArrowSortedUp className="thematic-table-sorting-icon__active" /> : <TiArrowSortedDown className="thematic-table-sorting-icon__active" />
        }
        else
            direction = <TiArrowUnsorted className="thematic-table-sorting-icon" />

        const words = header.label.split(' ')
        return (
            <span>
                <Button color="link" onClick={() => {handleSortingByColumn(mapping)}}>
                    <span style={{whiteSpace: "nowrap"}}>{direction}{words[0]}</span>
                    { words.slice(1).map((label) => (
                        <span color="link" key={label} onClick={() => {handleSortingByColumn(mapping)}}>{' '}{label}</span>
                    ))}
                </Button>
            </span>)
    }

    const checkSuppression = (feature, propertyName) => {

        const { checkingColumName, threshold, isApplicable } = dataSuppressRule

        const applySuppresion = isApplicable && (propertyName == propertyToMap.number || propertyName == propertyToMap.percentage) && (feature[propertyToMap.number] == 0 && feature[checkingColumName] < threshold)

        if (applySuppresion) return null

        return feature[propertyName]
    }

    const toggleTable = () => {
        setTableIsCollapsed(!tableIsCollapsed)
    }

    const rowSelectionHandle = (feature) => {

        const matchedFeature = dicData[feature[dataMappingField.geoId]]

        if (typeof matchedFeature == undefined) return

        selectedAreaHandle(matchedFeature)
    }

    return (
        <>
            <div className="map-table__interactive">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th colSpan="2" >
                                <RenderHeader header={tableTemplate.main} mapping={tableTemplate.main.value} />
                            </th>
                            {tableTemplate.default.map((item, index) => (
                                <th className="text-end thematic-table-heading" key={item.value + index} >
                                    <RenderHeader header={item} mapping={item.value == 'number' ? propertyToMap.number : propertyToMap.percentage} />
                                </th>
                            ))}
                            {tableTemplate.extra.map((item, index) => (
                                <th className="text-end thematic-table-heading" key={item.value + index}>
                                    <RenderHeader header={item} mapping={item.value} />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        { totalRow &&
                            <tr key={"row-total"} className="thematic-table-total-row">
                                <td></td>
                                <td className="thematic-table-row">
                                    <strong>{totalRow.label.toUpperCase()}</strong>
                                </td>
                                {tableTemplate.default.map((item) => (
                                    <td className="text-end thematic-table-row" key={totalRow.label + '-' + item.value}>
                                        {
                                            item.value == 'Total_Value'?
                                                formatThematicData(totalRow.default[item.value], item.type)
                                            :
                                                formatThematicData(totalRow.default[propertyToMap[item.value]], item.type)
                                        }
                                    </td>
                                ))}

                                {tableTemplate.extra.map((item) => (
                                    <td className="text-end thematic-table-row" key={totalRow.label + '-' + item.value} >
                                        <span>{formatThematicData(totalRow.extra[item.value], item.type)}</span>
                                    </td>
                                ))}
                            </tr>
                        }   
                        {sortableData.map((feature, index) => (index < numberOfRows || !tableIsCollapsed) && (
                            <tr key={"row-" + index} className={(selectedArea != null) && (feature[dataMappingField.geoId] == selectedArea.properties[dataMappingField.geoId]) ? 'thematic-table-row-active' : ''} onMouseEnter={() => rowSelectionHandle(feature)}
                                onMouseLeave={() => selectedAreaHandle(null)} >
                                <td >
                                    <NewColorChip feature={feature} /> </td>
                                <td className="thematic-table-row">
                                    <strong>{feature[tableTemplate.main.value].toString().toUpperCase()}</strong>
                                </td>
                                {tableTemplate.default.map((item) => (
                                    <td className="text-end thematic-table-row" key={feature[dataMappingField.geoId] + '-' + item.value}>
                                        {
                                            item.value == 'Total_Value'?
                                                formatThematicData(feature[item.value], item.type)
                                                :
                                                formatThematicData(checkSuppression(feature, propertyToMap[item.value]), item.type)
                                          }
                                        
                                        
                                    </td>
                                ))}

                                {tableTemplate.extra.map((item) => (
                                    <td className="text-end thematic-table-row" key={feature[dataMappingField.geoId] + '-' + item.value} >
                                        <span>{formatThematicData(feature[item.value], item.type)}</span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td ></td>
                            <td ><strong>Total areas: {sortableData.length}</strong></td>
                            <td colSpan={tableTemplate.extra.length + 2} className="text-end"></td>
                        </tr>
                    </tbody>
                </table>
                {(sortableData.length > numberOfRows) &&
                    (<div className="table-display-area">
                        <Button
                            outline
                            active={!tableIsCollapsed}
                            onClick={() => toggleTable()}
                        >
                            {tableIsCollapsed ? "Show all" : "Show less"}
                        </Button>
                    </div>)}
            </div>
        </>
    )
}

ThematicTable.propTypes = {
    scale: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    propertyToMap: PropTypes.object.isRequired,
    selectedArea: PropTypes.object,
    dataConfiguration: PropTypes.object.isRequired,
    dataDisplayType: PropTypes.bool.isRequired,
}


export default ThematicTable
