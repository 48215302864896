import Moment from 'moment'

const compareEndValueToPastvalue = (endValue, pastValue) => {
    if (pastValue === null || endValue === null) return null
    return endValue - pastValue
}


const getValidPeriods = (inputPricePoints, inputAffordableData) => {
    let validPeriods = []
    inputAffordableData.map(x => {
        const selectedYear = inputPricePoints.Periods.find(p => p.Period_Name.includes(x.Period_Name))
        if (selectedYear != undefined) {
            validPeriods.push(selectedYear.Period_Name)
        }
    })

    validPeriods = validPeriods.sort((a, b) =>
        Moment(b, 'MMM YYYY').diff(Moment(a, 'MMM YYYY'))
    ).map(p => p)

    return validPeriods
}

export { getValidPeriods, compareEndValueToPastvalue }