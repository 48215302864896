import React from 'react'
import { LandcomIconInformation } from './../landcom-icons'
import './index.scss'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ProptrackDisclaimer from '../proptrackDisclaimer/proptrack-disclaimer.js'

class ChartReferences extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.disclaimerToggle = this.disclaimerToggle.bind(this)

    this.state = {
      modal: false,
      disclaimerModal: false
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    })
  }

  disclaimerToggle() {
    this.setState({
      disclaimerModal: !this.state.disclaimerModal      
    })
  }

  render() {
    return (
      <div className="chart-references">
        {this.props.children}
        {this.props.source && (
          <span className="chart-references__source">Source: {this.props.source}. </span>
        )}        

        {this.props.proptrackDisclaimer && (
        <button
          className="chart-references__data-note d-print-none no-style-button"
          ref={this.setPopoverTarget}
          onClick={this.disclaimerToggle}>
            <LandcomIconInformation aria-label="Open chart disclaimer"/>{' '}
            <span className="chart-references__data-note-text">Disclaimer</span>&nbsp;
        </button>
        )}
        
        {this.props.proptrackDisclaimer && (
          <Modal isOpen={this.state.disclaimerModal} toggle={this.disclaimerToggle} className="modal-lg">
            <ModalHeader toggle={this.disclaimerToggle}>Disclaimer</ModalHeader>
            <ModalBody>
              <ProptrackDisclaimer/>
            </ModalBody>
          </Modal>
        )}

        {this.props.dataNotes && (
          <button
            className="chart-references__data-note d-print-none no-style-button"
            ref={this.setPopoverTarget}
            onClick={this.toggle}>
            <LandcomIconInformation aria-label="Open chart data notes"/>{' '}
            <span className="chart-references__data-note-text">Data notes</span>
          </button>
        )}
        {this.props.dataNotes && (
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
            <ModalHeader toggle={this.toggle}>Data notes</ModalHeader>
            <ModalBody>
              <div dangerouslySetInnerHTML={{__html:this.props.dataNotes.html}} />
            </ModalBody>
          </Modal>
        )}

        {this.props.chartNotes && (
          <div>
            <span className="chart-reference__chart-note-symbol">* </span>
            <span className="chart-references__chart-note">{this.props.chartNotes}. </span>
          </div>
        )}

      </div>
    )
  }
}

export default ChartReferences
