import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { map, split } from 'lodash'
import './styles.scss'
import { Collapse } from 'reactstrap'

const SideBarItem = ({ area, productLinks, item }) => {
  const anchorSlug = typeof window !== 'undefined' ? split(window.location.href, '#')[1] : null
  const pathname = typeof window !== 'undefined' ? window.location.pathname : null

  const [isOpen, setIsOpen] = React.useState(
    typeof window !== 'undefined' ? window.location.href.includes(item.slug) : null
  )
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <button onClick={toggleOpen} className={`Collapsible__trigger ${isOpen ? 'is-open' : ''}`}>
        <div>{item.title}</div>
      </button>
      <Collapse isOpen={isOpen}>
        <ul className="Collapsible__sublink__list">
          {map(
            item.sublinks,
            link =>
              (link.showIfClientSubscribesTo == undefined ||
                productLinks.find(p => p.SubdomainName == link.showIfClientSubscribesTo) !=
                  undefined) && (
                <li className="Collapsible__sublink__list__item" key={link.uri}>
                  <Link
                    className={
                      `${pathname}#${anchorSlug}` === `/${area}/${item.slug}#${link.uri}`
                        ? 'current'
                        : ''
                    }
                    to={`/${area}/${item.slug}#${link.uri}`}
                  >
                    {link.name}
                  </Link>
                </li>
              )
          )}
        </ul>
      </Collapse>
    </div>
  )
}

const SideBar = ({ area, productLinks, id }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          topics {
            slug
            title
            sublinks {
              uri
              name
            }
          }
        }
      }
    }
  `)  

  let linksList = data.site.siteMetadata.topics
  if (area === "fnqroc" || area === "g21-region")    
    linksList = linksList.slice(0,-1)   

  const listItems = linksList.map((item, index) => {
    return <SideBarItem key={index} item={item} area={area} productLinks={productLinks} />
  })

  return (
    <nav className="sidebar" id={id}>
      <div className="container-fluid">
        <Link className="home__link" to={`/${area}`}>
          Home
        </Link>
        {listItems}
      </div>
     </nav>
  )
}

export default SideBar
