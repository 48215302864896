import { AffordableHousing } from './affordable_housing'
import { HouseholdType } from './household_type'
import { HousingSales } from './housing_sales'
import { Population } from './population'
import { Rentals } from './rentals'

const getIcon = iconName => {
  switch (iconName) {
    case 'AffordableHousing':
      return AffordableHousing
    case 'HouseholdType':
      return HouseholdType
    case 'HousingSales':
      return HousingSales
    case 'Population':
      return Population
    case 'Rentals':
      return Rentals
    default:
      return AffordableHousing
  }
}

const Icon = ({ name }) => getIcon(name)

export { Icon }
