import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import HousingIllustration from '../img/404-illustration.svg'

const NotFoundPage = () => (
  <Layout pageTitle={`Page not found`}>
    <PageHeader className="home" background={HousingIllustration}>
      <h1 className="display-1 mb-5">404 error</h1>
      <h2>Nobody lives at this address yet</h2>
      <p className="lead">Is there something that you're looking for?</p>
      <p><a href="/" className="btn btn-light me-2">Back to home</a> <a href="https://home.id.com.au/services/demographic-information-tools/local-government-online-tools/housing-id/" className="btn btn-light">Learn about housing.id</a></p>
    </PageHeader>
  </Layout>
)

export default NotFoundPage
