import React from 'react'
import './style.scss'
import communityLogo from '../../img/community-logo.svg'

const CommunityHeader = () => (
  <div className="community-header">
    <div className="container">
      <div className="community-header__logo">
        <a href="http://home.id.com.au/demographic-resources/">
          <img src={communityLogo} alt=".id community demographic resources" aria-label="i d"/>
          <span className="visually-hidden">Find more community demographic resources</span>
        </a>
      </div>
    </div>
  </div>
)

export default CommunityHeader
