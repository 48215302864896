import React from 'react'

const ErrorMessageChart = (error) => {
  console.log(error)
  return (
    <div className="alert alert-danger" role="alert">
      <strong>Data error</strong>. The chart can't shown because something went
      wrong getting the data
    </div>
  )
}

export { ErrorMessageChart }
