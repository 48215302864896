import React from 'react'
import { IdIconChevronDown, IdIconChevronUp } from '../idIcon'
import { Collapse } from 'reactstrap'
import './index.scss'

const SectionUpfrontText = ({ title, type = '', children, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div {...props} className={`${type}-title collapsible-text ${isOpen ? 'collapsible-text--is-open' : ''}`}>
      <button onClick={toggleOpen}>
        <div className="collapsible-text__main" >
          <div className="collapsible-text__title">{title}</div>
          <div className="collapsible-text__action-icon">
            {isOpen ? <IdIconChevronUp aria-label="hello" /> : <IdIconChevronDown />}
          </div>
        </div>
      </button>
      <Collapse isOpen={isOpen} className="collapsible-text__body">
        {children}
      </Collapse>
    </div>
  )
}

export default SectionUpfrontText
