import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { isEmpty } from 'lodash'
import numeral from 'numeral'
import ChartWrapper from './../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import { formatNumber } from '../../functions/formatNumber'

const HousingStressStackedChart = ({ data, dataNotes }) => {
  if (isEmpty(data)) {
    return <ErrorMessageChart error="No data for chart" />
  }
  try {
    const housingStress = data.housingStress
    const totalRenting = data.totalRenting
    const totalMortgage = data.totalMortgage

    const chartData = [
      {
        group: 'Renting',
        In: housingStress.Total_Rental_Stress_No,
        Not_In: totalRenting - housingStress.Total_Rental_Stress_No,
      },
      {
        group: 'Mortgage',
        In: housingStress.Total_Mortgage_Stress_No,
        Not_In: totalMortgage - housingStress.Total_Mortgage_Stress_No,
      },
    ]

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {numeral(item.value).format('0,0')}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    return (
      <ChartWrapper
        name={`housing-stress-stacked`}
        title={`What is the dominant type of housing stress?`}
        subTitle={`Households in housing stress`}
        dataSource={Datasource.ABS2021}
        dataNotes={dataNotes}
        body={
          <div className="chart__viz" style={{ width: '100%', height: 320 }}>
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <XAxis dataKey="group" tickLine={false} />
                <YAxis tickLine={false} tickFormatter={value => numeral(value).format('0,0')} />
                <CartesianGrid vertical={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="top"
                  align="left"
                  height={50}
                  formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                />
                <Bar
                  dataKey="Not_In"
                  name="Not in housing stress"
                  fill={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                  }
                  stackId="a"
                >
                  <LabelList
                    dataKey="Not_In"
                    position="inside"
                    fill="white"
                    formatter={value => formatNumber(value)}
                  />
                </Bar>
                <Bar
                  dataKey="In"
                  name="In housing stress"
                  fill={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-primary')
                  }
                  stackId="a"
                >
                  <LabelList
                    dataKey="In"
                    position="inside"
                    fill="white"
                    formatter={value => formatNumber(value)}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default HousingStressStackedChart
