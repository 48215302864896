import React from 'react'
import {disclaimer} from './index.module.scss'

const Disclaimer = () => (
  <div className={disclaimer}>
    <p>
      DISCLAIMER: While all due care has been taken to ensure that the content
      of this website is accurate and current, there may be errors or omissions
      in it and no legal responsibility is accepted for the information and
      opinions in this website.
    </p>
    <p>
      Please view our{' '}
      <a href="https://home.id.com.au/privacy-policy/">Privacy Policy</a>,{' '}
      <a href="https://home.id.com.au/terms-of-use/">Terms of use</a> and{' '}
      <a href="https://home.id.com.au/legal-notices/">Legal notices</a>.
    </p>
    <p>
      ABS Data and the copyright in the ABS Data remains the property of the
      Australian Bureau of Statistics. The copyright in the way .id has
      modified, transformed or reconfigured the ABS Data as published on this
      website remains the property of .id. ABS Data can be accessed from the
      Australian Bureau of Statistics at{' '}
      <a href="http://www.abs.gov.au" title="Australian Bureau of Statistics">
        www.abs.gov.au
      </a>
      . ABS data can be used under license - terms published on ABS website.{' '}
      <a href="mailto:intermediary.management@abs.gov.au">
        intermediary.management@abs.gov.au
      </a>{' '}
      if you have any queries or wish to distribute any ABS data.
    </p>{' '}
  </div>
)

export default Disclaimer
