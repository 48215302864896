import React from 'react'
import * as Datasource from '../../config/text-constants'
import AffordableSalesRentalMap from './affordableSalesRentalMap'
import { PropTypes } from 'prop-types'

const dataConfiguration = {
  dataMappingField: { geoId: 'Area_Id', dataId: 'Area_Id' },
  dataSuppressRule: { isApplicable: false, checkingColumName: 'Total_Value', threshold: 10 },
  geoDataType: 'custom_areas',
  showThematicTable: true,
  isTableIsCollapsed: true,
  numberOfRows: 5,
  defaultSorting: { columnName: 'Area_Name', direction: 'asc' },
  defaultDataDisplayOption: 1,
  defaultDataDisplayType: 1,
  dataDisplayOptions: [
    {
      label: `Very low income `,
      short_label: `Very low`,
      number: `Very_Low`,
      percentage: `Very_Low_Percent`,
    },
    {
      label: `Low income`,
      short_label: `Low`,
      number: `Low`,
      percentage: `Low_Percent`,
    },
    {
      label: `Moderate income`,
      short_label: `Moderate`,
      number: `Moderate`,
      percentage: `Moderate_Percent`,
    },
  ],

  dataDisplayTypes: [
    {
      label: `Number`,
      value: 'number',
    },
    {
      label: `%`,
      value: 'percentage',
    },
  ],
  tableTemplate: {
    main: {
      label: `Area`,
      value: 'Area_Name',
    },
    default: [
      {
        label: `Total rentals`,
        value: 'Total_Value',
        type: 'number',
      },
      {
        label: `Affordable rentals`,
        value: 'number',
        type: 'number',
      },
      {
        label: `% affordable`,
        value: 'percentage',
        type: 'percentage',
      },
    ],
    extra: [
      {
        value: `Median_House`,
        label: `Median house ($/week)`,
        type: 'currency',
      },
      {
        value: `Median_Unit`,
        label: `Median unit ($/week)`,
        type: 'currency',
      },
    ],
  },
  tooltipTemplate: {
    tooltipLiterals: function(feature, number, percentage) {
      return [
        { isBold: true, content: `${feature.properties['Area_Name'].toUpperCase()}` },
        { isBold: false, content: `Affordable rentals: ${number} (${percentage})` },
      ]
    },
    extra: [
      {
        value: `Total_Value`,
        label: `Total rentals`,
        type: 'number',
      },
      {
        value: `Median_House`,
        label: `Median house`,
        type: 'currency',
      },
      {
        value: `Median_Unit`,
        label: `Median unit`,
        type: 'currency',
      },
    ],
  },
}

const AffordableRentalMap = ({ data, dataNotes, pageContext, extraOverlayData }) => {
  return (
    <AffordableSalesRentalMap
      name={'property-rental-map'}
      title={`How affordable are rental properties?`}
      subTitle={`Affordable property rental`}
      dataSource={Datasource.PropTrackLongVersion}
      dataConfiguration={dataConfiguration}
      data={data}
      dataNotes={dataNotes}
      proptrackDisclaimer={true}
      pageContext={pageContext}
      extraOverlayData={extraOverlayData}
    />
  )
}

AffordableRentalMap.propTypes = {
  data: PropTypes.array.isRequired,
  dataNotes: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
}

export default AffordableRentalMap
