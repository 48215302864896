import React from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import numeral from 'numeral'
import { isEmpty } from 'lodash'
import ChartWrapper from './../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

const IncomeChangeChart = props => {
  try {
    if (isEmpty(props.data)) {
      return <ErrorMessageChart error="No data for chart"  />
    }
    const chartData = [
      {
        year: '2006',
        value: props.data.MedHHI_06,
        value_bm: props.benchmarkData.MedHHI_06,
      },
      {
        year: '2011',
        value: props.data.MedHHI_11,
        value_bm: props.benchmarkData.MedHHI_11,
      },
      {
        year: '2016',
        value: props.data.MedHHI_16,
        value_bm: props.benchmarkData.MedHHI_16,
      },
      {
        year: '2021',
        value: props.data.MedHHI_21,
        value_bm: props.benchmarkData.MedHHI_21,
      },
    ]

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {numeral(item.value).format('$0,0')}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    return (
      <ChartWrapper
        name={`median-income-change`}
        title={`How has household income changed?`}
        subTitle={`Median weekly household income`}
        dataSource={Datasource.ABS2006}
        dataNotes={props.dataNotes}
        chartInfo={props.chartInfo}
        body={
          <div>
            <div className="row">
              <div className="col-12">
                <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                  <ResponsiveContainer>
                    <LineChart data={chartData} margin={{ right: 30 }}>
                      <XAxis dataKey="year" tickLine={false} />
                      <YAxis
                        tickLine={false}
                        tickFormatter={value => numeral(value).format('$0,0')}
                      />
                      <CartesianGrid vertical={false} />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend
                        verticalAlign="top"
                        align="left"
                        height={50}
                        formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                      />
                      <Line
                        dataKey="value"
                        name={props.areaName}
                        stroke={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-primary')
                        }
                        strokeWidth={2}
                      ></Line>
                      <Line
                        dataKey="value_bm"
                        name={props.benchmarkName}
                        stroke={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                        strokeWidth={2}
                      ></Line>
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default IncomeChangeChart
