import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { isEmpty } from 'lodash'
import numeral from 'numeral'
import ChartWrapper from './../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

class AgeChangeChart extends React.Component {
  render() {
    if (isEmpty(this.props.data)) {
      return <ErrorMessageChart error="No data for chart" />
    }

    try {
      const chartData = [
        {
          name: '0-4',
          number: this.props.data.Change_0to4,
        },
        {
          name: '5-9',
          number: this.props.data.Change_5to9,
        },
        {
          name: '10-14',
          number: this.props.data.Change_10to14,
        },
        {
          name: '15-19',
          number: this.props.data.Change_15to19,
        },
        {
          name: '20-24',
          number: this.props.data.Change_20to24,
        },
        {
          name: '25-29',
          number: this.props.data.Change_25to29,
        },
        {
          name: '30-34',
          number: this.props.data.Change_30to34,
        },
        {
          name: '35-39',
          number: this.props.data.Change_35to39,
        },
        {
          name: '40-44',
          number: this.props.data.Change_40to44,
        },
        {
          name: '45-49',
          number: this.props.data.Change_45to49,
        },
        {
          name: '50-54',
          number: this.props.data.Change_50to54,
        },
        {
          name: '55-59',
          number: this.props.data.Change_55to59,
        },
        {
          name: '60-64',
          number: this.props.data.Change_60to64,
        },
        {
          name: '65-69',
          number: this.props.data.Change_65to69,
        },
        {
          name: '70-74',
          number: this.props.data.Change_70to74,
        },
        {
          name: '75-79',
          number: this.props.data.Change_75to79,
        },
        {
          name: '80-84',
          number: this.props.data.Change_80to84,
        },
        {
          name: '85+',
          number: this.props.data.Change_85plus,
        },
      ]

      const numberFormatter = value => numeral(value).format('0,0')

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>Age: {label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}: {numeral(item.value).format('+0, 0')}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`5-year-age-change-chart`}
          title={`How is the age profile changing?`}
          subTitle={`Change in Estimated Resident Population by age, 2018-2023`}
          dataSource={Datasource.ABSRegionalChange}
          dataNotes={this.props.dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div className="chart__viz" style={{ width: '100%', height: 640 }}>
              <ResponsiveContainer>
                <BarChart data={chartData} barGap={0}>
                  <YAxis tickLine={false} type="number" tickFormatter={numberFormatter} />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    type="category"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                  />
                  <CartesianGrid vertical={false} horizontal={true} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    verticalAlign="top"
                    align="left"
                    height={50}
                    formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                  />
                  <Bar dataKey="number" name={this.props.areaName} fill={this.props.color} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default AgeChangeChart
