import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  isEmpty,
  map,
  head,
  nth,
  slice,
  find,
  flatMap,
  flatMapDeep,
  flow,
  filter,
  min,
  max,
} from 'lodash'
import CustomLegend from '../../components/legend/index.js'
import { ErrorMessageChart } from '../../components/error-message-chart'
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import numeral from 'numeral'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from './../chartWrapper/index'
import PillSelect from '../pillSelect'
import TimePeriodDropdown from '../timePeriodDropdown'
import Moment from 'moment'
import { Button, ButtonGroup } from 'reactstrap'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { getEntryLevelLabel } from '../../functions/getLevelLabels'

const priceFormatterLong = value => numeral(value).format('$0,0')

const GenericPricePointsChart = ({
  alias,
  name,
  dataBm,
  areaName,
  benchmarkName,
  title,
  dataSource,
  dataNotes,
  data,
  type,
  proptrackDisclaimer,
}) => {
  const dataTemplate = [
    {
      name: 'House',
      categories: [
        {
          name: getEntryLevelLabel(alias),
          value_key: 'Entry_House',
        },
        {
          name: 'Median',
          value_key: 'Median_House',
        },
        {
          name: 'Upper level',
          value_key: 'Upper_House',
        },
      ],
    },
    {
      name: 'Unit',
      categories: [
        {
          name: getEntryLevelLabel(alias),
          value_key: 'Entry_Unit',
        },
        {
          name: 'Median',
          value_key: 'Median_Unit',
        },
        {
          name: 'Upper level',
          value_key: 'Upper_Unit',
        },
      ],
    },
  ]

  if (isEmpty(data)) {
    ;<ErrorMessageChart error="No data for chart" />
  }

  const dataPeriods = map(data, node => node.Period_Name).sort((a, b) =>
    Moment(b, 'MMM YYYY').diff(Moment(a, 'MMM YYYY'))
  )

  const comparisonPeriods = slice(dataPeriods, 1)
  const [dataType, setDataType] = useState('number')
  const [activePeriod, setActiveperiod] = useState(head(dataPeriods))
  const [activeChangeStartPeriod, setActiveChangeStartPeriod] = useState(nth(dataPeriods, 1))

  const activeChangeEndPeriod = head(dataPeriods)

  const changeToNumber = () => setDataType('number')
  const changeToChange = () => setDataType('change')

  const handleActivePeriodDropdownOnChange = event => {
    setActiveperiod(event.target.value)
  }

  const handleComparisonPeriodDropdownOnChange = event => {
    setActiveChangeStartPeriod(event.target.value)
  }

  const getNodeByPeriod = (edges, period) => {
    const edge = find(edges, node => node.Period_Name === period)

    return edge
  }

  const compareEndValueToPastvalue = (endValue, pastValue) => {
    if (pastValue === null || endValue === null) return null
    return endValue - pastValue
  }

  const getChartDataUsingTemplate = (template, data) => {
    const currentPeriodNode = getNodeByPeriod(data, activePeriod)
    const currentPeriodBmNode = getNodeByPeriod(dataBm, activePeriod)
    const activeChangeStartPeriodNode =
      dataPeriods.length > 1 ? getNodeByPeriod(data, activeChangeStartPeriod) : null
    const activeChangeStartPeriodBmNode =
      dataPeriods.length > 1 ? getNodeByPeriod(dataBm, activeChangeStartPeriod) : null
    const activeChangeEndPeriodNode =
      dataPeriods.length > 1 ? getNodeByPeriod(data, activeChangeEndPeriod) : null
    const activeChangeEndPeriodBmNode =
      dataPeriods.length > 1 ? getNodeByPeriod(dataBm, activeChangeEndPeriod) : null
    var returnedData = {}

    template.forEach(group => {
      const categories = group.categories.map(category => {
        return {
          group: category.name,
          value: currentPeriodNode[category.value_key],
          value_bm: currentPeriodBmNode && currentPeriodBmNode[category.value_key],
          change: activeChangeStartPeriodNode
            ? compareEndValueToPastvalue(
                activeChangeEndPeriodNode[category.value_key],
                activeChangeStartPeriodNode[category.value_key]
              )
            : null,
          changeBm: activeChangeStartPeriodBmNode
            ? compareEndValueToPastvalue(
                activeChangeEndPeriodBmNode[category.value_key],
                activeChangeStartPeriodBmNode[category.value_key]
              )
            : null,
        }
      })

      returnedData[group.name] = {
        title: group.name,
        data: categories,
      }
    })
    return returnedData
  }

  const chartData = getChartDataUsingTemplate(dataTemplate, data)

  const NumberSubtitle = () => (
    <>
      {type === 'sales' ? 'Housing prices' : 'Weekly rental listing prices'} by price point for
      quarter ending{' '}
      {dataPeriods.length > 1 && (
        <TimePeriodDropdown
          onChange={handleActivePeriodDropdownOnChange}
          periods={dataPeriods}
          currentPeriod={activePeriod}
        />
      )}
      {dataPeriods.length === 1 && (
        <PillSelect>{Moment(activePeriod, 'MMM YYYY').format('MMM YYYY')}</PillSelect>
      )}
    </>
  )

  const ChangeSubtitle = () => (
    <>
      {type === 'sales' ? 'Change in housing prices' : 'Change in weekly rental listing prices'} by
      price point over the two 3-month periods ending{' '}
      {dataPeriods.length > 2 && (
        <TimePeriodDropdown
          onChange={handleComparisonPeriodDropdownOnChange}
          periods={comparisonPeriods}
          currentPeriod={activeChangeStartPeriod}
        />
      )}
      {dataPeriods.length === 2 && (
        <PillSelect>{Moment(activeChangeStartPeriod, 'MMM YYYY').format('MMM YYYY')}</PillSelect>
      )}{' '}
      and <PillSelect>{Moment(activeChangeEndPeriod, 'MMM YYYY').format('MMM YYYY')}</PillSelect>
    </>
  )

  const SubTitle = () => (
    <>
      {dataType === 'change' && <ChangeSubtitle />}

      {dataType === 'number' && <NumberSubtitle />}
    </>
  )

  try {
    dataSource = dataSource || Datasource.Proptrack

    const maxValue = flow([
      flatMapDeep,
      x =>
        flatMap(x, item =>
          dataType === 'number' ? [item.value, item.value_bm] : [item.change, item.changeBm]
        ),
      max,
    ])([chartData.House.data, chartData.Unit.data])

    const minValue = flow([
      flatMapDeep,
      x =>
        flatMap(x, item =>
          dataType === 'number' ? [item.value, item.value_bm] : [item.change, item.changeBm]
        ),
      min,
    ])([chartData.House.data, chartData.Unit.data])

    const domain = [dataType === 'change' ? minValue : 0, maxValue < 0 ? 0 : maxValue]

    const ticks = scaleLinear()
      .domain(domain)
      .nice(5)
      .ticks(5)

    let customLegendItems = [
      {
        label: areaName,
        color:
          typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-primary'),
      },
    ]

    const hasBenchmark = typeof dataBm != undefined && dataBm.length > 0

    if (hasBenchmark) {
      customLegendItems.push({
        label: benchmarkName,
        color:
          typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-gray-500'),
      })
    }

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {priceFormatterLong(item.value)}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    const charts = [chartData.House, chartData.Unit].map((chartData, index) => {
      const anyNullDataPoints =
        filter(chartData.data, item => item.value === undefined || item.value === null).length > 0
      const anyNullBMDataPoints =
        filter(chartData.data, item => item.value_bm === undefined || item.value === null).length >
        0

      return (
        <div className="col-md-6" key={index}>
          <div className="chart__viz" style={{ width: '100%', height: 250 }}>
            {anyNullDataPoints && (
              <div className="chart__overlay-message">
                <p>
                  There were insufficient {chartData.title.toLowerCase()} {type} in this LGA to
                  provide data for this chart
                </p>
              </div>
            )}
            <ResponsiveContainer>
              <BarChart data={chartData.data} margin={{ top: 20 }}>
                <XAxis dataKey="group" tickLine={false} />
                <YAxis
                  tickLine={false}
                  ticks={ticks}
                  interval={0}
                  tickCount={ticks.count + 1}
                  type="number"
                  scale="linear"
                  tickFormatter={priceFormatterLong}
                />
                <CartesianGrid vertical={false} />
                <Tooltip content={<CustomTooltip />} />
                {!anyNullDataPoints && dataType === 'number' && (
                  <Bar
                    dataKey="value"
                    name={areaName}
                    fill={
                      typeof window !== `undefined` &&
                      getComputedStyle(document.body).getPropertyValue('--bs-primary')
                    }
                    label={<CustomVerticalLabel formatter={priceFormatterLong} />}
                  />
                )}
                {!anyNullBMDataPoints && dataType === 'number' && hasBenchmark && (
                  <Bar
                    dataKey="value_bm"
                    name={benchmarkName}
                    fill={
                      typeof window !== `undefined` &&
                      getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                    }
                    label={<CustomVerticalLabel formatter={priceFormatterLong} />}
                  />
                )}

                {!anyNullDataPoints && dataType === 'change' && (
                  <Bar
                    dataKey="change"
                    name={areaName}
                    fill={
                      typeof window !== `undefined` &&
                      getComputedStyle(document.body).getPropertyValue('--bs-primary')
                    }
                    label={<CustomVerticalLabel formatter={priceFormatterLong} />}
                  />
                )}
                {!anyNullDataPoints && dataType === 'change' && hasBenchmark && (
                  <Bar
                    dataKey="changeBm"
                    name={benchmarkName}
                    fill={
                      typeof window !== `undefined` &&
                      getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                    }
                    label={<CustomVerticalLabel formatter={priceFormatterLong} />}
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          </div>
          <p className="text-center m-md-0">
            <strong>{chartData.title}</strong>
          </p>
        </div>
      )
    })

    return (
      <ChartWrapper
        name={name}
        title={title}
        subTitle={<SubTitle />}
        dataSource={dataSource}
        dataNotes={dataNotes}
        proptrackDisclaimer={proptrackDisclaimer}
        body={
          <>
            {dataPeriods.length > 1 && (
              <div className="chart__controls form-inline d-print-none mb-2">
                <ButtonGroup size="sm" className="d-print-none  mb-2 me-2">
                  <Button outline onClick={changeToNumber} active={dataType === 'number'}>
                    Number
                  </Button>
                  {dataPeriods.length > 1 && (
                    <Button outline onClick={changeToChange} active={dataType === 'change'}>
                      Change
                    </Button>
                  )}
                </ButtonGroup>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <CustomLegend items={customLegendItems} />
              </div>
              {charts}
            </div>
          </>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

GenericPricePointsChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataBm: PropTypes.array,
  dataNotes: PropTypes.object,
  dataSource: PropTypes.string,
  areaName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  benchmarkName: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  proptrackDisclaimer: PropTypes.bool,
}

GenericPricePointsChart.defaultProps = {
  data: [],
}

export default GenericPricePointsChart
