import { DateTime } from 'luxon'

export function removePropertiesWithValue(data, valuesToRemove) {
  return data.map(obj => {
    const newObj = {}
    for (const [key, value] of Object.entries(obj)) {
      if (!valuesToRemove.includes(value)) {
        newObj[key] = value
      }
    }
    return newObj
  })
}

export function spliceIfAllZero(arr) {
  // Check if the array has at least 4 objects
  if (arr.length < 4) {
    return arr // No need to splice if there are fewer than 4 objects
  }

  const firstFourObjects = arr.slice(0, 4) // Get the first 4 objects

  // Check if all properties are 0 in the first 4 objects
  const allZero = firstFourObjects.every(
    obj =>
      obj.median_percent === -100 &&
      obj.entry_level_percent === -100 &&
      obj.regional_median_percent === -100 &&
      obj.regional_entry_level_percent === -100
  )

  if (allZero) {
    // If all properties are 0, splice these objects
    arr.splice(0, 4)
  }

  return arr
}

export const calculate5YearAnnualAverageChange = (dataKeyType, data) => {
  // Extract the end year data
  const endYearData = data[data.length - 1]

  // Calculate the target year
  const year = 5
  const targetYear = DateTime.fromFormat(endYearData.Period_Name, 'MMM yyyy')
    .minus({ years: year })
    .toFormat('MMM yyyy')

  // Find the data for the target year
  const yearData = data.find(p => p.Period_Name === targetYear)

  let startingYearData = {}
  if (yearData) {
    startingYearData = {
      Period_Name: yearData.Period_Name,
      change: null,
      regional_change: null,
      median: yearData.median,
      entry_level: yearData.entry_level,
      regional_median: yearData.regional_median,
      regional_entry_level: yearData.regional_entry_level,
    }

    startingYearData.change =
      100 * (Math.pow(endYearData[dataKeyType] / startingYearData[dataKeyType], 1 / year) - 1)
    startingYearData.regional_change =
      100 *
      (Math.pow(
        endYearData[`regional_${dataKeyType}`] / startingYearData[`regional_${dataKeyType}`],
        1 / year
      ) -
        1)
  }
  return startingYearData
}

export function percentageStringToFloat(percentageString) {
  // Remove the "%" character
  var numberString = percentageString.replace('%', '')

  // Convert the string to a float
  var floatValue = parseFloat(numberString)

  // Check if the conversion was successful
  if (!isNaN(floatValue)) {
    return floatValue
  } else {
    // Return an error value or handle the error as needed
    return null
  }
}

// Iterate through the array and update 0 values to 'NA'
export const updatedChartData = chartData =>
  chartData.map(data => {
    // Check if 'median' and 'entry_level' are 0, and update them to 'NA'
    if (data.median === 0) {
      data.median = 'NA'
    }
    if (data.entry_level === 0) {
      data.entry_level = 'NA'
    }
    // You can add similar checks for other attributes if needed

    return data
  })

// Find the minimum year in the chartDataWithPercentage array
// Extract the year from the 'Period_Name' values
export const extractYearAndMonth = periodName => {
  const match = periodName.match(/(\w{3}) (\d{4})/)
  if (match) {
    const month = match[1] // Three-letter month abbreviation
    const year = parseInt(match[2]) // Four-digit year
    return { month, year }
  }
  return null
}

// A function to calculate the percentage difference between two values
const getPercentageDiff = (current, previous) => {
  if (previous === 0) return -100

  return ((current - previous) / previous) * 100
}

// A function to get the previous year’s quarter data for a given quarter
const getPreviousQuarterData = (quarterData, index) => {
  if (index < 4) return null // No previous year data for the first year
  return quarterData[index - 4]
}

// A function to add the percentage difference data to each quarter object
export const addPercentageData = quarterData => {
  return quarterData.map((quarter, index) => {
    const previousQuarter = getPreviousQuarterData(quarterData, index)

    if (!previousQuarter) {
      // Set all percentage values to zero for the first year
      return {
        ...quarter,
        median_percent: -100,
        entry_level_percent: -100,
        regional_median_percent: -100,
        regional_entry_level_percent: -100,
      }
    } else {
      // Calculate the percentage difference for each value
      return {
        ...quarter,
        median_percent: getPercentageDiff(quarter.median, previousQuarter.median),
        entry_level_percent: getPercentageDiff(
          quarter['entry_level'],
          previousQuarter['entry_level']
        ),
        regional_median_percent: getPercentageDiff(
          quarter['regional_median'],
          previousQuarter['regional_median']
        ),
        regional_entry_level_percent: getPercentageDiff(
          quarter['regional_entry_level'],
          previousQuarter['regional_entry_level']
        ),
      }
    }
  })
}
