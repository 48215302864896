import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { trackEvent } from '../../analytics'
import ChartWrapper from './../chartWrapper/index'
import * as Datasource from '../../config/text-constants'
import ThematicMapCustomAreas from './thematic-map-custom-areas'
import { ErrorMessageChart } from '../error-message-chart'
import { isEmpty } from 'lodash'
import TimePeriodDropdown from '../timePeriodDropdown'

const ApprovalsBySA1Map = ({ data, pageContext, chartInfo, dataNotes }) => {
  if (isEmpty(data)) {
    return <ErrorMessageChart error="No data for chart" />
  }

  const [selectedType, setSelectedType] = useState('Total_Residential') // Initialize with 'Total Residential'

  const filterApprovalsByType = type => {
    setOptionSelected(type)
    setSelectedType(type.label)
  }

  const formatDate = (dateString, format) => {
    const date = DateTime.fromISO(dateString)
    return date.toFormat(format)
  }

  const periods =
    data && data.length > 0
      ? data[0].data.Approvals_Per_Quarter.map(node => formatDate(node.Period, 'MMM yyyy'))
      : []

  const [startPeriod, setStartPeriod] = useState(periods && periods.length > 0 ? periods[0] : null)
  const [endPeriod, setEndPeriod] = useState(
    periods && periods.length > 1 ? periods.slice(-1)[0] : null
  )

  const dataOptions = [
    {
      label: `Total_Residential`,
      short_label: `Total`,
      number: `Approvals`,
    },
    {
      label: `Houses`,
      short_label: `Houses`,
      number: `Approvals`,
    },
    {
      label: `Other_residential`,
      short_label: `Other`,
      number: `Approvals`,
    },
  ]

  const dataConfiguration = {
    dataMappingField: { geoId: 'SA1_MAIN21', dataId: 'Area_Id' },
    dataSuppressRule: { isApplicable: false },
    geoDataType: 'sa1',
    showThematicTable: false,
    dataDisplayOptions: {},
    dataDisplayTypes: {},
    tableTemplate: null,
    tooltipTemplate: {
      tooltipLiterals: function(feature, number, percentage) {
        return [
          {
            isBold: true,
            content: `SA1: ${feature.properties['Area_Id'].toUpperCase()}`,
          },
          { isBold: false, content: `Approvals: ${number}` },
        ]
      },
      extra: [],
    },
  }

  const calculateApprovals = () => {
    return data.map(item => {
      const totalApprovalsForNode = item.data.Approvals_Per_Quarter.reduce(
        (accumulator, currentValue) => {
          const period = DateTime.fromISO(currentValue.Period)

          if (
            (startPeriod === null || period >= DateTime.fromFormat(startPeriod, 'MMM yyyy')) &&
            (endPeriod === null || period <= DateTime.fromFormat(endPeriod, 'MMM yyyy'))
          ) {
            // Check if the type exists in the current data structure
            if (currentValue[selectedType]) {
              return accumulator + currentValue[selectedType]
            } else {
              return accumulator
            }
          } else {
            return accumulator
          }
        },
        0
      )
      return {
        node: {
          Area_Id: item.sa1,
          Approvals: totalApprovalsForNode,
        },
      }
    })
  }
  const [optionSelected, setOptionSelected] = useState(dataOptions[0])

  const [edges, setEdges] = useState(calculateApprovals)

  const startPeriodDropdownChange = event => {
    const selectedValue = event.target.value
    setStartPeriod(selectedValue)

    trackEvent({
      category: 'Approvals Map',
      action: 'Change Start Date',
      label: `${selectedValue}`,
    })
  }

  const endPeriodDropdownChange = event => {
    const selectedValue = event.target.value
    setEndPeriod(selectedValue)

    trackEvent({
      category: 'Approvals Map',
      action: 'Change End Date',
      label: `${selectedValue}`,
    })
  }

  // useEffect to calculate approvals and handle start/end period changes
  useEffect(() => {
    const updatedEdges = calculateApprovals()
    setEdges(updatedEdges)
  }, [selectedType, startPeriod, endPeriod])

  const totalApprovals = edges.reduce((accumulator, { node }) => accumulator + node.Approvals, 0)

  const TimePeriodInTitle = ({ periodType, onChangeFunc }) =>
    periods.length > 1 ? (
      <TimePeriodDropdown onChange={onChangeFunc} periods={periods} currentPeriod={periodType} />
    ) : null

  const SubTitle = () => (
    <span>
      Residential building approvals by SA1,{' quarter ended '}
      <TimePeriodInTitle
        periodType={startPeriod}
        onChangeFunc={startPeriodDropdownChange}
      /> to <TimePeriodInTitle periodType={endPeriod} onChangeFunc={endPeriodDropdownChange} />
      {' for '} {optionSelected.short_label} {' approvals'}
    </span>
  )
  try {
    return (
      <ChartWrapper
        name={`building-approvals-sa1-map`}
        title={`Where are building approvals located?`}
        subTitle={<SubTitle />}
        dataSource={Datasource.MapBuildingApproval}
        dataNotes={dataNotes}
        chartInfo={chartInfo}
        body={
          <div>
            <div className="chart__controls form-inline d-print-none">
              <div className="form-group mb-2">
                {dataOptions.length > 1 && (
                  <ButtonGroup size="sm" className="d-print-none mb-2">
                    {dataOptions.map((option, index) => (
                      <Button
                        outline
                        key={index}
                        active={optionSelected.label === option.label}
                        onClick={() => filterApprovalsByType(option)}
                      >
                        {option.short_label}
                      </Button>
                    ))}
                  </ButtonGroup>
                )}
              </div>
            </div>
            <ThematicMapCustomAreas
              data={edges.map(a => a.node)}
              propertyToMap={optionSelected}
              dataConfiguration={dataConfiguration}
              dataDisplayType={true}
              pageContext={pageContext}
            >
              <div className="map-overlay map-overlay--top-left">
                <span>
                  Total <span className="h5">{numeral(totalApprovals).format('0[,]0')}</span>{' '}
                  approvals
                </span>
              </div>
            </ThematicMapCustomAreas>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default ApprovalsBySA1Map
