import React from 'react'
import { map, isEmpty, max, flow } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Text,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from '../chartWrapper'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import formatPercentage from '../../functions/formatPercentage'
import { formatNumber } from '../../functions/formatNumber'
import { ErrorMessageChart } from '../error-message-chart'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'

class TenureChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    if (isEmpty(this.props.data)) {
      return <ErrorMessageChart error="No data for chart" />
    }
    try {
      const chartData = [
        {
          group: 'Fully owned',
          Total_Per: this.props.data.Per2021_Owned_Outright,
          Total_Num: this.props.data.N2021_Owned_Outright,
          Total_Per_BM: this.props.benchmarkData.Per2021_Owned_Outright,
        },
        {
          group: 'Mortgage',
          Total_Per: this.props.data.Per2021_Owned_With_Mortgage,
          Total_Num: this.props.data.N2021_Owned_With_Mortgage,
          Total_Per_BM: this.props.benchmarkData.Per2021_Owned_With_Mortgage,
        },
        {
          group: 'Renting - private',
          Total_Per: this.props.data.Per2021_Rented_Private,
          Total_Num: this.props.data.N2021_Rented_Private,
          Total_Per_BM: this.props.benchmarkData.Per2021_Rented_Private,
        },
        {
          group: 'Renting - social housing',
          Total_Per: this.props.data.Per2021_Rented_Social,
          Total_Num: this.props.data.N2021_Rented_Social,
          Total_Per_BM: this.props.benchmarkData.Per2021_Rented_Social,
        },
      ]

      const maxValue = flow([
        x =>
          map(x, item =>
            this.state.dataType === 'percent'
              ? max([item.Total_Per, item.Total_Per_BM])
              : item.Total_Num
          ),
        max,
      ])(chartData)

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(5)
        .ticks(5)

      const numberFormatter = value =>
        this.state.dataType === 'percent' ? formatPercentage(value) : formatNumber(value)

      const AxisText = props => {
        const width = props.width / chartData.length
        return (
          <Text {...props} width={width}>
            {props.payload.value}
          </Text>
        )
      }

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}:{' '}
                  {this.state.dataType === 'percent'
                    ? formatPercentage(item.value)
                    : formatNumber(item.value)}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`tenure-types-chart`}
          title={`What are the dominant tenure types?`}
          subTitle={`Households by tenure, 2021`}
          dataSource={Datasource.ABS2021}
          dataNotes={this.props.dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis dataKey="group" tickLine={false} tick={<AxisText />} interval={0} />
                    <YAxis
                      tickLine={false}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.count + 1}
                      tickFormatter={numberFormatter}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Bar
                      dataKey={this.state.dataType === 'percent' ? 'Total_Per' : 'Total_Num'}
                      name={this.props.areaName}
                      fill={this.props.color}
                      label={props => (
                        <CustomVerticalLabel {...props} formatter={(value) => this.state.dataType === 'percent'
                        ? formatPercentage(value)
                        : formatNumber(value)} />
                      )}
                    />
                    {this.state.dataType === 'percent' && (
                      <Bar
                        dataKey="Total_Per_BM"
                        name={this.props.benchmarkName}
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                        label={props => (
                          <CustomVerticalLabel {...props} formatter={(value) => formatPercentage(value)}/>
                        )}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default TenureChart
