import React from 'react'

const CustomHorizontalLabelWorkerAffordbilityChart = (props) => {

  const { x, y, name, width, height, xoffset = 0, formatter, labelField, labelDataField, barCategory, refData } = props

  const labelDirection = refData[name][labelField] //get the bar assigned for label rendering
  const labelData = refData[name][labelDataField]  //get the stored final value, value on the bar could not be the final value
  const textFill = height < -10 ? '#ffffff' : "#000"


  const formattedValue = formatter && barCategory == labelDirection ? formatter(labelData) : null //render label of the bar is the correct one
  const xposition = labelData >= 0 ? x + width + 20 + xoffset : x + width - 20 - xoffset
  return (
    <>
      <text
        className="recharts-text recharts-label"
        x={xposition}
        y={y + height / 2 + 10}
        dy={-5}
        fill={textFill}
        textAnchor="middle"
      >
        {formattedValue}
      </text>
    </>
  )
}
export default CustomHorizontalLabelWorkerAffordbilityChart
