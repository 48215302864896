import React from 'react'

const HouseholdsByDwellingTypeChartInfo = ({ benchmarkName, LGAName }) => {
  return (
    <div>
      <p>
        This chart shows, for the latest Census period, the broad household types broken down by
        broad dwelling types within {LGAName} compared to {benchmarkName}. It can indicate which
        types of households have a revealed preference to occupy certain housing formats (eg. are
        lone persons more likely to live in medium and high density?), and whether this is at a
        greater or lesser rate than the benchmark. Further detail including a bedroom breakdown is
        available in other charts.
      </p>
      <p>Separate house - All dwellings separated from each other by at least half a metre.</p>
      <p>
        Medium density - Semi-detached, row, unit, townhouse and 1 or 2 storey flats, units and
        apartments
      </p>
      <p>High density - 3-storey or more flats, units and apartments.</p>
      <p>
        Note that families include both couple and one parent families, which can be separated out
        further down the page.
      </p>
    </div>
  )
}

export default HouseholdsByDwellingTypeChartInfo
