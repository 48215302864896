import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Bowser from 'bowser'

const UnsupportedBrowserModal = ({ modal, toggle }) => (
  <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>We're sorry</ModalHeader>
    <ModalBody>
      <p>We haven't found a way for users of {browserName()} to export images yet.</p>

      <p>Please try using one of these excellent browsers instead</p>

      <ul>
        <li>
          <a href="https://www.google.com/chrome/">Chrome</a>
        </li>
        <li>
          <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
        </li>
      </ul>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
)

const browserName = () => {
    if (typeof window === 'undefined') {
        return 'this browser'
      }
  
  const browser = Bowser.getParser(window.navigator.userAgent)
  const browserInfo = browser.getBrowser()
  return browserInfo.name
}

export default UnsupportedBrowserModal
