const Median = 'median'
const EntryLevel = 'entry_level'

const Number = 'number'
const Percent = 'percent'
const Table = 'table'

export {
    Median, 
    EntryLevel, 
    Number, 
    Percent, 
    Table
}