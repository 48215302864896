import React from 'react'
import { proptrack_disclaimer } from './proptrack-disclaimer.module.scss'

class ProptrackDisclaimer extends React.Component {
  render() {
    return (
      <div className={proptrack_disclaimer}>
        <p>
          This information is supplied by ID Consulting Pty Ltd (ABN 44084 054 473) on behalf of
          PropTrack Pty Ltd (ABN 43 127 386 298).
        </p>
        <a href="https://www.proptrack.com.au/product-disclaimers/">
          Copyright and Legal Disclaimers about Property Data
        </a>
      </div>
    )
  }
}

export default ProptrackDisclaimer
