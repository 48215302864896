import numeral from 'numeral'

const formatNumberNA = (number, stringFormat) => {
    if (number === null || number === 0) 
        return "N/A"
    else 
        return numeral(number).format(stringFormat)
}

const formatNumber = (number, stringFormat = "0,0") => {
    return numeral(number).format(stringFormat)
}

const roundNumberWithDecimalPlaces = (number, decimalPlaces = 0) => {
    return Math.round(number*Math.pow(10, decimalPlaces))/Math.pow(10, decimalPlaces)
}

export {
    formatNumberNA, 
    formatNumber,
    roundNumberWithDecimalPlaces
} 