import React from 'react'
import { map, flow, max } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import numeral from 'numeral'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from '../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'
import { formatNumber } from '../../functions/formatNumber'

class OccupancyRateByNumberOfBedroomsChart extends React.Component {
  render = () => {
    try {
      const chartData = [
        {
          group: '0-1',
          Total: this.props.data._2021_persons_per_dwelling_0_or_1_bedroom,
          Total_BM: this.props.benchmarkData._2021_persons_per_dwelling_0_or_1_bedroom,
        },
        {
          group: '2',
          Total: this.props.data._2021_persons_per_dwelling_two_bedrooms,
          Total_BM: this.props.benchmarkData._2021_persons_per_dwelling_two_bedrooms,
        },
        {
          group: '3',
          Total: this.props.data._2021_persons_per_dwelling_three_bedrooms,
          Total_BM: this.props.benchmarkData._2021_persons_per_dwelling_three_bedrooms,
        },
        {
          group: '4+',
          Total: this.props.data._2021_persons_per_dwelling_4_or_more_bedrooms,
          Total_BM: this.props.benchmarkData._2021_persons_per_dwelling_4_or_more_bedrooms,
        },
      ]

      const numberFormatter = value => numeral(value).format('0,0[.]0')

      const maxValue = flow([x => map(x, item => max([item.Total, item.Total_BM])), max])(chartData)

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(5)
        .ticks(5)

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}: {numeral(item.value).format('0,0.00')}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`household-types-chart`}
          title={`What is the occupancy rate of dwellings by size?`}
          subTitle={`Average household size by number of bedrooms, 2021`}
          dataSource={Datasource.ABS2021}
          dataNotes={this.props.dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div>
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis dataKey="group">
                      <Label value="Bedrooms" offset={-10} position="insideBottom" />
                    </XAxis>
                    <YAxis
                      tickLine={false}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.count + 1}
                      tickFormatter={numberFormatter}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Bar
                      dataKey={'Total'}
                      name={this.props.areaName}
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-primary')
                      }
                      label={props => (
                        <CustomVerticalLabel {...props} formatter={(value) => formatNumber(value, '0.00')}/>
                      )}
                    />
                    <Bar
                      dataKey="Total_BM"
                      name={this.props.benchmarkName}
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                      }
                      label={props => (
                        <CustomVerticalLabel {...props} formatter={(value) => formatNumber(value, '0.00')}/>
                      )}
                    />
                    )
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default OccupancyRateByNumberOfBedroomsChart
