import React from 'react'
import { isEmpty, head, last } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts'
import numeral from 'numeral'
import ChartWrapper from './../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

const HousingApprovalsChart = ({ data, dataNotes, chartInfo }) => {
  if (isEmpty(data)) {
    return <ErrorMessageChart error="No data for chart" />
  }
  try {
    const chartData = head(data.nodes).data.LGA

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}: {numeral(item.value).format('0,0')}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    return (
      <ChartWrapper
        name={`building-approvals-by-dwelling-type`}
        title={`How are residential building approvals tracking?`}
        subTitle={`Approvals by dwelling type`}
        dataSource={Datasource.ChartBuildingApproval}
        chartNotes={
          last(chartData).Year_Label.includes('*') ? `Current financial year to date` : null
        }
        dataNotes={dataNotes}
        chartInfo={chartInfo}
        body={
          <div className="chart__viz" style={{ width: '100%', height: 360 }}>
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <XAxis dataKey="Year_Label">
                  <Label
                    value={Datasource.FinancialYearChartLabel}
                    offset={-5}
                    position="insideBottom"
                  />
                </XAxis>
                <YAxis
                  tickLine={false}
                  domain={['auto', 'auto']}
                  tickFormatter={value => numeral(value).format('0,0')}
                />
                <CartesianGrid vertical={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={36}
                  formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                />
                <Bar
                  dataKey="Number_House"
                  stackId="a"
                  fill={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-primary')
                  }
                  name="Houses"
                ></Bar>
                <Bar
                  dataKey="Number_Other"
                  stackId="a"
                  fill={
                    typeof window !== `undefined` &&
                    getComputedStyle(document.body).getPropertyValue('--bs-muted')
                  }
                  name="Other types"
                ></Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default HousingApprovalsChart
