import React from 'react'

const CustomLegend = ({ items }) => {
  const legendItems = items.map(({ color, label, shape }, index) => (
    <LegendItem color={color} label={label} key={index} shape={shape} />
  ))

  return <div className="legend">{legendItems}</div>
}

const LegendItem = ({ shape, label, color }) => (
  <p className="legend-item">
    {shape && shape}

    {!shape && (
      <span className="legend-chip" style={{ background: color }}></span>
    )}
    <span style={{ color: 'black' }}> {label}</span>
  </p>
)

export default CustomLegend
