import React from 'react'
import HousingStressBandsChart from './housing-stress-bands'
import { ErrorMessageChart } from '../error-message-chart'

const HousingStressRentalChart = ({
  data,
  benchmarkData,
  areaName,
  benchmarkName,
  dataNotes,
}) => {
  try {
    const chartData = [
      {
        group: 'Very low income',
        number: data.Very_Low_Rental_Stress_No,
        percent: data.Very_Low_Rental_Stress_Per,
        benchmark_percent: benchmarkData.Very_Low_Rental_Stress_Per,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary'),
        color_BM: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500'),
      },
      {
        group: 'Low income',
        number: data.Low_Rental_Stress_No,
        percent: data.Low_Rental_Stress_Per,
        benchmark_percent: benchmarkData.Low_Rental_Stress_Per,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-muted20'),
        color_BM: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500'),
      },
      {
        group: 'Moderate income',
        number: data.Moderate_Rental_Stress_No,
        percent: data.Moderate_Rental_Stress_Per,
        benchmark_percent: benchmarkData.Moderate_Rental_Stress_Per,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-muted'),
        color_BM: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500'),
      },
    ]

    return (
      <HousingStressBandsChart
        name={`rental-stress-income-bands`}
        title={`Rental stress by income`}
        subTitle={`Households renting in housing stress by income band`}
        chartData={chartData}
        areaName={areaName}
        benchmarkName={benchmarkName}
        dataNotes={dataNotes}
      />
    )
  } catch (error) {
    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

export default HousingStressRentalChart
