import React from 'react'
import {IdIconChevronRight, IdIconMap} from '../idIcon'
import {Collapse} from 'reactstrap'
import './index.scss'

const SingleCrossLink = ({urls, icon, product, children}) => (
    <a href={urls[0]} className={`cross-link cross-link--${product}`} target="_blank" rel="noopener noreferrer">
        <div className="cross-link__main">
            <div className="cross-link__icon">
                {icon === "map" &&
                    <IdIconMap />
                }
            </div>
            <div className="cross-link__body">
                <div className="cross-link__kicker">SEE ALSO</div>
                {children}
            </div>
            <div className="cross-link__action-icon"><span className="d-none d-lg-inline">View in {product} </span><IdIconChevronRight /></div>
        </div>
    </a>
);

class MultiCrossLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggleOpen = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    render = () => {
        const {urls, icon, product, children} = this.props;

        return (
            <div onClick={this.toggleOpen} className={`cross-link cross-link--multi cross-link--${product} ${this.state.isOpen ? 'cross-link--is-open' : ''}`} target="_blank" rel="noopener noreferrer">
                <div className="cross-link__main">
                    <div className="cross-link__icon">
                        {icon === "map" &&
                            <IdIconMap />
                        }
                    </div>
                    <div className="cross-link__body">
                        <div className="cross-link__kicker">SEE ALSO</div>
                        {children}
                    </div>
                    <div className="cross-link__action-icon">{this.state.isOpen ? 'Hide' : 'Show'} {urls.length} items</div>
                </div>
                <Collapse isOpen={this.state.isOpen}>
                    <div className="cross-link__collapse">
                        <ul className="list-unstyled mb-0">
                            {urls.map((url, index) => (
                                <li key={index}><a href={url.url} target="_blank" rel="noopener noreferrer">{url.title}</a></li>
                            ))}
                        </ul>
                    </div>
                </Collapse>
            </div>
        )
    }
}

const CrossLinkComponent = (props) => {

    // Find the product with the matching SubdomainName
    const matchingProduct = props.clientProducts.find(product => product.SubdomainName === props.product);
    
    // Check if the matching product exists and if it is disabled; if yes, return nothing and vice versa
    if (matchingProduct && matchingProduct.IsDisabled) {
    return false
    }
     
    if (props.urls.length > 1) {
        return <MultiCrossLink {...props} />;
    } else {
        return <SingleCrossLink {...props} />;
    }
    
};

export default CrossLinkComponent