import React from 'react'
import * as Datasource from '../../config/text-constants'
import GenericPriceLineChart from './lineChart/generic-price-line-chart'
import calculateBMMedianForPropertyTypes from '../../functions/benchmarkMedianDataHelper'

const GenericPriceChangeChart = props => {
  const flattenedBMData = calculateBMMedianForPropertyTypes(
    props.benchmarkData,
    props.transactionType
  )

  const chartData = props.data.map(periodData => {
    const matchingBMData = flattenedBMData.find(
      bmData => bmData.Period_Name === periodData.Period_Name
    )
    return {
      median: periodData[`Median_${props.propertyType}`] || 0,
      entry_level: periodData[`Entry_${props.propertyType}`] || 0,
      regional_median: (matchingBMData && matchingBMData[`Median_${props.propertyType}`]) || 0,
      regional_entry_level: (matchingBMData && matchingBMData[`Entry_${props.propertyType}`]) || 0,
      Period_Name: periodData.Period_Name,
    }
  })

  return (
    <GenericPriceLineChart
      name={props.name}
      subTitle={props.subTitle}
      dataSource={Datasource.Proptrack}
      title={props.title}
      areaName={props.areaName}
      benchmarkData={props.benchmarkData}
      chartInfo={props.chartInfo}
      chartData={chartData}
      dataKeyPeriod={'Period_Name'}
      dataKeyMedian={'median'}
      dataKeyEntry={'entry_level'}
      dataKeyEntryBM={'regional_entry_level'}
      dataKeyMedianBM={'regional_median'}
      benchmarkName={props.benchmarkName}
      dataNotes={props.dataNotes}
      chartTypeText={props.chartTypeText}
    />
  )
}

export default GenericPriceChangeChart
