import { isEmpty, max } from 'lodash'
import React, { useContext } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from '../chartWrapper'
import { ErrorMessageChart } from '../error-message-chart'
import { formatNumberNA, formatNumber } from '../../functions/formatNumber'
import { getEntryLevelLabel } from '../../functions/getLevelLabels'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'
import TooltipWrapper from '../tooltip-wrapper'
import { PropTypes } from 'prop-types'
import calPresentValueMortgage from '../../functions/presentValueMortgage'
import { WorkerAffordabilityContext } from '../../hooks/workerAffordabilityContext'
import TimePeriodDropdown from '../timePeriodDropdown'
import { getValidPeriods } from '../../functions/affordabilityChartHelpers.js'

const WorkerAffordabilityLgaChart = ({
  alias,
  pricePoints,
  interest,
  name,
  title,
  dataSource,
  dataNotes,
  type,
  areaName,
  data,
  affordableData,
  proptrackDisclaimer,
  xoffset = 0,
}) => {
  try {
    if (isEmpty(pricePoints) || isEmpty(data) || isEmpty(affordableData)) {
      return <ErrorMessageChart error="No data for chart" />
    }

    const context = useContext(WorkerAffordabilityContext)

    // Get only periods that have calculated affordability data and housing median data
    const periods = getValidPeriods(pricePoints, affordableData)

    // Create a map of interest rates for each period
    const interestRatesMap = new Map()

    periods.forEach(period => {
      const [month, yearString] = period.split(' ')
      const year = parseInt(yearString, 10)

      const interestForYear = interest.find(item => item.Year === year)

      if (interestForYear) {
        let interest_rate =
          month === 'Jun'
            ? interestForYear.Interest_rate_june
            : interestForYear.Interest_rate_december
        interestRatesMap.set(period, interest_rate)
      } else {
        interestRatesMap.set(period, null)
      }
    })

    const activePeriod =
      type === 'mortgage'
        ? context.activeSalePeriod == null
          ? periods[0]
          : context.activeSalePeriod
        : context.activeRentalPeriod == null
        ? periods[0]
        : context.activeRentalPeriod

    const currentPricePoints = pricePoints.Periods.find(p => p.Period_Name == activePeriod)

    //build chart data
    const getSelectedChartData = (data, selectedPeriod) => {
      const selectedData = data.find(p => selectedPeriod.includes(p.Year.toString()))

      let selectedChartData = []
      let dicOfOccupations = {} //will be used for tooltip look up
      let maxOutputValue = 0 //for constructing the chart

      if (selectedData != undefined) {
        // Get the correct interest rate for the selected period
        const selectedInterestRate = interestRatesMap.get(selectedPeriod)

        selectedData.Occupations.map(p => {
          const calculatedAmount = getOutputAmount(p['Income'], type, selectedInterestRate)

          selectedChartData.push({
            name: p['Occupation'],
            range: [0, calculatedAmount],
            fill:
              typeof window !== `undefined` &&
              getComputedStyle(document.body).getPropertyValue('--bs-muted'),
          })

          dicOfOccupations[p['Occupation']] = p['Income']

          if (calculatedAmount > maxOutputValue) maxOutputValue = calculatedAmount
        })
      }
      return { data: selectedChartData, dic: dicOfOccupations, max: maxOutputValue }
    }

    const selectedPeriodChartData = getSelectedChartData(data, activePeriod)

    const chartBMLines = {
      House_Median: currentPricePoints.Median_House,
      House_Entry: currentPricePoints.Entry_House,
      Unit_Entry: currentPricePoints.Entry_Unit,
      Unit_Median: currentPricePoints.Median_Unit,
    }

    const maxValue = max([
      selectedPeriodChartData.max,
      chartBMLines.House_Median,
      chartBMLines.Unit_Median,
    ])

    const domain = scaleLinear()
      .domain([0, maxValue])
      .nice(5)
      .domain()

    const legendContent = [
      {
        id: 'Entry Unit: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '3 3',
        },
        color: 'black',
        value: `${getEntryLevelLabel(alias)} Unit: ${formatNumberNA(
          chartBMLines.Unit_Entry,
          '$0,0'
        )}`,
      },
      {
        id: 'Median Unit: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '3 3',
        },
        color: 'black',
        value: `Median Unit: ${formatNumberNA(chartBMLines.Unit_Median, '$0,0')}`,
      },
      {
        id: 'Entry House: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '1 0',
        },
        color: 'black',
        value: `${getEntryLevelLabel(alias)} House: ${formatNumberNA(
          chartBMLines.House_Entry,
          '$0,0'
        )}`,
      },
      {
        id: 'Median House: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '1 0',
        },
        color: 'black',
        value: `Median House: ${formatNumberNA(chartBMLines.House_Median, '$0,0')}`,
      },
    ]
    const ticks = scaleLinear()
      .domain(domain)
      .nice(4)
      .ticks(4)

    const handleActivePeriodDropdownOnChange = event => {
      if (type === 'mortgage') context.setActiveSalePeriod(event.target.value)
      else context.setActiveRentalPeriod(event.target.value)
    }

    const SubTitle = () => {
      const baseText = `What the top occupation households in ${areaName} can afford (by median household income)`
      let mortgageText =
        type === 'mortgage'
          ? `${baseText} vs property prices in the 12 months to`
          : `${baseText} in rent vs market prices ($ pw) in the 12 months to`
      return (
        <>
          {mortgageText}{' '}
          {periods.length > 1 && (
            <TimePeriodDropdown
              onChange={handleActivePeriodDropdownOnChange}
              periods={periods}
              currentPeriod={activePeriod}
            />
          )}
        </>
      )
    }
    const CustomTooltip = props => {
      const { active } = props
      if (active) {
        const { label } = props
        const median = selectedPeriodChartData.dic[label]
        return (
          <TooltipWrapper>
            Median weekly household income: {formatNumber(median, '$0,0')}
          </TooltipWrapper>
        )
      }
      return null
    }

    //Update the data note with correct LGA name
    if (dataNotes != null) dataNotes.html = dataNotes.html.replace('%LGA%', areaName)

    return (
      <ChartWrapper
        title={title}
        subTitle={<SubTitle />}
        dataSource={dataSource}
        dataNotes={dataNotes}
        name={name}
        proptrackDisclaimer={proptrackDisclaimer}
        body={
          <div className="chart__viz" style={{ height: 500 }}>
            <ResponsiveContainer>
              <BarChart
                data={selectedPeriodChartData.data}
                margin={{ bottom: 15, right: 40 }}
                layout="vertical"
              >
                <Tooltip content={<CustomTooltip />} />
                <YAxis type="category" dataKey="name" width={150} tickLine={false} />
                <XAxis
                  type="number"
                  tickLine={false}
                  ticks={ticks}
                  tickFormatter={value => formatNumber(value, '$0,0')}
                />
                <CartesianGrid vertical={true} horizontal={false} />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={60}
                  width={430}
                  payload={legendContent}
                  formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                />
                <Bar
                  dataKey="range"
                  isAnimationActive={false}
                  label={props => (
                    <CustomHorizontalLabel
                      {...props}
                      xoffset={xoffset}
                      formatter={value => formatNumber(value, '$0,0')}
                      fill={null}
                    />
                  )}
                />
                <ReferenceLine x={chartBMLines.House_Median} stroke="black" />
                <ReferenceLine x={chartBMLines.Unit_Entry} stroke="black" strokeDasharray="3 3" />
                <ReferenceLine x={chartBMLines.House_Entry} stroke="black" />
                <ReferenceLine x={chartBMLines.Unit_Median} stroke="black" strokeDasharray="3 3" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

const getOutputAmount = (weekly_income, type, selectedInterestRate) => {
  if (type === 'mortgage') return calPresentValueMortgage(weekly_income * 52, selectedInterestRate)
  else return weekly_income * 0.3
}

WorkerAffordabilityLgaChart.propTypes = {
  alias: PropTypes.string.isRequired,
  pricePoints: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['mortgage', 'rental']).isRequired,
  dataNotes: PropTypes.object,
  dataSource: PropTypes.string,
  data: PropTypes.array.isRequired,
  affordableData: PropTypes.array.isRequired,
  areaName: PropTypes.string.isRequired,
  interest: PropTypes.number,
  title: PropTypes.string,
  proptrackDisclaimer: PropTypes.bool,
  xoffset: PropTypes.number,
}

export default WorkerAffordabilityLgaChart
