import React from 'react'
import './index.scss'

const PageHeader = ({ background, children, className }) => (
  <div className={`page-header ${className}`}>
    <div className="page-header__content">
      <div className="container print-100">
        <div className="row">
          <div className="col-md-9">{children}</div>
        </div>
        {background && (
          <div className="page-header__background__image" aria-hidden="true">
            <img
              src={background}
              className="page-header__hero"
              alt="Illustration of houses"
            />
          </div>
        )}
      </div>
    </div>
  </div>
)

export default PageHeader
