import React from 'react'
import PillSelect from '../pillSelect'

export const HouseholdTypes = {
  Total: 'Average all households',
  Lone_person: 'Lone person',
  Couple: 'Couple without children',
  Couple_family_with_children: 'Couple with children',
  One_parent_family: 'One parent family',
  Group_household: 'Group household',
}

const HouseholdTypeDropdown = ({ onChange, value }) => {
  return (
    <PillSelect aria-label="Household type" onChange={onChange} defaultValue={value}>
      {Object.keys(HouseholdTypes).map(key => (
        <option value={key} key={HouseholdTypes[key]}>
          {HouseholdTypes[key]}
        </option>
      ))}
    </PillSelect>
  )
}

export default HouseholdTypeDropdown
