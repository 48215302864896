import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Obfuscate from 'react-obfuscate'

const HelpModal = ({ modalIsOpen, toggle }) => {
  return (
    <Modal isOpen={modalIsOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Getting help</ModalHeader>
      <ModalBody>
        <p>
          <strong>Please contact our housing team</strong>
        </p>
        <p>
          Email <Obfuscate email="housing@id.com.au" />
          <br />
          Call <Obfuscate tel="+61 3 9417 2205" />.{' '}
        </p>
        <p> We're available 9am to 5pm weekdays local Sydney/Melbourne time.</p>
      </ModalBody>
    </Modal>
  )
}

export default HelpModal
