import React from 'react'
import { map, flow, flatten, max, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import { Button, ButtonGroup } from 'reactstrap'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import { formatNumber } from '../../functions/formatNumber'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'

class HousingStressBandsChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    const { chartData, areaName, benchmarkName, dataNotes, name, title, subTitle } = this.props
    if (isEmpty(chartData)) {
      return <ErrorMessageChart error="No data for chart" />
    }

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                {item.name}:{' '}
                {this.state.dataType === 'percent'
                  ? formatPercentage(item.value)
                  : formatNumber(item.value)}
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    try {
      const maxValue = flow([
        x => map(x, item => (this.state.dataType === 'number' ? item.number : item.percent)),
        flatten,
        max,
      ])(chartData)

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(3)
        .ticks(3)

      return (
        <ChartWrapper
          name={name}
          title={title}
          subTitle={subTitle}
          dataNotes={dataNotes}
          dataSource={Datasource.ABS2021}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>

              <div className="chart__viz" style={{ width: '100%', height: 240 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData} layout="vertical" margin={{ right: 40, top: 15 }}>
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <XAxis
                      type="number"
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.count + 1}
                      tickFormatter={value => formatNumber(value)}
                      unit={this.state.dataType === 'percent' ? '%' : ''}
                    />
                    <YAxis type="category" dataKey="group" tickLine={false} width={140} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      dataKey={this.state.dataType === 'percent' ? 'percent' : 'number'}
                      name={areaName}
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-primary')
                      }
                      label={props => (
                        <CustomHorizontalLabel {...props} formatter={(value) => this.state.dataType === 'percent'
                        ? formatPercentage(value)
                        : formatNumber(value)} />
                      )}
                    />
                    {this.state.dataType === 'percent' && (
                      <Bar
                        dataKey="benchmark_percent"
                        name={benchmarkName}
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                        label={props => (
                          <CustomHorizontalLabel {...props} formatter={(value) => formatPercentage(value)} />
                        )}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default HousingStressBandsChart
