import React, { useState } from 'react'
import { map, mapKeys, merge, groupBy, sortBy, flow, maxBy, isEmpty } from 'lodash'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts'
import CustomLegend from '../legend/index.js'
import numeral from 'numeral'
import { scaleLinear } from 'd3-scale'
import {
    LandcomIconHouse,
    LandcomIconTownhouse,
    LandcomIconApartments,
} from '../landcom-icons'
import { ButtonGroup, Button } from 'reactstrap'
import ChartWrapper from '../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { PropTypes } from 'prop-types'


const HousingConsumptionDwellings = ({ data, benchmarkData, benchmarkName, areaName, dataNotes, houseHoldType, chartInfo }) => {
    if (isEmpty(data)) {
        return (
            <ErrorMessageChart error="No data for chart"/>
        )
    }
    const [dataType, setDataType] = useState('percent')

    const dataKey = `${houseHoldType.value}_Percentage`

    const benchmarkDataKey = `b${houseHoldType.value}_Percentage`

    const calculateTicks = () => {

        const selectedDataKey = dataType === 'percent' ? dataKey : houseHoldType.value

        const maxValueItem = maxBy(data, selectedDataKey)

        const maxValueItemBm = maxBy(benchmarkData, selectedDataKey)

        const maxValue = dataType === 'percent' ? Math.max(maxValueItem[selectedDataKey], maxValueItemBm[selectedDataKey]) : maxValueItem[selectedDataKey]
        const ticks = scaleLinear()
            .domain([0, maxValue])
            .nice(4)
            .ticks(4)

        return ticks
    }


    const chartIcon = dwellingType => {
        switch (dwellingType) {
            case 'Separate house':
                return (
                    <LandcomIconHouse className="landcom-icon-lg landcom-icon-purple" />
                )
            case 'Medium density':
                return (
                    <LandcomIconTownhouse className="landcom-icon-lg landcom-icon-purple" />
                )
            case 'High density':
                return (
                    <LandcomIconApartments className="landcom-icon-lg landcom-icon-purple" />
                )
            default:
                return false
        }
    }


    const CustomTooltip = props => {
        const { active } = props

        if (active) {
            const { payload, label } = props

            return (
                <TooltipWrapper>
                    <strong>{label} bedrooms</strong>
                    {payload.map((item, key) => (
                        <p
                            key={key}
                            className="recharts-tooltip-label m-0"
                            style={{ color: item.color }}
                        >
                            {item.name}:{' '}
                            {dataType === 'percent'
                                ? formatPercentage(item.value)
                                : numeral(item.value).format('0,0')}
                        </p>
                    ))}
                </TooltipWrapper>
            )
        }
        return null
    }

    const areaColor =typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')

    const benchmarkColor = typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')

    const legendItems = () => {
        const legendItems = [{ label: areaName, color: areaColor }]

        if (dataType === 'percent') {
            legendItems.push({ label: benchmarkName, color: benchmarkColor })
        }
        return legendItems
    }


    const chartData = flow([        
        x => groupBy(x, d => d.Dwelling_Type),
        x => map(x, (value, key) => {
            value = sortBy(value, ['Dwelling_Composition'])
            return { type: key, data: value }
        })
    ])(data)


    //Need to make the name of properties of benchmark data different to use in rechart
    //such as Total -> bTotal     
    const mappedBenchmarkdata = benchmarkData.map((item) => {
        let newObj = mapKeys(item, (value, key) => {
            if (key != 'Dwelling_Composition' && key != 'Dwelling_Type')
                return 'b' + key
            else
                return key
        })
        return newObj
    })


    const sortedBenchmarkData = flow([
        x => groupBy(x, d => d.Dwelling_Type),
        x => map(x, (value, key) => {
            value = sortBy(value, ['Dwelling_Composition'])
            return { type: key, data: value }
        })
    ]) (mappedBenchmarkdata)


    const mergedData = merge(sortedBenchmarkData, chartData)


    const charts = mergedData.map((item, index) => {
        return (
            <div className="col-md-4" key={index}>
                <div style={{ width: '100%', height: 200 }}>
                    <ResponsiveContainer>
                        <BarChart className="overflow-visible" data={item.data}>
                            <XAxis dataKey="Dwelling_Composition">
                                <Label
                                    value="Bedrooms"
                                    offset={-5}
                                    position="insideBottom"
                                />
                            </XAxis>
                            <YAxis
                                tickLine={false}
                                ticks={calculateTicks()}
                                interval={0}
                                tickFormatter={
                                    dataType === 'percent'
                                        ? value => `${numeral(value).format('0[.]0')}%`
                                        : value => numeral(value).format('0,0')
                                }
                                tickCount={calculateTicks().count + 1}
                                width={30}
                            />
                            <CartesianGrid vertical={false} />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar
                                dataKey={
                                    dataType === 'percent'
                                        ? dataKey
                                        : houseHoldType.value
                                }
                                fill={areaColor}
                                name={areaName}
                            />
                            {dataType === 'percent' && (
                                <Bar
                                    dataKey={benchmarkDataKey}
                                    fill={benchmarkColor}
                                    name={benchmarkName}
                                />
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <p className="text-center m-0 mt-2" style={{ paddingLeft: '32px' }}>
                    {chartIcon(item.type)}
                    <br /> {item.type}
                </p>
            </div>
        )
    })

    try {
        return (
            <ChartWrapper
                name={`housing-mix-bedrooms`}
                title={`What dwelling types do ${houseHoldType.name.toLowerCase()} households live in?`}
                subTitle={`Occupied dwellings by dwelling type and no. of bedrooms, 2021`}
                dataSource={Datasource.ABS2021}
                dataNotes={dataNotes}
                chartInfo={chartInfo}
                body={
                    <div>
                        <ButtonGroup size="sm" className="d-print-none mb-1">
                            <Button
                                outline
                                onClick={() => setDataType('percent')}
                                active={dataType === 'percent'}
                            >
                                Percent
                            </Button>
                            <Button
                                outline
                                onClick={() => setDataType('number')}
                                active={dataType === 'number'}
                            >
                                Number
                            </Button>
                        </ButtonGroup>
                        <div className="chart-group">
                            <CustomLegend items={legendItems()} />
                            <div className="row">{charts}</div>
                        </div>
                    </div>
                }
            />
        )
    }
    catch (error) {
        return (
            <ErrorMessageChart error={error}/>
        )
    }
}

HousingConsumptionDwellings.propTypes = {
    data: PropTypes.array.isRequired,
    benchmarkData: PropTypes.array.isRequired,
    areaName: PropTypes.string.isRequired,
    dataNotes: PropTypes.object.isRequired,
    houseHoldType: PropTypes.object.isRequired,
    benchmarkName: PropTypes.string.isRequired,
    chartInfo: PropTypes.object
}


export default HousingConsumptionDwellings