import React from 'react'
import './index.scss'
import Disclaimer from '../disclaimer'
import idLogo from '../../img/id_full_logo.png'
import FooterHelpButton from '../footerHelpButton'
import FooterFeedbackButton from '../footerFeedbackButton'

const Footer = ({client}) => (
  <div className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <a
            className="footer-logo"
            href="https://home.id.com.au/"
            title="Visit the .id website"
          >
            <img
              src={idLogo}
              alt=".id logo"
              width="180"
              className="align-middle"
            />
          </a>
        </div>
        <div className="col-md-9">
          {client === 'gold-coast' &&
            <ul className="footer-links list-inline">
              <li className="list-inline-item tandc">
                <a href={'/goldcoast-TandC.pdf'} target="_blank">
                  Gold Coast Terms and Conditions
                </a>
              </li>
            </ul>
          }
          <ul className="footer-links list-inline">
            <li className="list-inline-item">
              <a href="https://home.id.com.au/services/demographic-information-tools/local-government-online-tools/housing-id/">
                About housing monitor
              </a>
            </li>
            <li className="list-inline-item">
              <FooterFeedbackButton />
            </li>
            <li className="list-inline-item">
              <FooterHelpButton />
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Disclaimer />
        </div>
      </div>
    </div>
  </div>
)

export default Footer
