import React from 'react'
import { map, flow, max, min, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  Text,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import numeral from 'numeral'
import formatPercentage from '../../functions/formatPercentage'
import { formatNumber } from '../../functions/formatNumber'
import ChartWrapper from '../chartWrapper'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'
import { ErrorMessageChart } from '../error-message-chart'

const TenureChangeChart = props => {
  try {
    if (isEmpty(props.data)) {
      return (
        <ErrorMessageChart error="No data for chart"  />
      )
    }
    const chartData = [
      {
        group: 'Fully owned',
        Change:
          props.data.N2021_Owned_Outright - props.data.N2016_Owned_Outright,
        ChangePerc:
          (props.data.N2021_Owned_Outright - props.data.N2016_Owned_Outright) /
          props.data.N2021_Owned_Outright,
      },
      {
        group: 'Mortgage',
        Change:
          props.data.N2021_Owned_With_Mortgage -
          props.data.N2016_Owned_With_Mortgage,
        ChangePerc:
          (props.data.N2021_Owned_With_Mortgage -
            props.data.N2016_Owned_With_Mortgage) /
          props.data.N2021_Owned_With_Mortgage,
      },
      {
        group: 'Renting - private',
        Change:
          props.data.N2021_Rented_Private - props.data.N2016_Rented_Private,
        ChangePerc:
          (props.data.N2021_Rented_Private - props.data.N2016_Rented_Private) /
          props.data.N2021_Rented_Private,
      },
      {
        group: 'Renting - social housing',
        Change: props.data.N2021_Rented_Social - props.data.N2016_Rented_Social,
        ChangePerc:
          (props.data.N2021_Rented_Social - props.data.N2016_Rented_Social) /
          props.data.N2021_Rented_Social,
      },
    ]

    const maxValue = flow([      
      x => map(x, item => item.Change, x),
     max
     ] )(chartData)

    const minValue = flow([
      x => map(x, item => item.Change, x),
      min
    ])(chartData)

    const domain = scaleLinear()
      .domain([minValue, maxValue])
      .nice(5)
      .domain()


    const AxisText = props => {
      const width = props.width / chartData.length
      return (
        <Text {...props} width={width}>
          {props.payload.value}
        </Text>
      )
    }

    const CustomTooltip = props => {
      const { active } = props

      if (active) {
        const { payload, label } = props

        return (
          <TooltipWrapper>
            <strong>{label}</strong>
            {payload.map((item, key) => (
              <p
                key={key}
                className="recharts-tooltip-label m-0"
                style={{ color: item.color }}
              >
                {item.name}: {item.value} (
                {formatPercentage(item.payload.ChangePerc * 100)})
              </p>
            ))}
          </TooltipWrapper>
        )
      }
      return null
    }

    return (
      <ChartWrapper
        name={`tenure-change-chart`}
        title={`How has tenure changed, 2016-2021?`}
        subTitle={`Change in tenure of households`}
        dataSource={Datasource.ABS2016}
        dataNotes={props.dataNotes}
        chartInfo={props.chartInfo}
        body={
          <div className="chart__viz" style={{ width: '100%', height: 320 }}>
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <XAxis
                  dataKey="group"
                  tickLine={false}
                  padding={{ top: 40 }}
                  tick={<AxisText />}
                  interval={0}
                />
                <YAxis
                  tickLine={false}
                  scale="linear"
                  domain={domain}
                  tickFormatter={value => numeral(value).format('0,0')}
                />
                <CartesianGrid vertical={false} />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="Change"
                  name="Change"
                  fill={props.color}
                  label={props => (
                    <CustomVerticalLabel {...props} formatter={(value) => formatNumber(value)}/>
                  )}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
      />
    )
  } catch (error) {
    console.error(error)

    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

export default TenureChangeChart
