import { includes, get, maxBy, toLower, isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'
import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from './../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import { ErrorMessageChart } from '../error-message-chart'
import formatPercentage from '../../functions/formatPercentage'
import { formatNumber } from '../../functions/formatNumber'

const PopulationForecastChart = ({ data, dataNotes, color, chartInfo }) => {
  if (isEmpty(data)) {
    return <ErrorMessageChart error="No data for chart" />
  }

  let chartData = [
    { year: 2006, per: get(data, 'Population_06') },
    { year: 2011, per: get(data, 'Population_11') },
    { year: 2016, per: get(data, 'Population_16') },
    { year: 2021, per: get(data, 'Population_21') },
    { year: 2026, per: get(data, 'Population_26') },
    { year: 2031, per: get(data, 'Population_31') },
    { year: 2036, per: get(data, 'Population_36') },
  ]

  // Add 2041 if available
  // TODO: make this computational, not hard-coded
  if (get(data, 'Population_41')) {
    chartData.push({ year: 2041, per: get(data, 'Population_41') })
  }

  const maxValue = maxBy(chartData, item => item.per).per

  const domain = scaleLinear()
    .domain([0, maxValue])
    .nice(5)
    .domain()

  const increaseOrDecrease = number => {
    if (number > 0) {
      return 'increase'
    }
    if (number === 0) {
      return 'no-change'
    }
    if (number < 0) {
      return 'decrease'
    }
  }

  const annualGrowthRate = get(data, 'Population_41')
    ? get(data, 'Annual_Growth_16_41')
    : get(data, 'Annual_Growth_16_36')
  const populationChange = get(data, 'Population_41')
    ? get(data, 'Change_16_41')
    : get(data, 'Change_16_36')

  const CustomTooltip = props => {
    const { active } = props

    if (active) {
      const { payload, label } = props

      return (
        <TooltipWrapper>
          <strong>{label}</strong>
          {payload.map((item, key) => (
            <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
              {item.name}: {formatNumber(item.value)}
            </p>
          ))}
        </TooltipWrapper>
      )
    }
    return null
  }

  // Find dataNotes text from an array of possible forecast datanotes, based on the Source column of the dataset
  //TODO: Re-engineer datanotes to be queryable based on what clients have
  const dataNotesForForecastSource = dataNotes.edges.find(
    ({ node }) => node.frontmatter.sourceID == toLower(get(data, 'Source'))
  ).node

  try {
    return (
      <ChartWrapper
        name={`population-forecast-chart`}
        title={`How is the population changing?`}
        subTitle={`Forecast population 2006-${get(data, 'Population_41') ? '2041' : '2036'}`}
        dataSource={dataNotesForForecastSource.frontmatter.sourceText}
        dataNotes={dataNotesForForecastSource}
        chartInfo={chartInfo}
        body={
          <div className="row">
            <div className="col-md-8">
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer debounce={2000}>
                  <LineChart data={chartData} className="overflow-visible">
                    <XAxis dataKey="year" />
                    <YAxis
                      tickLine={false}
                      scale="linear"
                      domain={domain}
                      tickFormatter={value => formatNumber(value)}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="linear"
                      dataKey="per"
                      name="Population"
                      stroke={color}
                      strokeWidth={2}
                    >
                      <LabelList
                        dataKey="per"
                        unit="%"
                        position="top"
                        style={{
                          fill:
                            typeof window !== `undefined` &&
                            getComputedStyle(document.body).getPropertyValue('--bs-gray-600'),
                        }}
                        formatter={value => formatNumber(value)}
                      />
                    </Line>
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-md-4">
              <p className="text-muted m-0">
                Average annual growth <br />
                2016-{get(data, 'Population_41') ? '2041' : '2036'}
              </p>
              <p className="h2 m-0">
                {formatPercentage(annualGrowthRate)} <span className="small text-muted">p.a.</span>
              </p>
              <p className="small">
                <span className={increaseOrDecrease(populationChange)}>
                  {formatNumber(populationChange, '+0,0')}
                </span>
              </p>
            </div>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

PopulationForecastChart.propTypes = {
  data: PropTypes.shape({}),
}

PopulationForecastChart.defaultProps = {
  data: {},
}

export default PopulationForecastChart
