import React from 'react'
import HousingStressPercentageChart from './housing-stress-percentage'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

const HousingStressRentalPercentageChart = ({
  data,
  benchmarkData,
  areaName,
  benchmarkName,
  dataNotes
}) => {
  try {
    const chartData = [
      {
        group: areaName,
        Total_Per: data.Total_Rental_Stress_Per,
        Total_Num: data.Total_Rental_Stress_No,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary'),
      },
      {
        group: benchmarkName,
        Total_Per: benchmarkData.Total_Rental_Stress_Per,
        Total_Num: benchmarkData.Total_Rental_Stress_No,
        color: typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500'),
      },
    ]

    return (
      <HousingStressPercentageChart
        chartData={chartData}
        dataNotes={dataNotes}
        name={`rental-stress-percentage`}
        title={`Rental stress compared`}
        subTitle={`Percentage of households renting in rental stress`}
        dataSource={Datasource.ABS2021}
      />
    )
  } catch (error) {
    return (
      <ErrorMessageChart error={error}/>
    )
  }
}

export default HousingStressRentalPercentageChart
