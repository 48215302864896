import React from 'react'
import * as Datasource from '../../config/text-constants'
import HousingStressByIncomeMap from './housingStressByIncome'
import { PropTypes } from 'prop-types'

const dataConfiguration = {
  dataMappingField: { geoId: 'SA2_MAINCODE', dataId: 'Area_Id' },
  dataSuppressRule: { isApplicable: false, checkingColumName: 'Total_Rental_Stress_No', threshold: 10 },
  geoDataType: 'sa2',
  showThematicTable: true,
  isTableIsCollapsed: true,
  numberOfRows: 5,
  defaultSorting: { columnName: 'SA2_NAME', direction: 'asc' },
  defaultDataDisplayOption: 0,
  defaultDataDisplayType: 1,
  dataDisplayOptions: [
    {
      label: `Total households`,
      short_label: `Total`,
      number: `Total_Rental_Stress_No`,
      percentage: `Total_Rental_Stress_Per`,
    },
    {
      label: `Very low income households`,
      short_label: `Very low income`,
      number: `Very_Low_Rental_Stress_No`,
      percentage: `Very_Low_Rental_Stress_Per`,
    },
    {
      label: `Low income households`,
      short_label: `Low income`,
      number: `Low_Rental_Stress_No`,
      percentage: `Low_Rental_Stress_Per`,
    },
    {
      label: `Moderate income households`,
      short_label: `Moderate income`,
      number: `Moderate_Rental_Stress_No`,
      percentage: `Moderate_Rental_Stress_Per`,
    },
  ],

  dataDisplayTypes: [
    {
      label: `Number`,
      value: 'number',
    },
    {
      label: `%`,
      value: 'percentage',
    },
  ],
  tableTemplate: {
    main: {
      label: `Area`,
      value: 'SA2_NAME',
    },
    default: [
      {
        label: `Number`,
        value: 'number',
        type: 'number',
      },
      {
        label: `Percentage`,
        value: 'percentage',
        type: 'percentage',
      },
    ],
    extra: [
    ]
  },
  tooltipTemplate: {
    tooltipLiterals: function (feature, number, percentage) {
      return [{ isBold: true, content: `${feature.properties['SA2_NAME'].toUpperCase()}` },
      { isBold: false, content: `In stress: ${percentage} (${number})` }]
    },
    extra: [
    ]
  }
}


const HousingStressRentalByIncomeMap = ({
  LGACode,
  LGASlug,  
  data,  
  dataNotes, 
  pageContext
}) => {

  return (
    <HousingStressByIncomeMap
      name={'rental-stress-map'}
      title={`Where is rental stress felt?`}
      subTitle={`in rental stress by SA2`}
      dataSource={Datasource.ABS2021}
      LGACode={LGACode}
      data={data}
      dataConfiguration={dataConfiguration}
      LGASlug={LGASlug}
      dataNotes={dataNotes}
      pageContext={pageContext}
    />
  )
}

HousingStressRentalByIncomeMap.propTypes = {
  LGACode: PropTypes.string.isRequired,
  LGASlug: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,  
  dataNotes: PropTypes.object,
  pageContext:PropTypes.object.isRequired
}


export default HousingStressRentalByIncomeMap
