import React from 'react'

const DwellingChangeNote = ({ LGAName, benchmarkName }) => {
  return (
    <div>
      <p>
        This chart shows the quarterly house/unit price point (median (50<sup>th</sup> percentile),
        entry level (25<sup>th</sup> percentile)) for {LGAName} compared to {benchmarkName} as a
        time series chart, with optional change in percentage over a 12 month period. On the
        percentage version, the first four quarters available cannot show 12 month change, and so
        are blank. Raw data are shown in table form. Note that change is shown over a 12 month
        period, to the same quarter of the previous year, but are shown on a quarterly basis.
      </p>
      <p>
        Note that medians cannot be calculated for quarters with fewer than 7 house/unit sales in
        the region. These quarters are blank in the prices view, and the subsequent year is also
        blank in the percent view (because percent change can't be calculated). In the table view
        they show as $0, but this should not be taken to imply a zero median, just that the number
        of sales is too small to calculate.
      </p>
    </div>
  )
}

export default DwellingChangeNote
