import React from 'react'
import { map, flow, max, min } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import numeral from 'numeral'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from '../chartWrapper/index'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { formatNumber, roundNumberWithDecimalPlaces } from '../../functions/formatNumber'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'
import { ErrorMessageChart } from '../error-message-chart'

class OccupancyRateByNumberOfBedroomsChangeChart extends React.Component {
  render = () => {
    try {
      const dataDefinition = [
        {
          name: '0-1',
          key: '_2016_2021_change_per_dwelling_0_or_1_bedroom',
        },
        {
          name: '2',
          key: '_2016_2021_change_per_dwelling_two_bedrooms',
        },
        {
          name: '3',
          key: '_2016_2021_change_per_dwelling_three_bedrooms',
        },
        {
          name: '4+',
          key: '_2016_2021_change_per_dwelling_4_or_more_bedrooms',
        },
      ]

      const chartData = dataDefinition.map(definition => {
        return {
          group: definition.name,
          Total: roundNumberWithDecimalPlaces(this.props.data[definition.key], 2),
          Total_BM: roundNumberWithDecimalPlaces(this.props.benchmarkData[definition.key], 2),
        }
      })

      const maxValue = flow([x => map(x, item => max([item.Total, item.Total_BM])), max])(chartData)

      const minValue = flow([x => map(x, item => min([item.Total, item.Total_BM])), min])(chartData)

      const maxDomain = maxValue < 0 ? 0 : maxValue + 0.001
      const minDomain = minValue < 0 ? minValue - 0.001 : 0

      const ticks = scaleLinear()
        .domain([minDomain, maxDomain])
        .nice(5)
        .ticks(5)

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}: {numeral(item.value).format('0,0.00')}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`change-household-types-chart`}
          title={`Change in occupancy rate by number of bedrooms.`}
          subTitle={`${this.props.areaName} and benchmark, 2016-2021 Census`}
          dataSource={Datasource.ABS2016}
          dataNotes={this.props.dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div>
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis dataKey="group">
                      <Label value="Bedrooms" offset={-10} position="insideBottom" />
                    </XAxis>
                    <YAxis
                      tickLine={false}
                      ticks={ticks}
                      tickCount={ticks.count + 1}
                      tickFormatter={value => `${value}`}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" align="left" height={50} 
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}/>
                    <Bar
                      dataKey={'Total'}
                      name={this.props.areaName}
                      fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-primary')} 
                      label={(props) => 
                        <CustomVerticalLabel {...props} formatter={(value) => formatNumber(value,'0.00')}/>
                      }
                    ></Bar>
                    <Bar
                      dataKey="Total_BM"
                      name={this.props.benchmarkName}
                      fill={typeof window !== `undefined` && getComputedStyle(document.body).getPropertyValue('--bs-gray-500')}
                      label={(props) => 
                        <CustomVerticalLabel {...props} formatter={(value) => formatNumber(value,'0.00')}/>
                      }
                    ></Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      console.error(error)

      return <ErrorMessageChart error={error} />
    }
  }
}

export default OccupancyRateByNumberOfBedroomsChangeChart
