import React from 'react'
import { head, isEmpty } from 'lodash'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import {
  appHeader, 
  appHeader__inner,
  appHeader__title__wrapper,
  appHeader__title, 
  appHeader__clientName
} from './index.module.scss'

const Header = ({ clientName, siteTitle, clientAlias, clientLogo }) => (
  <header className={appHeader}>
    <div className="container">
      <div className={appHeader__inner}>
        <div className={appHeader__title__wrapper}>
          <div className={appHeader__title}>
            {clientName && (
              <Link
                to={`/${clientAlias}`}
                className={appHeader__clientName}
              >
                {clientName}
              </Link>
            )}

            {!clientName && (
              <div className={appHeader__clientName}>housing.id</div>
            )}
            <Link to="/">
              {siteTitle}
            </Link>
          </div>
        </div>
        <div aria-hidden={true}>
          {!isEmpty(clientLogo) && (
            <GatsbyImage 
              image={head(clientLogo.edges).node.childImageSharp.gatsbyImageData}
              alt={clientName}
              className="page-header__client__logo"
            />
          )}
        </div>
      </div>
    </div>
  </header>
)

export default Header
