import React from 'react'
import * as Datasource from '../../config/text-constants'
import HousingStressByIncomeMap from './housingStressByIncome'
import { PropTypes } from 'prop-types'

const dataConfiguration = {
  dataMappingField: { geoId: 'SA2_MAINCODE', dataId: 'Area_Id' },
  dataSuppressRule: { isApplicable: false, checkingColumName: 'Total_Mortgage_Stress_No', threshold: 10 },
  geoDataType: 'sa2',
  showThematicTable: true,
  isTableIsCollapsed: true,
  numberOfRows: 5,
  defaultSorting: { columnName: 'SA2_NAME', direction: 'asc' },
  defaultDataDisplayOption: 0,
  defaultDataDisplayType: 1,
  dataDisplayOptions: [
    {
      label: `Total households`,
      short_label: `Total`,
      number: `Total_Mortgage_Stress_No`,
      percentage: `Total_Mortgage_Stress_Per`,
    },
    {
      label: `Very low income households`,
      short_label: `Very low income`,
      number: `Very_Low_Mortgage_Stress_No`,
      percentage: `Very_Low_Mortgage_Stress_Per`,
    },
    {
      label: `Low income households`,
      short_label: `Low income`,
      number: `Low_Mortgage_Stress_No`,
      percentage: `Low_Mortgage_Stress_Per`,
    },
    {
      label: `Moderate income households`,
      short_label: `Moderate income`,
      number: `Moderate_Mortgage_Stress_No`,
      percentage: `Moderate_Mortgage_Stress_Per`,
    },
  ],

  dataDisplayTypes: [
    {
      label: `Number`,
      value: 'number',
    },
    {
      label: `%`,
      value: 'percentage',
    },
  ],
  tableTemplate: {
    main: {
      label: `Area`,
      value: 'SA2_NAME',
    },
    default: [
      {
        label: `Number`,
        value: 'number',
        type: 'number',
      },
      {
        label: `Percentage`,
        value: 'percentage',
        type: 'percentage',
      },
    ],
    extra: [
    ]
  },
  tooltipTemplate: {
    tooltipLiterals: function (feature, number, percentage) {
      return [{ isBold: true, content: `${feature.properties['SA2_NAME'].toUpperCase()}` },
      { isBold: false, content: `In stress: ${percentage} (${number})` }]
    },
    extra: [
    ]
  }
}


const HousingStressMortgageByIncomeMap = ({
  data,
  dataNotes,
  pageContext
}) => {

  
  return (
    <HousingStressByIncomeMap
      name={'mortgage-stress-map'}
      title={`Where is mortgage stress felt?`}
      subTitle={`in mortgage stress by SA2`}
      dataSource={Datasource.ABS2021}
      data={data}
      dataConfiguration = {dataConfiguration}
      dataNotes={dataNotes}
      pageContext={pageContext}
    />
  )
}

HousingStressMortgageByIncomeMap.propTypes = {
  data: PropTypes.object.isRequired,
  dataNotes: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}


export default HousingStressMortgageByIncomeMap
