import React from 'react'
import PillSelect from '../pillSelect'

const TimePeriodDropdown = ({ onChange, periods, currentPeriod }) => {
  return (
    <PillSelect aria-label="Time period" onChange={onChange} defaultValue={currentPeriod}>
      {periods.map((period, index) => (
        <option value={period} key={index}>
          {period}
        </option>
      ))}
    </PillSelect>
  )
}

export default TimePeriodDropdown
