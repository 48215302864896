import ReactDOMServer from 'react-dom/server'

const DynamicText = (children) => {
  return {
    html: 
        ReactDOMServer.renderToString(children)
  }
}

export default DynamicText
