import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import ChartWrapper from '../chartWrapper/index'
import { isEmpty } from 'lodash'
import * as Datasource from '../../config/text-constants'
import { formatNumber } from '../../functions/formatNumber'
import { ErrorMessageChart } from '../error-message-chart'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'
import TooltipWrapper from '../tooltip-wrapper'

const HomelessnessTypesChange = ({ data2021, data2016, bm2021, bm2016, pageContext, datanote }) => {
  if (isEmpty(data2021) || isEmpty(bm2021) || isEmpty(data2016) || isEmpty(bm2016)) {
    return <ErrorMessageChart error="No data for chart" />
  }

  try {
    const dataDefinition = [
      {
        name: 'People living in improvised dwellings, tents or sleeping out',
        key: 'People_living_in_improvised_dwellings__tents__or_sleeping_out',
      },
      {
        name: 'People living in supported accommodation for the homeless',
        key: 'People_in_supported_accommodation_for_the_homeless',
      },
      {
        name: 'People staying temporarily with other households',
        key: 'People_staying_temporarily_with_other_households',
      },
      {
        name: 'People living in boarding houses',
        key: 'People_living_in_boarding_houses',
      },
      {
        name: 'People in other temporary lodgings',
        key: 'People_in_other_temporary_lodgings',
      },
      {
        name: "People living in 'severely' crowded dwellings",
        key: 'People_living_in_severely_crowded_dwellings',
      },
    ]

    const chartData = dataDefinition.map(definition => {
      return {
        name: definition.name,
        Change: data2021.nodes[0][definition.key] - data2016.nodes[0][definition.key],
      }
    })

    const CustomTooltip = props => {
      const { payload, label } = props

      return (
        <TooltipWrapper>
          <strong>{label}</strong>
          {payload.map((item, key) => (
            <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
              {item.name}: {formatNumber(item.value)}
            </p>
          ))}
        </TooltipWrapper>
      )
    }

    return (
      <ChartWrapper
        name={'how-is-homelessness-changing'}
        title={'How is homelessness changing?'}
        subTitle={`Change in types of homelessness, 2016-2021`}
        dataSource={Datasource.ABS2016}
        dataNotes={datanote}
        body={
          <div>
            <div className="chart__viz" style={{ width: '100%', height: 440 }}>
              <ResponsiveContainer>
                <BarChart data={chartData} layout="vertical">
                  <YAxis
                    dataKey="name"
                    tickLine={false}
                    type="category"
                    interval={0}
                    textAnchor="end"
                    width={200}
                  />
                  <XAxis tickLine={false} type="number" />
                  <CartesianGrid vertical={true} horizontal={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    verticalAlign="top"
                    align="left"
                    height={30}
                    formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                  />
                  <Bar
                    dataKey={'Change'}
                    name={pageContext.lgaName}
                    fill={
                      typeof window !== `undefined` &&
                      getComputedStyle(document.body).getPropertyValue('--bs-primary')
                    }
                    label={props => (
                      <CustomHorizontalLabel {...props} formatter={(value) => formatNumber(value, '+0,0')} />
                    )}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}

export default HomelessnessTypesChange
