import React from 'react'
import * as Datasource from '../../config/text-constants'
import calculateBMMedianForPropertyTypes from '../../functions/benchmarkMedianDataHelper'
import GenericPricePointsChart from './generic-price-points'
import { PropTypes } from 'prop-types'

const PricePointsSaleChart = ({
  alias,
  data,
  dataBM,
  areaName,
  benchmarkName,
  dataNotes,
}) => {

  const flattenedBMData = calculateBMMedianForPropertyTypes(dataBM,'Sales')

  return (
    <GenericPricePointsChart
      alias={alias}
      name={`purchase-price-points`}
      title={`How much does it cost to buy a home?`}
      type={`sales`}
      benchmarkName={benchmarkName}
      areaName={areaName}
      dataSource={Datasource.Proptrack}
      dataNotes={dataNotes}
      data={data}
      dataBm={flattenedBMData}
      proptrackDisclaimer={true}
    />
  )
}

PricePointsSaleChart.propTypes = {
  alias: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataBM: PropTypes.object,
  dataNotes: PropTypes.object,  
  areaName: PropTypes.string.isRequired,  
  benchmarkName: PropTypes.string,   
}

export default PricePointsSaleChart
