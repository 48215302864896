import React from 'react'
import HousingAvailabilityChart from './housing-availability-chart'

const HousingAvailabilityRentalChart = ({ data, dataNotes, pageContext }) => {
  const title = `How much affordable housing is available to rent in ${pageContext.lgaName}?`
  const name = `housing-availability-rental`

  return (
    <HousingAvailabilityChart
      availabilityData={data}
      dataNotes={dataNotes}
      name={name}
      title={title}
      type={'rental'}
      proptrackDisclaimer={true}
    />
  )
}

export default HousingAvailabilityRentalChart
