import React from 'react'
import { max, map, isEmpty } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Button, ButtonGroup } from 'reactstrap'
import { scaleLinear } from 'd3-scale'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import { formatNumber } from '../../functions/formatNumber'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'
import CustomVerticalLabel from '../customLabel/CustomVerticalLabel'

class HouseholdTypeChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    if (isEmpty(this.props.data)) {
      return <ErrorMessageChart error="No data for chart" />
    }
    try {
      const chartData = [
        {
          group: 'Lone person',
          Total_Per: this.props.data.lone_person_21_per,
          Total_Num: this.props.data.lone_person_21_num,
          Total_Per_BM: this.props.benchmarkData.lone_person_21_per,
        },
        {
          group: 'Couples',
          Total_Per: this.props.data.couple_21_per,
          Total_Num: this.props.data.couple_21_num,
          Total_Per_BM: this.props.benchmarkData.couple_21_per,
        },
        {
          group: 'Families',
          Total_Per: this.props.data.family_21_per,
          Total_Num: this.props.data.family_21_num,
          Total_Per_BM: this.props.benchmarkData.family_21_per,
        },
        {
          group: 'Groups',
          Total_Per: this.props.data.group_21_per,
          Total_Num: this.props.data.group_21_num,
          Total_Per_BM: this.props.benchmarkData.group_21_per,
        },
      ]

      const numberFormatter = value =>
        this.state.dataType === 'percent' ? formatPercentage(value) : formatNumber(value)

      const maxValue = max(
        map(chartData, item =>
          this.state.dataType === 'percent'
            ? max([item.Total_Per, item.Total_Per_BM])
            : item.Total_Num
        )
      )

      const ticks = scaleLinear()
        .domain([0, maxValue])
        .nice(5)
        .ticks(5)

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>{label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}:{' '}
                  {numberFormatter(item.value)}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`household-types-chart`}
          title={`What is the mix of household types?`}
          subTitle={`Household types, 2021`}
          dataSource={Datasource.ABS2021}
          dataNotes={this.props.dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
              <div className="chart__viz" style={{ width: '100%', height: 320 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis dataKey="group" tickLine={false} />
                    <YAxis
                      tickLine={false}
                      ticks={ticks}
                      interval={0}
                      tickCount={ticks.count + 1}
                      tickFormatter={numberFormatter}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Bar
                      dataKey={this.state.dataType === 'percent' ? 'Total_Per' : 'Total_Num'}
                      name={this.props.areaName}
                      fill={this.props.color}
                      label={props => (
                        <CustomVerticalLabel {...props} formatter={numberFormatter}/>
                      )}
                    />
                    {this.state.dataType === 'percent' && (
                      <Bar
                        dataKey="Total_Per_BM"
                        name={this.props.benchmarkName}
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                        label={props => (
                          <CustomVerticalLabel {...props} formatter={(value) => formatPercentage(value)} />
                        )}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default HouseholdTypeChart
