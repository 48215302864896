import React, { useState } from 'react'
export const AvailabilityContext = React.createContext()

const AvailabilityProvider = props => {
  const [availabilitySalesLow, setAvailabilitySalesLow] = useState(0)
  const [availabilityRentalsLow, setAvailabilityRentalsLow] = useState(0)
  const [householdType, setHouseholdType] = useState('Total')
  const [activePeriod, setActivePeriod] = useState()
  const [activeChangeStartPeriod, setActiveChangeStartPeriod] = useState()
  const [dwellingSize, setDwellingSize] = useState('BedroomAll')

  const context = {
    availabilitySalesLow,
    setAvailabilitySalesLow,
    availabilityRentalsLow,
    setAvailabilityRentalsLow,
    householdType, 
    setHouseholdType, 
    activePeriod, 
    setActivePeriod, 
    activeChangeStartPeriod, 
    setActiveChangeStartPeriod,
    dwellingSize,
    setDwellingSize
  }

  return (
    <AvailabilityContext.Provider value={{ ...context }}>
      {props.children}
    </AvailabilityContext.Provider>
  )
}

export default (element) => (
  <AvailabilityProvider>
    {element.children}
  </AvailabilityProvider>
)
