import React, { useEffect } from 'react'
import { head, isEmpty, filter } from 'lodash'
import { navigate, graphql } from 'gatsby'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import AffordabilityMortgageLgaChart from '../components/charts/affordability-mortgage-lga'
import AffordabilityRentalLgaChart from '../components/charts/affordability-rental-lga'
import HousingAvailabilityRentalChart from '../components/charts/housing-availability-rental'
import HousingAvailabilityMortgageChart from './../components/charts/housing-availability-mortgage'
import { AvailabilityContext } from '../hooks/availabilityContext'
import SectionUpfrontText from '../components/collapsibleUpfrontText/section-upfront-text'
import AffordableSalesMap from '../components/maps/affordableSalesMap'
import AffordableRentalMap from '../components/maps/affordableRentalsMap'

const AffordabilityAvailabilityTemplate = props => {
  useEffect(() => {
    const access = head(filter(passwordProtected, ['lga', pageContext.LGASlug]))
    if (!isEmpty(access) && access.password !== window.pass) {
      navigate(`/${pageContext.LGASlug}`)
    }
  })

  // Destructure passed in object
  const data = props.data
  const pageContext = props.pageContext

  const title = pageContext.title
  const LGALongName = data.areas2XlsxAreas.LongName
  const alias = data.areas2XlsxAreas.Alias

  // hack, need to fix the periods in the import so that we import based on available data
  if (
    pageContext.LGASlug == 'randwick' ||
    pageContext.LGASlug == 'mitchell' ||
    pageContext.LGASlug == 'casey' ||
    pageContext.LGASlug == 'hobsons-bay' ||
    pageContext.LGASlug == 'shellharbour'
  ) {
    data.availability.nodes[0].Rentals.Periods = data.availability.nodes[0].Rentals.Periods.filter(
      p => p.Period_Name != 'Jun 2017'
    )
    data.availability.nodes[0].Sales.Periods = data.availability.nodes[0].Sales.Periods.filter(
      p => p.Period_Name != 'Jun 2017'
    )
    data.entryMedianPrices.nodes[0].Rentals.Periods = data.entryMedianPrices.nodes[0].Rentals.Periods.filter(
      p => p.Period_Name != 'Jun 2017'
    )
    data.entryMedianPrices.nodes[0].Sales.Periods = data.entryMedianPrices.nodes[0].Sales.Periods.filter(
      p => p.Period_Name != 'Jun 2017'
    )
  }

  return (
    <AvailabilityContext.Consumer>
      {context => (
        <React.Fragment>
          <PageContentWrapper
            title={title}
            LGAName={LGALongName}
            theme={pageContext.theme}
            areas={data.areas2XlsxAreas}
            slug={pageContext.LGASlug}
            alias={alias}
            productLinks={pageContext.productLinks}
            clientLogo={data.allFile}
          >
            <SectionUpfrontText title="Affordability and availability" type="h2">
              <p>
                Another way of looking at housing affordability is to compare directly the sales and
                rentals to a set of affordable thresholds, based on income. These are tracked over
                time to see whether affordability is increasing or decreasing. Affordability is
                based on spending 30% of gross income on rent, or a first home-buyer spending more
                than 30% of gross income on a mortgage based on a set of assumptions about deposit
                and interest rates.
              </p>
              <p>
                This allows you to monitor the affordability of housing in the area against a set of
                standard thresholds, and is updated every 6 months.
              </p>
            </SectionUpfrontText>
            <h2 className="section-heading">With a mortgage</h2>
            <SectionUpfrontText title="Housing purchase affordability">
              <p>
                This section looks at the number and percentage of housing sales which are
                affordable, dependent on income band, household type and small area.
              </p>
              <p>
                The first chart outlines the property purchase price that would be affordable to
                first home buyers with a 20% deposit, on very low, low and moderate incomes, in
                comparison to the entry-level (25th percentile) and median house and unit prices in{' '}
                {pageContext.LGAName}. This is an indicator of whether purchasing a property is
                affordable in the area.
              </p>
              <p>
                The second chart looks at how many sales over a 12 month period fall below the
                affordability (at 30% of gross income) threshold for each income level, based on
                assumptions for a first home buyer (deposit and interest rates, as well as loan term
                – details in the data notes). The number of bedrooms for each affordable sale is
                also identified, as an indicator of ‘appropriateness’ – especially when analysed by
                household type. The number of affordable sales is then disaggregated by small area
                on the map below.
              </p>
              <p>
                Tracking this over time (income levels for very low, low and moderate are adjusted
                annually based on changes in Average Weekly Earnings) can provide a great insight
                into whether home purchase is becoming more or less affordable at 6-monthly
                intervals in the area. It is a contemporary indicator of the need for affordable
                housing to purchase in the area and should be viewed in conjunction with the income
                ranges on the prices and incomes page, to see how many households fall into each of
                the income ranges.
              </p>
            </SectionUpfrontText>
            <div className="row">
              <div className="col-md-12">
                <AffordabilityMortgageLgaChart
                  alias={pageContext.LGASlug}
                  incomeRangesByYear={data.incomeRangesByYear}
                  dataNotes={data.affordabilityMortgageNotes}
                  pricePoints={data.entryMedianPrices.nodes[0].Sales}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <HousingAvailabilityMortgageChart
                  data={props.data.availability.nodes[0].Sales}
                  dataNotes={data.affordabilityMortgageNotes}
                  pageContext={pageContext}
                  context={context}
                />
              </div>
            </div>

            <div className="anchor-point" id="where-is-affordable-housing-available-to-buy">
              <AffordableSalesMap
                data={data.availability.nodes[0].Sales.Periods}
                dataNotes={data.affordabilityHousingSalesNotes}
                pageContext={pageContext}
                context={context}
                extraOverlayData={
                  data.availabilityOverlays.nodes.length > 0
                    ? data.availabilityOverlays.nodes[0].Sales.Periods
                    : null
                }
              />
            </div>
            <h2 className="section-heading anchor-point" id="rental-stress">
              Renting
            </h2>
            <SectionUpfrontText title="Housing rental affordability">
              <p>
                This section looks at the number and percentage of housing sales which are
                affordable, dependent on income band, household type and small area.
              </p>
              <p>
                The first chart outlines the weekly rent that would be affordable to renters on very
                low, low and moderate incomes, in comparison to the entry level (25th percentile)
                and median house and unit rental prices in {pageContext.LGAName}. This is an
                indicator of whether renting is affordable in the area.
              </p>
              <p>
                The second chart looks at how many rental listings over a 12 month period fall below
                the affordability (at 30% of gross income) threshold for each income level, based on
                spending no more than 30% of income on rent for very low, low and moderate incomes
                for each household type. The number of bedrooms for each affordable rental listing
                is also identified, as an indicator of ‘appropriateness’ – especially when analysed
                by household type. The number of affordable rental listings is then disaggregated by
                small area on the map below.
              </p>
              <p>
                Tracking this over time (income levels for very low, low and moderate are adjusted
                annually based on changes in Average Weekly Earnings) can provide a great insight
                into whether rental in the area is becoming more or less affordable at 6-monthly
                intervals. It is a contemporary indicator of the need for affordable housing rental
                in the area and should be viewed in conjunction with the income ranges on the prices
                and incomes page, to see how many households fall into each of the income ranges.
                Note that very low incomes are usually assumed to be candidates for social housing,
                while low incomes are likely to need affordable housing for key workers, however
                this can change depending on the role and function of each place.
              </p>
            </SectionUpfrontText>
            <div className="row anchor-point" id="how-affordable-is-renting">
              <div className="col-md-12">
                <AffordabilityRentalLgaChart
                  alias={pageContext.LGASlug}
                  pricePoints={data.entryMedianPrices.nodes[0].Rentals}
                  incomeRangesByYear={data.incomeRangesByYear}
                  pageContext={pageContext}
                  dataNotes={data.affordabilityRentalNotes}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <HousingAvailabilityRentalChart
                  data={props.data.availability.nodes[0].Rentals}
                  dataNotes={data.housingAvailabilityRentalNotes}
                  context={context}
                  pageContext={pageContext}
                />
              </div>
            </div>
            <div className="anchor-point" id="where-is-affordable-housing-available-to-rent">
              <AffordableRentalMap
                data={data.availability.nodes[0].Rentals.Periods}
                dataNotes={data.affordabilityHousingRentalsNotes}
                pageContext={pageContext}
                extraOverlayData={
                  data.availabilityOverlays.nodes.length > 0
                    ? data.availabilityOverlays.nodes[0].Rentals.Periods
                    : null
                }
              />
            </div>
          </PageContentWrapper>
        </React.Fragment>
      )}
    </AvailabilityContext.Consumer>
  )
}

export default AffordabilityAvailabilityTemplate

export const PageQuery = graphql`
  query Affordability($lgacode: String!, $benchmarkCode: String!, $geocode: String!) {
    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    entryMedianPrices: allAffordabilityAvailability(filter: { lga: { eq: $lgacode } }) {
      nodes {
        Rentals {
          Periods {
            Median_Unit
            Median_House
            Entry_Unit
            Entry_House
            Period_Name
          }
        }
        Sales {
          Periods {
            Median_Unit
            Median_House
            Entry_Unit
            Entry_House
            Period_Name
          }
        }
      }
    }

    availability: allAffordabilityAvailability(filter: { lga: { eq: $lgacode } }) {
      nodes {
        lga
        Sales {
          Periods {
            Period_Name
            Median_House
            Median_Unit
            Areas {
              Area_Name
              Area_Id
              Entry_House
              Entry_Unit
              Low
              Median_House
              Median_Unit
              Low_Percent
              Moderate
              Moderate_Percent
              Total_Value
              Very_Low
              Very_Low_Percent
              Area_Details {
                Couple {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }

                One_parent_family {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }
                Couple_family_with_children {
                  Low
                  Low_Percent
                  Moderate
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Lone_person {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Group_household {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
              }
            }
            Couple {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated_Percent
                Bedrooms_Not_Stated
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            One_parent_family {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Couple_family_with_children {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Lone_person {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Group_household {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Total_Value
            Interest_Rate
            Total {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low_Percent
              Very_Low
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
          }
        }
        Rentals {
          Periods {
            Period_Name
            Median_House
            Median_Unit
            Areas {
              Area_Name
              Area_Id
              Entry_House
              Entry_Unit
              Low
              Median_House
              Median_Unit
              Low_Percent
              Moderate
              Moderate_Percent
              Total_Value
              Very_Low
              Very_Low_Percent
              Area_Details {
                Couple {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }

                One_parent_family {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }
                Couple_family_with_children {
                  Low
                  Low_Percent
                  Moderate
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Lone_person {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Group_household {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
              }
            }
            Couple {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated_Percent
                Bedrooms_Not_Stated
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            One_parent_family {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Couple_family_with_children {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Lone_person {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Group_household {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Total_Value
            Total {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low_Percent
              Very_Low
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
          }
        }
      }
    }

    availabilityOverlays: allAffordabilityAvailabilityOverlays(filter: { lga: { eq: $lgacode } }) {
      nodes {
        lga
        Sales {
          Periods {
            Period_Name
            Median_House
            Median_Unit
            Areas {
              Area_Name
              Area_Id
              Entry_House
              Entry_Unit
              Low
              Median_House
              Median_Unit
              Low_Percent
              Moderate
              Moderate_Percent
              Total_Value
              Very_Low
              Very_Low_Percent
              Area_Details {
                Couple {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }

                One_parent_family {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }
                Couple_family_with_children {
                  Low
                  Low_Percent
                  Moderate
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Lone_person {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Group_household {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
              }
            }
            Couple {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated_Percent
                Bedrooms_Not_Stated
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            One_parent_family {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Couple_family_with_children {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Lone_person {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Group_household {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Total_Value
            Total {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low_Percent
              Very_Low
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
          }
        }
        Rentals {
          Periods {
            Period_Name
            Median_House
            Median_Unit
            Areas {
              Area_Name
              Area_Id
              Entry_House
              Entry_Unit
              Low
              Median_House
              Median_Unit
              Low_Percent
              Moderate
              Moderate_Percent
              Total_Value
              Very_Low
              Very_Low_Percent
              Area_Details {
                Couple {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }

                One_parent_family {
                  Low
                  Low_Percent
                  Moderate
                  Very_Low
                  Moderate_Percent
                  Very_Low_Percent
                }
                Couple_family_with_children {
                  Low
                  Low_Percent
                  Moderate
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Lone_person {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
                Group_household {
                  Low
                  Moderate
                  Low_Percent
                  Moderate_Percent
                  Very_Low
                  Very_Low_Percent
                }
              }
            }
            Couple {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated_Percent
                Bedrooms_Not_Stated
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            One_parent_family {
              Low
              Low_Percent
              Moderate
              Very_Low
              Moderate_Percent
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_3Plus
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Couple_family_with_children {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Lone_person {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Group_household {
              Low
              Moderate
              Low_Percent
              Moderate_Percent
              Very_Low
              Very_Low_Percent
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
            Total_Value
            Total {
              Low
              Low_Percent
              Moderate
              Moderate_Percent
              Very_Low_Percent
              Very_Low
              Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Moderate_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
              Very_Low_Dwelling_Sizes {
                All
                All_Percent
                Bedrooms_1
                Bedrooms_1_Percent
                Bedrooms_1_Affordable_Percent_Of_Bedrooms_1_All
                Bedrooms_2
                Bedrooms_2_Percent
                Bedrooms_2_Affordable_Percent_Of_Bedrooms_2_All
                Bedrooms_3Plus
                Bedrooms_3Plus_Percent
                Bedrooms_3Plus_Affordable_Percent_Of_Bedrooms_3Plus_All
                Bedrooms_Not_Stated
                Bedrooms_Not_Stated_Percent
              }
            }
          }
        }
      }
    }

    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      GCC_Code
      GCC_Name
      Alias
    }

    incomeRangesByYear: allAffordabilityBands(filter: { gccsa: { eq: $benchmarkCode } }) {
      nodes {
        data {
          Incomes {
            Couple {
              Low
              Moderate
              Very_Low
              Label
            }
            Couple_family_with_children {
              Low
              Moderate
              Very_Low
              Label
            }
            Lone_person {
              Low
              Moderate
              Very_Low
              Label
            }
            One_parent_family {
              Low
              Moderate
              Very_Low
              Label
            }
            Group_household {
              Low
              Moderate
              Very_Low
              Label
            }
            Total {
              Low
              Moderate
              Very_Low
              Label
            }
            Year
            Interest_rate_june
            Interest_rate_december
          }
        }
      }
    }

    affordabilityHousingSalesNotes: markdownRemark(
      frontmatter: { slug: { eq: "affordability-housing-sales" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    affordabilityHousingRentalsNotes: markdownRemark(
      frontmatter: { slug: { eq: "affordability-housing-rentals" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    affordabilityMortgageNotes: markdownRemark(
      frontmatter: { slug: { eq: "affordability-mortgage" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
    affordabilityRentalNotes: markdownRemark(
      frontmatter: { slug: { eq: "affordability-rental" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
    housingAvailabilityMortgageNotes: markdownRemark(
      frontmatter: { slug: { eq: "housing-availability-mortgage" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
    housingAvailabilityRentalNotes: markdownRemark(
      frontmatter: { slug: { eq: "housing-availability-rental" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
  }
`
