export const filteredDataByFamilyType = (data, context, selectedPeriod) => {
  if (data != null) {
    let filteredData = data.filter(item => item.Period_Name === selectedPeriod)
    if (filteredData != null && filteredData.length > 0) {
      return modifyDataForFamilyType(
        filteredData[0].Areas,
        context.householdType)
    }
    else
      return []
  }
  else
    return []
}

const modifyDataForFamilyType = (data, selectedFamilyType) => {
  // No modification for Average All Households
  if (selectedFamilyType === 'Total') {
    return data
  }

  // Create a copy of the data to avoid modifying the original data
  const modifiedData = JSON.parse(JSON.stringify(data))

  // Iterate through the areas and replace values with the selected familyType's values
  for (const area of modifiedData) {
    const familyTypeData = area.Area_Details[selectedFamilyType]
    if (familyTypeData) {
      // Copy the values from the selected familyType
      for (const key in familyTypeData) {
        if (key !== 'Family_Type') {
          area[key] = familyTypeData[key]
        }
      }
    } else {
      console.warn(`Family type '${selectedFamilyType}' not found for area '${area.Area_Name}'.`)
    }
  }

  return modifiedData
}
