import React from 'react'

const CustomHorizontalLabel = ({ x, y, value, width, height, formatter, xoffset =0 }) => {
  const textFill = height < -10 ? '#ffffff' : "#000"
  const formattedValue = formatter ? formatter(value) : value
  const xposition = value >= 0 ? x + width + 20 + xoffset : x + width - 20 - xoffset
  return (
    <>
      <text
        className="recharts-text recharts-label"
        x={xposition}
        y={y + height/2 + 10}
        dy={-5}
        fill={textFill}
        stroke="#F8F8F9"
        strokeWidth="2.5"
        textAnchor="middle"
      >
        {formattedValue}
      </text>

      <text
        className="recharts-text recharts-label"
        x={xposition}
        y={y + height/2 + 10}
        dy={-5}
        fill={textFill}
        textAnchor="middle"
      >
        {formattedValue}
      </text>
    </>
  )
}
export default CustomHorizontalLabel
