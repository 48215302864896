import React from 'react'

const HousingConsumptionDwellingsInfo = ({ LGAName }) => {

  return (
    <div>
      <p>
        This chart breaks down the selected household type by dwelling types for each 
        suburb or small area in {LGAName}. Please note that percentages may sum to slightly 
        over or under 100%. This is due to small numbers in some areas in some housing 
        types, and the application of ABS random adjustment to the numbers to protect 
        confidentiality of individual information. 
      </p>
      <p>
        The chart rollover gives the total number of households in the category, and no 
        reliance should be placed on small numbers (under 10 in most cases).
      </p>
    </div>
  )
}

export default HousingConsumptionDwellingsInfo