import React from 'react'
import formatPercentage from '../../../functions/formatPercentage'

const MapLegend = props => {
  const { range, domain, extent, isPercentage } = props

  const formatNumber = number =>
    isPercentage ? formatPercentage(number) : number

  return (
    <div className="map-legend">
      <div className="map-legend__range">
        {range.map((color, index) => (
          <span
            key={index}
            style={{
              background: color,
            }}
          />
        ))}
      </div>
      <div className="map-legend__domain">
        <span>{formatNumber(extent[0])}</span>
        {domain.map((number, index) => (
          <span key={index}>{formatNumber(number)}</span>
        ))}
        <span>{formatNumber(extent[1])}</span>
      </div>
    </div>
  )
}

export default MapLegend
