import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'
import { getSrc } from 'gatsby-plugin-image'

const ClientGrid = ({ clients, clientLogos }) => (
  <div className="client-grid">
    {clients.edges
      .sort((a, b) =>
        a.node.LongName !== b.node.LongName
          ? a.node.LongName < b.node.LongName
            ? -1
            : 1
          : 0
      )
      .map((client, index) => (
        <Link
          className="client-grid__item"
          key={index}
          to={`/${client.node.Alias}`}
        >
          <img aria-hidden={true}
            src={
              getSrc(getImageNodeByClientId(clientLogos, client.node.Geocode).node
                .childImageSharp.gatsbyImageData)
            }
            className="img-fluid"
          />
          <span className="client-grid__item-title">
            {client.node.LongName}
          </span>
        </Link>
      ))}
    <a
      href="https://home.id.com.au/services/demographic-information-tools/local-government-online-tools/housing-id/"
      className="client-grid__item client-grid__item--cta"
    >
      <span className="client-grid__item-title">Add your community</span>
    </a>
  </div>
)

const getImageNodeByClientId = (clientImages, clientID) => {
  return clientImages.edges.find(
    ({ node }) => node.name === clientID.toString()
  )
}

export default ClientGrid
