import React from 'react'
import { maxBy } from 'lodash'
import numeral from 'numeral'

const HousingStressNote = ({ incomeRangesByYear, benchmarkName }) => {
  const latestIncomes = maxBy(incomeRangesByYear.nodes[0].data.Incomes, range => range.Year)
  const priceFormatterLong = value => numeral(value).format('$0,0')

  return (
    <div>
      <p>
        Housing stress is defined as households spending more than 30% of gross household income on
        rent or mortgage, who are in the very low, low or moderate income ranges. Income ranges are
        described below and are calculated in the Census year and recalculated based on inflation
        every year.
      </p>
      <p>
        Households described as being on a very low income are an aggregate of households earning
        less than 50% of {benchmarkName} <a href="https://en.wikipedia.org/wiki/Median">median</a>{' '}
        income for that household type.
      </p>
      <p>
        Households earning more than 50% but less than 80% of the {benchmarkName}{' '}
        <a href="https://en.wikipedia.org/wiki/Median">median</a> income for their household type
        are described as earning a low income.
      </p>
      <p>
        Households described as being on a moderate income are those earning between 80-120% of the{' '}
        {benchmarkName} <a href="https://en.wikipedia.org/wiki/Median">median</a> income for that
        household type.
      </p>
      <p>
        For {benchmarkName} in {latestIncomes.Year}, the following ranges are used in the
        calculation of housing affordability. Census base income levels shown in this chart are
        adjusted to current and past years from a base year of 2021, using changes in{' '}
        <a href="https://www.abs.gov.au/statistics/labour/earnings-and-working-conditions/average-weekly-earnings-australia/latest-release">
          Average Weekly Total Earnings
        </a>{' '}
        for employees in the State.
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Very Low</th>
            <th>Low</th>
            <th>Moderate</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(latestIncomes).map(
            (key, index) =>
              latestIncomes[key].Very_Low != null && (
                <tr key={'household-type-' + index}>
                  <td>
                    <strong>{latestIncomes[key].Label}</strong>
                  </td>
                  <td>Up to {priceFormatterLong(latestIncomes[key].Very_Low)}</td>
                  <td>Up to {priceFormatterLong(latestIncomes[key].Low)}</td>
                  <td>Up to {priceFormatterLong(latestIncomes[key].Moderate)}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  )
}

export default HousingStressNote
