import React from 'react'
import { Location } from '@reach/router'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const FeedbackForm = props => (
  <Location>
    {({ location }) => <UnwrappedFeedbackForm {...props} location={location} />}
  </Location>
)

class UnwrappedFeedbackForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formState: {
        location: this.props.location.pathname,
      },
      formSubmitted: false,
    }
  }

  handleChange = e => {
    this.setState({
      formState: {
        ...this.state.formState,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state.formState,
      }),
    })
      .then(() => {
        this.setState({ formSubmitted: true })
        form.reset()
      })
      .catch(error => alert(error))
  }

  render() {
    return (
      <div>
        {this.state.formSubmitted === false && (
          <form
            name="feedback"
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="feedback" />
            <input type="hidden" name="location" value={this.props.location.pathname} />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="feedbackName">Name</label>
              <input
                type="text"
                id="feedbackName"
                className="form-control"
                name="name"
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="feedbackEmail">Email address</label>
              <input
                type="email"
                id="feedbackEmail"
                className="form-control"
                aria-describedby="emailHelp"
                name="email"
                onChange={this.handleChange}
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>

            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Feedback</label>
              <textarea
                className="form-control"
                rows="8"
                name="message"
                onChange={this.handleChange}
              ></textarea>
            </div>
            <p>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </p>
          </form>
        )}
        {this.state.formSubmitted === true && (
          <div className="thanks">
            <strong>
              <span role="img" aria-label="Thumbs up">
                👍
              </span>{' '}
              Thanks for giving us feedback
            </strong>
            <p>
              If you've left your details, one of our team will follow up with
              you
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default FeedbackForm
