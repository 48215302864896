import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { StickyContainer } from 'react-sticky'
import 'ie-array-find-polyfill'
import Header from '../components/header'
import Footer from '../components/footer'
import '../styles/bootstrap.scss'
import CommunityHeader from './communityHeader'
import ProductTabs from './productTabs'

const Layout = ({ children, clientName, clientAlias, pageTitle, productLinks, clientLogo }) => (
  <StaticQuery
    query={graphql`
      query mainLayoutQuery {
        site {
          siteMetadata {
            title
            topics {
              slug
              title
            }
            version
          }
        }
      }
    `}
    render={data => {
      // <title> pattern of profile.id
      // Ancestry | City of Adelaide | Community Profile
      // {pageTitle} | {clientName} | {siteTitle}

      var titleTagText = pageTitle ? `${pageTitle} |  ` : ''
      titleTagText += clientName ? `${clientName} |  ` : ''
      titleTagText += data.site.siteMetadata.title
      titleTagText = !pageTitle && !clientName ? `housing.id | ${titleTagText}` : titleTagText

      return (
        <div>
          <Helmet title={titleTagText}>
            <html lang="en" />
          </Helmet>
          <CommunityHeader />
          <Header
            siteTitle={data.site.siteMetadata.title}
            clientLogo={clientLogo}
            clientName={clientName}
            clientAlias={clientAlias}
            productLinks={productLinks}
          />
          {productLinks && <ProductTabs products={productLinks} />}
          <StickyContainer>{children}</StickyContainer>
          <Footer version={data.site.siteMetadata.version} client={clientAlias} />
        </div>
      )
    }}
  />
)

export default Layout
