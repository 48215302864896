import React from 'react'

const PopulationChangeInfo = ({ LGAName }) => {
  return (
    <div>
      <p>
        Housing demand is ultimately driven by population and household growth. This chart shows the
        population change in {LGAName} from 2006 to 2036 or 2041 based on actual population and
        forecasts derived from .id or alternate sources. The broad level of population growth can
        indicate whether there is likely to be increased demand for housing in the future, but
        should be viewed in conjunction with household forecasts and housing supply datasets.
      </p>
    </div>
  )
}

export default PopulationChangeInfo
