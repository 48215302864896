import React from 'react'

const Rentals = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="60 80 380 360"
  >
    <defs />
    <path d="M462.2 231.5l-72.2-53v-69.2h-71.8v16.5L251.7 77 37.8 231.5h52.9V423h323.1V231.5zM138.1 409.8V254h99.6v155.8zm262.5 0H251v-169H124.9v169H104v-178h296.6zm-69.1-287.3h45.3v46.3l-45.3-33.2zm-79.9-29.1l170.1 124.8H78.8z" />
    <path d="M380.4 251.9h-82.9v123.5h82.9zm-13.2 26h-21.6v-12.8h21.6zm0 29.1h-21.6v-15.9h21.6zm0 29.2h-21.6v-15.9h21.6zm-56.5-15.9h21.6v15.9h-21.6zm0-29.2h21.6V307h-21.6zm21.6-26v12.8h-21.6v-12.8zm-21.6 84.3h21.6v12.8h-21.6zm34.9 12.8v-12.8h21.6v12.8z" />
  </svg>
)

export { Rentals }
